<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="adCampain; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!adCampain">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="adCampain">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>

            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom"
                formControlName="name"
                required
              />
              <mat-error *ngIf="formGroup.controls['name']?.errors">{{
                formGroup.controls["name"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <div class="timepicker-container">
              <label>Heure de début</label>
              <ngx-timepicker-field
                [format]="24"
                [controlOnly]="true"
                [(ngModel)]="startAtTime"
                [ngModelOptions]="{ standalone: true }"
              >
              </ngx-timepicker-field>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>Date de début</mat-label>
              <input
                matInput
                [matDatepicker]="startAtPicker"
                formControlName="startAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['startAt']?.errors">{{
                formGroup.controls["startAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <div class="timepicker-container">
              <label>Heure de fin</label>
              <ngx-timepicker-field
                [format]="24"
                [controlOnly]="true"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="endAtTime"
              >
              </ngx-timepicker-field>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>Date de fin</mat-label>
              <input
                matInput
                [matDatepicker]="endAtPicker"
                formControlName="endAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['endAt']?.errors">{{
                formGroup.controls["endAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Limite de vues</mat-label>
              <input
                matInput
                placeholder="Vues"
                formControlName="views"
                type="number"
                min="0"
              />
              <mat-error *ngIf="formGroup.controls['views']?.errors">{{
                formGroup.controls["views"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-slide-toggle
              formControlName="unlimitedViews"
              (change)="changeUnlimitedViewsInput($event)"
              >Vues illimitées</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="allowAppodeal"
              >Autoriser appodeal</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="isEnabled"
              >Activé
            </mat-slide-toggle>
            <mat-form-field style="flex: 1; margin-top: 10px" appearance="fill">
              <mat-label>Visible uniquement pour certaines arènes</mat-label>
              <mat-chip-list #contestChipList multiple>
                <mat-chip
                  *ngFor="let contest of adCampain.contests"
                  (removed)="
                    removeContestItem(contest); contestSearchInput.value = ''
                  "
                >
                  {{ contest.id + " - " + contest.name }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input
                  placeholder="Rechercher une arène..."
                  #contestSearchInput
                  [(ngModel)]="contestSearchTerm"
                  [ngModelOptions]="{ standalone: true }"
                  (input)="searchContests()"
                  [matAutocomplete]="contestAutocomplete"
                  [matChipInputFor]="contestChipList"
                />
              </mat-chip-list>
              <mat-autocomplete #contestAutocomplete="matAutocomplete">
                <mat-option disabled *ngIf="contestSearchLoading"
                  ><mat-spinner
                    [diameter]="25"
                    style="margin: auto"
                  ></mat-spinner
                ></mat-option>
                <mat-option
                  *ngFor="let contest of searchedContests"
                  (click)="
                    addContestItem(contest); contestSearchInput.value = ''
                  "
                >
                  {{ contest.id + " - " + contest.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-divider style="margin: 10px 0"></mat-divider>
            <h2>Publicités</h2>

            <p
              *ngIf="
                formGroup.value['allowAppodeal'] === true &&
                sumAdsProbabilities() < 1
              "
            >
              Probabilité d'avoir une publicité appodeal :
              {{ calculateOtherProbabilities() | number }}
            </p>
            <p
              *ngIf="
                formGroup.value['allowAppodeal'] === false &&
                sumAdsProbabilities() < 1
              "
            >
              Probabilité de ne pas avoir de publicité :
              {{ calculateOtherProbabilities() | number }}
            </p>
            <p *ngIf="sumAdsProbabilities() > 1" style="color: red">
              La somme des probabilité doit être inférieure ou égale à 1
              (actuellement : {{ sumAdsProbabilities() | number }})
            </p>
            <div
              formArrayName="adCampainAds"
              *ngFor="
                let adCampainAdsFormGroup of adCampainAds.controls;
                let i = index
              "
            >
              <div
                [formGroupName]="i"
                style="
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <mat-form-field appearance="fill">
                  <mat-label>Publicité</mat-label>
                  <mat-select formControlName="selectedAdGuid" required>
                    <mat-option [value]="null">--</mat-option>
                    <mat-option
                      *ngFor="let ad of ads"
                      [value]="ad.guid ?? ''"
                      [disabled]="!canAdBeSelected(ad.guid ?? '')"
                    >
                      {{ ad.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Probabilité</mat-label>
                  <input
                    matInput
                    placeholder="Probabilité"
                    type="number"
                    min="0"
                    max="1"
                    step=".1"
                    formControlName="probability"
                    required
                  />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Vues (estimées)</mat-label>
                  <input
                    matInput
                    placeholder="views"
                    type="number"
                    disabled
                    [value]="
                      formGroup.value.views === null
                        ? null
                        : adCampainAds.value[i].probability *
                          formGroup.value.views
                    "
                  />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Max / H</mat-label>
                  <input
                    matInput
                    formControlName="hourlyViewsLimit"
                    type="number"
                  />
                </mat-form-field>

                <div>
                  <button
                    mat-raised-button
                    (click)="removeAdCampainAdFormGroup(i)"
                    color="accent"
                    type="button"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div>
          <div style="flex: 1; display: flex; justify-content: center">
            <div style="flex: 0.75; display: flex; flex-direction: column">
              <div class="text-center">
                <button
                  mat-raised-button
                  (click)="addAdCampainAdFormGroup()"
                  color="accent"
                  type="button"
                >
                  Ajouter une publicité
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="
        submitLoading ||
        !adCampain ||
        !formGroup.valid ||
        sumAdsProbabilities() > 1
      "
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
