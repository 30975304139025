import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Discount } from 'src/entities/Discount';
import { PaymentService } from 'src/services/payment.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Product } from 'src/entities/Product';

@Component({
  selector: 'app-edit-discount-modal',
  templateUrl: './edit-discount-modal.component.html',
  styleUrls: ['./edit-discount-modal.component.css'],
})
export class EditDiscountModalComponent implements OnInit {
  discount: Discount | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    code: new FormControl(null, Validators.required),
    maxUses: new FormControl(null),
    expiresAt: new FormControl(null),
    productGuid: new FormControl(null, Validators.required),
    value: new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(100),
    ]),
  });
  errors: string | null = null;
  products: { value: string; name: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private dialogRef: MatDialogRef<EditDiscountModalComponent>
  ) {}

  ngOnInit(): void {
    // Store products for select
    this.paymentService.getProducts().subscribe((products: any) => {
      this.products = products['hydra:member'].map((product: Product) => ({
        name: `${product.androidStoreName} / ${product.iosStoreName}` ?? '',
        value: product.guid ?? '',
      }));
    });

    if (this.data.guid) {
      // Retrieve discount
      this.paymentService
        .getDiscount(this.data.guid)
        .subscribe((discount: Discount) => {
          this.discount = discount;
          this.createFormGroup();
        });
    } else {
      this.discount = new Discount();
      this.createFormGroup();
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  createFormGroup(): void {
    if (this.discount) {
      this.formGroup.setValue({
        code: this.discount.code,
        maxUses: this.discount.maxUses,
        expiresAt: this.discount.expiresAt,
        value: this.discount.value,
        productGuid: this.discount.product?.guid ?? null,
      });
    }
  }

  onSubmit(): void {
    this.submitLoading = true;
    let data: any = Object.assign({}, this.formGroup.value);
    this.errors = null;

    // Format data
    data.product = `/s/products/${data.productGuid}`;
    delete data.productGuid;

    let request = this.data.guid
      ? this.paymentService.putDiscount(this.data.guid, data)
      : this.paymentService.createDiscount(data);

    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      .add(() => {
        this.submitLoading = false;
      });
  }
}
