<!-- Loader -->
<mat-spinner *ngIf="!data"></mat-spinner>

<section class="main-section">
  <ng-container *ngIf="data">
    <div *ngIf="contestsMetrics" class="recap mat-elevation-z1">
      <h2 class="text-center">Résumé</h2>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p>
            <strong>Nb d'arènes : </strong>{{ contestsMetrics.contestsCount }}
          </p>
          <p>
            <strong>Nb d'arènes Freemium : </strong
            >{{ contestsMetrics.freemiumContestsCount }}
          </p>
          <p>
            <strong>Nb d'arènes Premium : </strong
            >{{ contestsMetrics.premiumContestsCount }}
          </p>
        </div>
        <div style="flex: 1">
          <p>
            <strong>Nb de joueurs moyen : </strong
            >{{ contestsMetrics.averagePlayers | number }}
          </p>
          <p>
            <strong>Nb de paris effectués en moyenne : </strong
            >{{ contestsMetrics.averageBets | number }}
          </p>
          <p>
            <strong>Nb de bonus débloqués en moyenne : </strong
            >{{ contestsMetrics.averageRetrievedBonuses | number }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!loading; else loader" style="width: 100%">
    <div class="text-center">
      <!-- Search user by name -->
      <mat-form-field appearance="fill">
        <mat-label>Rechercher une arène</mat-label>
        <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
        <input
          matInput
          type="search"
          [(ngModel)]="searchedValue"
          (input)="setSearchedValue($event)"
        />
        <button
          *ngIf="searchedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchedValue = ''; this.setData(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip
              [color]="getChipColorFromStatus(element)"
              [tooltip]="getChipTooltipFromStatus(element)"
            >
            </app-status-chip>
          </td>
        </ng-container>

        <!-- isSponsored -->
        <ng-container matColumnDef="isSponsored">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Public</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip [bool]="element.isSponsored"></app-status-chip>
          </td>
        </ng-container>

        <!-- isPremium -->
        <ng-container matColumnDef="isPremium">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Premium</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip [bool]="element.isPremium"></app-status-chip>
          </td>
        </ng-container>

        <!-- Mode -->
        <ng-container matColumnDef="mode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mode</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              [routerLink]="['/modes', element.mode?.guid ?? '']"
              >{{ element.mode?.title }}</a
            >
            <a
              *ngIf="element.variant"
              mat-dialog-close
              [routerLink]="['/variants', element.variant?.guid ?? '']"
            >
              - {{ element.variant?.title }}</a
            >
          </td>
        </ng-container>

        <!-- Creator -->
        <ng-container matColumnDef="creator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Créateur</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              [routerLink]="['/users', element.creator?.guid ?? '']"
              >{{ element.creator?.username }}</a
            >
          </td>
        </ng-container>

        <!-- User count -->
        <ng-container matColumnDef="userCount">
          <th mat-header-cell *matHeaderCellDef>Nb de joueurs</th>
          <td mat-cell *matCellDef="let element">{{ element.userCount }}</td>
        </ng-container>
        <!-- User count -->
        <ng-container matColumnDef="pendingUserCount">
          <th mat-header-cell *matHeaderCellDef>Nb de joueurs en attente</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pendingUserCount }}
          </td>
        </ng-container>

        <!-- CreatedAt -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Date de création</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showContestModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="editContestModal(element.id)"
                class="cursor_pointer"
                matTooltip="Editer"
              >
                <mat-icon>edit</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        #paginator
        showFirstLastButtons
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </div>
</section>
<mat-divider style="margin: 20px 0"></mat-divider>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
