import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ad } from 'src/entities/Ad';
import { AdService } from 'src/services/ad.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-ad-modal',
  templateUrl: './show-fullscreen-ad-modal.component.html',
  styleUrls: ['./show-fullscreen-ad-modal.component.css'],
})
export class ShowFullscreenAdModalComponent implements OnInit {
  ad: Ad | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adService: AdService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.adService.getAd(this.data.guid).subscribe((ad: Ad) => {
        this.ad = ad;
      });
      // Change url
      this.location.replaceState(`/ads/ads/${this.data.guid}`);
    }
  }
}
