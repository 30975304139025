import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contest } from 'src/entities/Contest';
import { ContestService } from 'src/services/contest.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileService } from 'src/services/file.service';

@Component({
  selector: 'app-edit-contest-modal',
  templateUrl: './edit-contest-modal.component.html',
  styleUrls: ['./edit-contest-modal.component.css'],
})
export class EditContestModalComponent implements OnInit {
  contest: Contest | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    isSponsored: new FormControl(''),
    code: new FormControl(''),
    description: new FormControl(''),
    hasAutoAcceptation: new FormControl(false),
  });
  dateRange = new FormGroup({
    startAt: new FormControl(Validators.required),
    endAt: new FormControl(Validators.required),
  });
  errors: string | null = null;
  imageUploadLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contestService: ContestService,
    private dialogRef: MatDialogRef<EditContestModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.contestService
        .getContest(this.data.guid)
        .subscribe((contest: Contest) => {
          this.contest = contest;
          this.formGroup.setValue({
            name: contest.name,
            code: contest.code,
            description: contest.description,
            isSponsored: contest.isSponsored,
            hasAutoAcceptation: contest.hasAutoAcceptation,
          });
          this.dateRange.setValue({
            startAt: contest.startAt,
            endAt: contest.endAt,
          });
        });
    }
  }

  imageChanged(event: any) {
    if (this.contest) {
      if (event === null) {
        this.contest.image = null;
      } else {
        this.contest.image = event['@id'] ?? null;
      }
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    let data: Partial<Contest> = this.formGroup.value;
    data.startAt = this.dateRange.value.startAt;
    data.endAt = this.dateRange.value.endAt;
    data.image = this.contest?.image ?? null;
    if (data.image?.guid) data.image = `/s/media_objects/${data.image.guid}`;

    if (!data.isSponsored) data.description = null;

    this.errors = null;

    this.contestService
      .putContest(this.data.guid, data)
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
            this.dateRange.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      .add(() => {
        this.submitLoading = false;
      });
  }
}
