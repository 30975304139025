import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
import { ContestBonusBetUser } from 'src/entities/ContestBonusBetUser';
import { Wallet } from 'src/entities/Wallet';

@Injectable()
export class ContestBonusBetUserService {
  constructor(private apiService: APIService) {}

  public getBetsByContestAndUser(
    contestGuid: string,
    userGuid: string,
    params: any = null
  ): Observable<ContestBonusBetUser[]> {
    return this.apiService.get(
      `/admin/bets_by_contest_and_user/${contestGuid}/${userGuid}`,
      params,
      true
    );
  }

  public getWalletByContestAndUser(
    contestGuid: string,
    userGuid: string,
    params: any = null
  ): Observable<Wallet> {
    return this.apiService.get(
      `/admin/wallet_by_contest_and_user/${contestGuid}/${userGuid}`,
      params,
      true
    );
  }
}
