import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'src/services/payment.service';
import { Payment } from 'src/entities/Payment';
import { Location } from '@angular/common';
import { getTypeAsString } from 'src/entities/Product';

@Component({
  selector: 'app-show-transaction-modal',
  templateUrl: './show-transaction-modal.component.html',
  styleUrls: ['./show-transaction-modal.component.css'],
})
export class ShowTransactionModalComponent implements OnInit {
  transaction: Payment | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.paymentService
        .getPayment(this.data.guid)
        .subscribe((transaction: Payment) => {
          this.transaction = transaction;
        });
      // Change url
      this.location.replaceState(`/payments/transactions/${this.data.guid}`);
    }
  }

  getNameFromType(): string {
    if (this.transaction && this.transaction?.type !== null)
      return getTypeAsString(this.transaction.type);

    return '-';
  }
}
