import { Ad } from './Ad';
import { Contest } from './Contest';

export class AdCampain {
  readonly id: number | null = null;
  guid: string | null = null;
  name: string | null = null;
  startAt: Date | null = null;
  endAt: Date | null = null;
  allowAppodeal: boolean = true;
  isEnabled: boolean = false;
  adCampainAds: AdCampainAd[] = [];
  views: number | null = null;
  contests: Contest[] = [];
  readonly currentViewCount: number = 0;
  readonly currentSkipCount: number = 0;
  readonly currentClickCount: number = 0;
}

export class AdCampainAd {
  ad: Ad | null = null;
  guid: string | null = null;
  adCampain: AdCampain | null = null;
  probability: number = 1;
  hourlyViewsLimit: number | null = null;
  readonly currentViewCount: number = 0;
  readonly currentSkipCount: number = 0;
  readonly currentClickCount: number = 0;
}
