import { Contest } from './Contest';
import { League } from './League';
import { Sport } from './Sport';
import { Event } from './Event';

export enum SlideshowImageActionType {
  ACTION_TYPE_DO_NOTHING = 0,
  ACTION_TYPE_LEAGUE = 1,
  ACTION_TYPE_EVENT = 2,
  ACTION_TYPE_HANDICAP = 3,
  ACTION_TYPE_CONTEST = 4,
  ACTION_TYPE_URL = 5,
}

export class SlideshowImage {
  id: number | null = null;
  guid: number | null = null;
  startAt: Date | null = null;
  endAt: Date | null = null;
  isEnabled: boolean = false;
  actionType: SlideshowImageActionType =
    SlideshowImageActionType.ACTION_TYPE_DO_NOTHING;
  isAlsoForPremium: boolean = false;
  image: any | null = null;
  URL: string | null = null;
  league: League | null = null;
  event: Event | null = null;
  contest: Contest | null = null;
  slug: string | null = null;
  sport: Sport | null = null;
  visibleForContests: Contest[] = [];
}

export function getActionTypeStringFromActionType(
  actionType: SlideshowImageActionType
): string {
  switch (actionType) {
    case SlideshowImageActionType.ACTION_TYPE_DO_NOTHING:
      return '-';
    case SlideshowImageActionType.ACTION_TYPE_LEAGUE:
      return 'Ligue';
    case SlideshowImageActionType.ACTION_TYPE_EVENT:
      return 'Match';
    case SlideshowImageActionType.ACTION_TYPE_HANDICAP:
      return 'Handicap';
    case SlideshowImageActionType.ACTION_TYPE_CONTEST:
      return 'Arène';
    case SlideshowImageActionType.ACTION_TYPE_URL:
      return 'URL';
  }
}
