import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APIService } from 'src/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private apiService: APIService, private snackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && this.apiService.isUserConnected()) {
          this.snackBar.open('Votre session a expiré', undefined, {
            duration: 1500,
          });
          // auto logout if 401 response returned from api
          this.apiService.logout();
        }

        if (err.status === 422) {
          this.snackBar.open("Une erreur s'est produite", undefined, {
            duration: 1500,
          });
        }

        // const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }
}
