import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Event } from 'src/entities/Event';
import { Contest } from 'src/entities/Contest';
import { League } from 'src/entities/League';
import {
  SlideshowImage,
  SlideshowImageActionType,
} from 'src/entities/SlideshowImage';
import { Sport } from 'src/entities/Sport';
import { ContestService } from 'src/services/contest.service';
import { EventService } from 'src/services/event.service';
import { LeagueService } from 'src/services/league.service';
import { SlideshowImageService } from 'src/services/slideshow-image.service';
import { SportService } from 'src/services/sport.service';

@Component({
  selector: 'app-edit-slideshow-image-modal',
  templateUrl: './edit-slideshow-image-modal.component.html',
  styleUrls: ['./edit-slideshow-image-modal.component.css'],
})
export class EditSlideshowImageModalComponent implements OnInit {
  slideshowImage: SlideshowImage | null = null;
  submitLoading: boolean = false;
  errors: string | null = null;

  searchedLeagues: League[] = [];
  searchedContests: Contest[] = [];
  searchedEvents: Event[] = [];
  searchedSports: Sport[] = [];

  loading: boolean = true;
  imageUploadLoading: boolean = false;

  leagueSearchTerm: string = '';
  contestSearchTerm: string = '';
  eventSearchTerm: string = '';
  sportSearchTerm: string = '';

  selectedLeague: League | null = null;
  selectedContest: Contest | null = null;
  selectedEvent: Event | null = null;
  selectedSport: Sport | null = null;

  searchLeaguesSubscription: any | null = null;
  searchContestsSubscription: any | null = null;
  searchEventsSubscription: any | null = null;
  searchSportsSubscription: any | null = null;

  contestSearchLoading: boolean = false;
  leagueSearchLoading: boolean = false;
  sportSearchLoading: boolean = false;
  eventSearchLoading: boolean = false;

  formGroup = new FormGroup({
    slug: new FormControl(null, [Validators.required]),
    isEnabled: new FormControl(false),
    startAt: new FormControl(),
    endAt: new FormControl(),

    actionType: new FormControl(
      SlideshowImageActionType.ACTION_TYPE_DO_NOTHING,
      [Validators.required]
    ),
    isAlsoForPremium: new FormControl(false),
    URL: new FormControl(),
  });

  slideshowImageActionType = SlideshowImageActionType;

  actionTypes: { value: SlideshowImageActionType; name: string }[] = [
    {
      value: SlideshowImageActionType.ACTION_TYPE_DO_NOTHING,
      name: 'Aucune action',
    },
    { value: SlideshowImageActionType.ACTION_TYPE_CONTEST, name: 'Arène' },
    { value: SlideshowImageActionType.ACTION_TYPE_LEAGUE, name: 'Ligue' },
    { value: SlideshowImageActionType.ACTION_TYPE_EVENT, name: 'Match' },
    // { value: SlideshowImageActionType.ACTION_TYPE_HANDICAP, name: 'Handicap' },
    { value: SlideshowImageActionType.ACTION_TYPE_URL, name: 'URL' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leagueService: LeagueService,
    private contestService: ContestService,
    private eventService: EventService,
    private sportService: SportService,
    private slideshowImageService: SlideshowImageService,
    private dialogRef: MatDialogRef<EditSlideshowImageModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve slideshow image and init form
      this.slideshowImageService
        .getSlideshowImage(this.data.guid)
        .subscribe((slideshowImage: SlideshowImage) => {
          this.slideshowImage = slideshowImage;
          this.selectedContest = slideshowImage.contest;
          this.selectedLeague = slideshowImage.league;
          this.selectedEvent = slideshowImage.event;
          this.selectedSport = slideshowImage.sport;
          this.createFormGroup();
        });
    } else {
      this.slideshowImage = new SlideshowImage();
      this.createFormGroup();
    }
  }

  createFormGroup(): void {
    if (this.slideshowImage)
      this.formGroup.setValue({
        slug: this.slideshowImage.slug,
        isEnabled: this.slideshowImage.isEnabled,
        startAt: this.slideshowImage.startAt,
        endAt: this.slideshowImage.endAt,

        actionType: this.slideshowImage.actionType,
        isAlsoForPremium: this.slideshowImage.isAlsoForPremium,
        URL: this.slideshowImage.URL,
      });
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  imageChanged(event: any) {
    if (this.slideshowImage) {
      if (event === null) {
        this.slideshowImage.image = null;
      } else {
        this.slideshowImage.image = event['@id'] ?? null;
      }
    }
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);

    // Format fields
    if (data.startAt) {
      data.startAt = new Date(data.startAt);
      // Set the date to UTC
      data.startAt.setMinutes(
        data.startAt.getMinutes() - data.startAt.getTimezoneOffset()
      );
    }

    if (data.endAt) {
      data.endAt = new Date(data.endAt);
      // Set the date to UTC
      data.endAt.setMinutes(
        data.endAt.getMinutes() - data.endAt.getTimezoneOffset()
      );
      // Set the end date to the end of the day
      data.endAt.setUTCHours(23, 59, 59, 999);
    }

    data.league = null;
    data.contest = null;
    data.event = null;

    if (
      data.actionType === SlideshowImageActionType.ACTION_TYPE_CONTEST &&
      !this.selectedContest
    ) {
      data.actionType = SlideshowImageActionType.ACTION_TYPE_DO_NOTHING;
    }
    if (
      data.actionType === SlideshowImageActionType.ACTION_TYPE_LEAGUE &&
      !this.selectedLeague
    ) {
      data.actionType = SlideshowImageActionType.ACTION_TYPE_DO_NOTHING;
    }
    if (
      data.actionType === SlideshowImageActionType.ACTION_TYPE_EVENT &&
      !this.selectedEvent
    ) {
      data.actionType = SlideshowImageActionType.ACTION_TYPE_DO_NOTHING;
    }
    if (
      data.actionType === SlideshowImageActionType.ACTION_TYPE_URL &&
      (data.URL === null || data.URL === '')
    ) {
      data.actionType = SlideshowImageActionType.ACTION_TYPE_DO_NOTHING;
    }

    data.image = this.slideshowImage?.image;

    // Transform entities to IRIs
    if (data.image.guid) data.image = `/s/media_objects/${data.image.guid}`;
    if (this.selectedLeague)
      data.league = `/s/leagues/${this.selectedLeague.guid}`;

    if (this.selectedEvent) data.event = `/s/events/${this.selectedEvent.guid}`;

    if (this.selectedContest)
      data.contest = `/s/contests/${this.selectedContest.guid}`;

    if (this.selectedSport) data.sport = `/s/sports/${this.selectedSport.guid}`;

    if (this.slideshowImage)
      data.visibleForContests = [...this.slideshowImage.visibleForContests].map(
        (contest: Contest) => `/s/contests/${contest.guid}`
      );

    this.errors = null;

    // POST / PUT Mode
    let request: Observable<SlideshowImage> = this.data.guid
      ? this.slideshowImageService.putSlideshowImage(this.data.guid, data)
      : this.slideshowImageService.createSlideshowImage(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Search sports
   */
  searchSports(): void {
    this.sportSearchLoading = true;
    let params: any = [];
    params['translations.name'] = this.sportSearchTerm;
    params['itemsPerPage'] = 3;

    if (this.searchSportsSubscription)
      this.searchSportsSubscription.unsubscribe();

    // Search sports
    this.searchSportsSubscription = this.sportService
      .getSports(params)
      .subscribe((data: any) => {
        this.searchedSports = data['hydra:member'];
        this.sportSearchLoading = false;
      });
  }

  /**
   * Remove a visibleForContest array element
   */
  removeVisibleForContestItem(contest: Contest): void {
    this.slideshowImage?.visibleForContests.splice(
      this.slideshowImage.visibleForContests.findIndex(
        (_contest: Contest) => _contest.guid === contest.guid
      ),
      1
    );
  }

  /**
   * Add a visibleForContest element
   */
  addVisibleForContestItem(contest: Contest): void {
    this.slideshowImage?.visibleForContests.push(contest);
  }

  /**
   * Search leagues
   */
  searchLeagues(): void {
    this.leagueSearchLoading = true;
    let params: any = [];
    params['translations.name'] = this.leagueSearchTerm;
    params['itemsPerPage'] = 3;
    params[`order[id]`] = 'desc';

    if (this.searchLeaguesSubscription)
      this.searchLeaguesSubscription.unsubscribe();

    // Search leagues
    this.searchLeaguesSubscription = this.leagueService
      .getLeagues(params)
      .subscribe((data: any) => {
        this.searchedLeagues = data['hydra:member'];
        this.leagueSearchLoading = false;
      });
  }

  /**
   * Search events
   */
  searchEvents(): void {
    this.eventSearchLoading = true;
    let params: any = [];
    params['search_team'] = this.eventSearchTerm;
    params['itemsPerPage'] = 3;
    params[`order[id]`] = 'desc';

    if (this.searchEventsSubscription)
      this.searchEventsSubscription.unsubscribe();

    // Search events
    this.searchEventsSubscription = this.eventService
      .getEvents(params)
      .subscribe((data: any) => {
        this.searchedEvents = data['hydra:member'];
        this.eventSearchLoading = false;
      });
  }

  /**
   * Search contests
   */
  searchContests(): void {
    this.contestSearchLoading = true;
    let params: any = [];
    params['name'] = this.contestSearchTerm;
    params['itemsPerPage'] = 3;
    params[`order[id]`] = 'desc';

    if (this.searchContestsSubscription)
      this.searchContestsSubscription.unsubscribe();

    // Search contests
    this.searchContestsSubscription = this.contestService
      .getContests(params)
      .subscribe((data: any) => {
        this.searchedContests = data['hydra:member'];
        this.contestSearchLoading = false;
      });
  }
}
