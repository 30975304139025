import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/services/navigation.service';
import { AdService } from 'src/services/ad.service';
import { EditFullscreenAdModalComponent } from './edit-fullscreen-ad-modal/edit-fullscreen-ad-modal.component';
import { ShowFullscreenAdModalComponent } from './show-fullscreen-ad-modal/show-fullscreen-ad-modal.component';
import { Ad } from 'src/entities/Ad';

@Component({
  selector: 'app-contests',
  templateUrl: './fullscreen-ads.component.html',
  styleUrls: ['./fullscreen-ads.component.css'],
})
export class FullscreenAdsComponent implements OnInit {
  data: Ad[] = [];
  loading: boolean = false;
  // Columns to display
  displayedColumns: string[] = [
    'name',
    'minimalAge',
    'skipTime',
    'link',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private adService: AdService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Publicités');
    // Show ad if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowFullscreenAdModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.adService.getAds(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;

    return params;
  }

  /**
   * Find a slideshow image in the data by id
   */
  getAdById(id: number): Ad | null {
    const index: number = this.data.findIndex((ad: Ad) => ad.id === id);
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }

    // Set all data
    this.setData(false);
  }

  /**
   * Show the show ad modal
   */
  showAdModal(id: number): void {
    const ad: Ad | null = this.getAdById(id);

    if (ad) {
      this.dialog.open(ShowFullscreenAdModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: ad.guid,
        },
      });
    }
  }

  /**
   * Show the edit ad modal
   */
  editAdModal(id: number | null = null): void {
    let ad: Ad | null = null;

    if (id) {
      ad = this.getAdById(id);
      if (!ad) return;
    } else {
      ad = new Ad();
    }

    if (ad) {
      const dialogRef = this.dialog.open(EditFullscreenAdModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: ad.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }
}
