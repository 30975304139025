import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-copy-entity-link',
  templateUrl: './copy-entity-link.component.html',
  styleUrls: ['./copy-entity-link.component.css'],
})
export class CopyEntityLinkComponent {
  @Input() link: string = '';

  constructor(private snackBar: MatSnackBar) {}

  getLink(): string {
    return environment.defaultURL + this.link;
  }

  copiedLinkSuccess(): void {
    this.snackBar.open('Lien copié!', undefined, {
      duration: 1000,
    });
  }
}
