import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-info-message-modal',
  templateUrl: './delete-info-message-modal.component.html',
  styleUrls: ['./delete-info-message-modal.component.css'],
})
export class DeleteInfoMessageModalComponent {
  constructor() {}
}
