import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Base64Image, MediaObject } from 'src/entities/MediaObject';
import { FileService } from 'src/services/file.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-manager',
  templateUrl: './image-manager.component.html',
  styleUrls: ['./image-manager.component.css'],
})
export class ImageManagerComponent implements OnInit {
  @Input() isEditable: boolean = false;

  // Image
  @Input() image: MediaObject | null = null;

  // Chosen folder
  @Input() path: string | null = null;

  // Display height/width
  @Input() height: number | null = null;
  @Input() width: number | null = null;

  // Recommended height/width (tooltips)
  @Input() recommendedHeight: number | null = null;
  @Input() recommendedWidth: number | null = null;

  @Input() placeholder: string | null = 'Image';

  // Events
  @Output() imageUploadStart = new EventEmitter<MediaObject | null>();
  @Output() imageUploadEnd = new EventEmitter<MediaObject | null>();
  @Output() imageChanged = new EventEmitter<MediaObject | null>();

  base64: Base64Image | null = null;
  imageSource: string | SafeResourceUrl | null = null;
  loading: boolean = false;

  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // Retrieve the image if one is alreaady sete
    if (this.image?.guid) {
      this.loading = true;
      this.fileService
        .getImageAsBase64(this.image.guid)
        .subscribe((base64: Base64Image) => {
          this.base64 = base64;
          this.imageSource = base64.base64;
          this.loading = false;
        });
    }
  }

  /**
   * Upload a file to API and display a preview
   */
  uploadFile(event: any): void {
    this.imageUploadStart.emit();
    this.loading = true;

    // Todo : Check file format
    // TODO : Set default file path

    // Show preview
    let imageSource: string = URL.createObjectURL(event.target.files[0]);
    this.imageSource =
      this.sanitizer.bypassSecurityTrustResourceUrl(imageSource);

    // Send to API
    let file = event.target.files.item(0);
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('custom_path', this.path ?? '');

    this.fileService.createMediaObject(formData).subscribe(
      (_file: any) => {
        this.imageChanged.emit(_file);
      },
      () => null,
      () => {
        this.loading = false;
        this.imageUploadEnd.emit();
      }
    );
  }

  /**
   * Remove the image
   */
  clearImage(): void {
    this.image = null;
    this.base64 = null;
    this.imageSource = null;
    this.imageChanged.emit(this.image);
  }
}
