<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="trophy; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/trophies/' + trophy.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-h1">{{ trophy?.name ?? "" }}</h1>
    <div *ngIf="!trophy">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="trophy">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <app-image-manager
            *ngIf="trophy.image"
            [image]="trophy.image"
            [width]="200"
            [height]="200"
            [recommendedHeight]="800"
            [recommendedWidth]="800"
          ></app-image-manager>
          <p><strong>Nom : </strong>{{ trophy.name }}</p>
          <p>
            <strong>Catégorie : </strong
            >{{ getCategoryNameFromSlug(trophy.category?.slug ?? "") }}
          </p>
          <p><strong>Activé : </strong>{{ trophy.isEnabled | yesNo }}</p>
        </div>
      </div>
      <br />
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2><strong>Progression : </strong></h2>
          <p>
            <strong>Type : </strong>
            {{
              trophy.trophyProgressionBetWon
                ? "Nombre de paris gagnés"
                : "Cote comprise entre x et y"
            }}
          </p>

          <div *ngIf="trophy.trophyProgressionBetWon">
            <p>
              <strong>Valeur : </strong>
              {{ trophy.trophyProgressionBetWon.value }}
            </p>
          </div>
          <div *ngIf="trophy.trophyProgressionOdd">
            <p>
              <strong>Cote min : </strong>
              {{ trophy.trophyProgressionOdd.minOdd }}
            </p>
            <p>
              <strong>Cote max : </strong>
              {{ trophy.trophyProgressionOdd.maxOdd }}
            </p>
            <p>
              <strong>Valeur : </strong> {{ trophy.trophyProgressionOdd.value }}
            </p>
          </div>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex" *ngIf="trophy.trophyReward">
        <div style="flex: 1" *ngIf="trophy.trophyReward.rewards">
          <h2><strong>Récompenses : </strong></h2>
          <p
            style="text-align: center"
            *ngIf="trophy.trophyReward.rewards.length === 0"
          >
            Aucune récompense
          </p>

          <table
            mat-table
            [dataSource]="trophy.trophyReward.rewards ?? []"
            class="mat-elevation-z8"
            *ngIf="trophy.trophyReward.rewards.length > 0"
          >
            <!-- Bonus -->
            <ng-container matColumnDef="reward">
              <th mat-header-cell *matHeaderCellDef>Récompense</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['reward']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['reward']"></tr>
          </table>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2><strong>Sports : </strong></h2>
          <p style="text-align: center" *ngIf="trophy.sports.length === 0">
            Aucun sport
          </p>
          <table
            mat-table
            [dataSource]="trophy.sports"
            matSort
            class="mat-elevation-z8"
            *ngIf="trophy.sports.length > 0"
          >
            <!-- Sport -->
            <ng-container matColumnDef="sport">
              <th mat-header-cell *matHeaderCellDef>Sport</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['sport']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['sport']"></tr>
          </table>
        </div>
      </div>

      <!-- <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">Modes : TODO</div>
      </div> -->
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
