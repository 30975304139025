<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="contest; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        Edition - {{ contest?.name ?? "" }}
      </h1>
      <div *ngIf="!contest">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="contest">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                required
                placeholder="Nom de l'arène"
                formControlName="name"
              />
              <mat-error *ngIf="formGroup.controls['name']?.errors">{{
                formGroup.controls["name"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <p>
              <mat-slide-toggle formControlName="isSponsored"
                >Publique</mat-slide-toggle
              >
            </p>
            <p *ngIf="formGroup.value.isSponsored === false">
              <mat-slide-toggle formControlName="hasAutoAcceptation"
                >Acceptation automatique</mat-slide-toggle
              >
            </p>

            <app-image-manager
              *ngIf="formGroup.value.isSponsored"
              [image]="contest.image ?? null"
              [width]="300"
              [height]="166"
              [recommendedHeight]="500"
              [recommendedWidth]="890"
              [isEditable]="true"
              [path]="'contests/sponsored/'"
              (imageChanged)="imageChanged($event)"
              (imageUploadStart)="imageUploadLoading = true"
              (imageUploadEnd)="imageUploadLoading = false"
            ></app-image-manager>

            <mat-form-field
              appearance="fill"
              *ngIf="formGroup.value.isSponsored"
            >
              <mat-label>Description</mat-label>
              <textarea
                matInput
                placeholder="Description"
                formControlName="description"
              ></textarea>
              <mat-error *ngIf="formGroup.controls['description']?.errors">{{
                formGroup.controls["description"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Période</mat-label>
              <mat-date-range-input
                [formGroup]="dateRange"
                [rangePicker]="picker"
                lang="fr"
                required
              >
                <input
                  matStartDate
                  formControlName="startAt"
                  placeholder="Start date"
                  required
                />
                <input
                  matEndDate
                  formControlName="endAt"
                  placeholder="End date"
                  required
                />
              </mat-date-range-input>
              <mat-error *ngIf="formGroup.controls['startAt']?.errors">{{
                formGroup.controls["startAt"]?.errors?.error
              }}</mat-error>
              <mat-error *ngIf="formGroup.controls['endAt']?.errors">{{
                formGroup.controls["endAt"]?.errors?.error
              }}</mat-error>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Code</mat-label>
              <input matInput placeholder="Code" formControlName="code" />
              <mat-error *ngIf="formGroup.controls['code']?.errors">{{
                formGroup.controls["code"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="submitLoading || !contest || imageUploadLoading"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
