import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mode } from 'src/entities/Mode';
import { ModeService } from 'src/services/mode.service';
import { Location } from '@angular/common';
import { getTicketTypeName } from 'src/entities/ContestBonusBetUser';

@Component({
  selector: 'app-show-mode-modal',
  templateUrl: './show-mode-modal.component.html',
  styleUrls: ['./show-mode-modal.component.css'],
})
export class ShowModeModalComponent implements OnInit {
  mode: Mode | null = null;
  displayedModeBonusColumns: string[] = [
    'bonus',
    'defaultCount',
    'isEditable',
    'probability',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modeService: ModeService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.modeService.getMode(this.data.guid).subscribe((mode: Mode) => {
        this.mode = mode;
      });

      // Change url
      this.location.replaceState(`/modes/${this.data?.guid}`);
    }
  }
  getTicketTypeName(): string {
    return getTicketTypeName(this.mode?.defaultTicketType);
  }
}
