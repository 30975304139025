import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { getTypeAsString, Product, ProductType } from 'src/entities/Product';
import { PaymentService } from 'src/services/payment.service';
import { NavigationService } from 'src/services/navigation.service';
import { ShowProductModalComponent } from './show-product-modal/show-product-modal.component';
import { DeleteProductModalComponent } from './delete-product-modal/delete-product-modal.component';
import { EditProductModalComponent } from './edit-product-modal/edit-product-modal.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  data: Product[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'androidStoreName',
    'iosStoreName',
    'type',
    'isDefaultProduct',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'desc',
  };
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;

  constructor(
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Compétiteurs');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowProductModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.paymentService
      .getProducts(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['storeName'] = this.searchedValue;

    return params;
  }

  /**
   * Find a product in the data by id
   */
  getProductById(id: number): Product | null {
    const index: number = this.data.findIndex(
      (product: Product) => product.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(product: any): void {
    // Clear the order if no header is sorted
    if (product.active === '' || product.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: product.active,
        direction: product.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(product: any): void {
    this.searchedValue = (product.target as HTMLTextAreaElement).value;
    this.setData(false);
  }

  /**
   * Show the show product modal
   */
  showProductModal(id: number): void {
    const product: Product | null = this.getProductById(id);
    if (product) {
      this.dialog.open(ShowProductModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: product.guid,
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }

  getNameFromType(type: ProductType): string {
    return getTypeAsString(type);
  }

  /**
   * Show the edit discount modal
   */
  editProductModal(id: number | null = null): void {
    let product: Product | null = null;
    if (id) {
      product = this.getProductById(id);
    } else {
      product = new Product();
    }

    if (product) {
      const dialogRef = this.dialog.open(EditProductModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: product.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  deleteProduct(guid: string) {
    this.paymentService
      .deleteProduct(guid)
      .subscribe(() => this.setData(false));
  }

  /**
   * Show the delete product modal
   */
  deleteProductModal(id: number): void {
    const product: Product | null = this.getProductById(id);
    if (product?.guid) {
      const dialogRef = this.dialog.open(DeleteProductModalComponent);
      dialogRef.afterClosed().subscribe((result) => {
        // Delete the product
        if (result && product.guid) this.deleteProduct(product.guid);
      });
    }
  }
}
