import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'src/services/payment.service';
import { getTypeAsString, Product, ProductType } from 'src/entities/Product';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-product-modal',
  templateUrl: './show-product-modal.component.html',
  styleUrls: ['./show-product-modal.component.css'],
})
export class ShowProductModalComponent implements OnInit {
  product: Product | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.paymentService
        .getProduct(this.data.guid)
        .subscribe((product: Product) => {
          this.product = product;
        });
      // Change url
      this.location.replaceState(`/payments/products/${this.data.guid}`);
    }
  }

  getTypeAsString(type: ProductType | null): string {
    if (type !== null) return getTypeAsString(type);

    return '-';
  }
}
