<section class="main-section">
  <div
    style="
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    "
  >
    <mat-card
      style="flex: 0.33; margin-top: 150px"
      *ngIf="simpleBets === null && combinatedBets === null"
    >
      <mat-card-title style="text-align: center; margin-bottom: 20px"
        ><h2>Rechercher des paris</h2></mat-card-title
      >
      <mat-card-content>
        <div style="display: flex">
          <mat-form-field style="flex: 1" appearance="fill">
            <mat-label>Rechercher une arène</mat-label>
            <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
            <mat-chip-list #contestChipList [multiple]="false">
              <mat-chip
                *ngIf="selectedContest"
                (removed)="
                  selectedContest = null; contestSearchInput.value = ''
                "
              >
                {{ selectedContest.id + " - " + selectedContest.name }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                placeholder="Rechercher une arène..."
                #contestSearchInput
                [(ngModel)]="contestsSearchedValue"
                (input)="searchContests()"
                [matAutocomplete]="contestAutocomplete"
                [disabled]="selectedContest !== null"
                [hidden]="selectedContest !== null"
                [matChipInputFor]="contestChipList"
              />
            </mat-chip-list>
            <mat-autocomplete #contestAutocomplete="matAutocomplete">
              <mat-option disabled *ngIf="contestsSearchLoading"
                ><mat-spinner [diameter]="25" style="margin: auto"></mat-spinner
              ></mat-option>
              <mat-option
                *ngFor="let contest of searchedContests"
                (click)="selectedContest = contest; error = null"
              >
                {{ contest.id + " - " + contest.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div style="display: flex">
          <mat-form-field style="flex: 1" appearance="fill">
            <mat-label>Rechercher un utilisateur</mat-label>
            <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
            <mat-chip-list #userChipList [multiple]="false">
              <mat-chip
                *ngIf="selectedUser"
                (removed)="
                  selectedUser = null; error = null; userSearchInput.value = ''
                "
              >
                {{ selectedUser.id + " - " + selectedUser.username }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                placeholder="Rechercher une arène..."
                #userSearchInput
                [(ngModel)]="usersSearchedValue"
                [ngModelOptions]="{ standalone: true }"
                (input)="searchUsers()"
                [matAutocomplete]="userAutocomplete"
                [disabled]="selectedUser !== null"
                [hidden]="selectedUser !== null"
                [matChipInputFor]="userChipList"
              />
            </mat-chip-list>
            <mat-autocomplete #userAutocomplete="matAutocomplete">
              <mat-option disabled *ngIf="usersSearchLoading"
                ><mat-spinner [diameter]="25" style="margin: auto"></mat-spinner
              ></mat-option>
              <mat-option
                *ngFor="let user of searchedUsers"
                (click)="selectedUser = user"
              >
                {{ user.id + " - " + user.username }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-divider inset></mat-divider>
      <mat-card-actions
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <small style="text-align: center" *ngIf="error" style="color: red">{{
          error
        }}</small>
        <button
          mat-raised-button
          [disabled]="selectedContest === null || selectedUser === null"
          color="primary"
          (click)="getBets()"
        >
          Rechercher
        </button>
      </mat-card-actions>
      <mat-card-footer>
        <mat-progress-bar
          *ngIf="loading"
          mode="indeterminate"
        ></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>

  <div *ngIf="!loading">
    <div *ngIf="simpleBets !== null && combinatedBets !== null">
      <div>
        <button mat-raised-button color="primary" (click)="resetData()">
          <mat-icon> chevron_left </mat-icon>Nouvelle recherche
        </button>

        <button
          mat-raised-button
          color="warn"
          style="margin-left: 20px"
          (click)="banUser()"
        >
          Bannir l'utilisateur
        </button>

        <!-- Copy link -->
        <app-copy-entity-link
          *ngIf="selectedContest && selectedUser"
          [link]="
            '/bets/historic/' + selectedContest.guid + '/' + selectedUser.guid
          "
        ></app-copy-entity-link>
        <h1 style="text-align: center">
          Paris de <strong>{{ selectedUser?.username }}</strong> dans l'arène
          <strong>{{ selectedContest?.name }}</strong>
        </h1>

        <div class="card mat-elevation-z1">
          <div class="card-content">
            <div class="dashboard-summary">
              <div class="summary-item">
                <div class="value">
                  {{ wallet?.balance }}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 24">
                      <path
                        fill="currentColor"
                        d="M17,20.6V24H0v-2.7l5.1-7.6H0.3l2.3-3.4h4.8l4.6-7H0.3V0h16.3v2.8l-5.1,7.6h5.1l-2.3,3.4H9.3l-4.7,7H17z"
                      ></path>
                    </svg>
                  </sup>
                </div>
                <div class="title">en bourse</div>
              </div>
              <div class="summary-item">
                <div class="value">
                  {{ wallet?.pending }}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 24">
                      <path
                        fill="currentColor"
                        d="M17,20.6V24H0v-2.7l5.1-7.6H0.3l2.3-3.4h4.8l4.6-7H0.3V0h16.3v2.8l-5.1,7.6h5.1l-2.3,3.4H9.3l-4.7,7H17z"
                      ></path>
                    </svg>
                  </sup>
                </div>
                <div class="title">misé</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedContest && selectedUser">
        <div class="title">
          <h2>Paris simples</h2>
        </div>
        <p style="text-align: center" *ngIf="simpleBets.length === 0">
          Aucun pari simple
        </p>
        <table
          mat-table
          [dataSource]="simpleBets"
          matSort
          class="mat-elevation-z8"
          *ngIf="simpleBets.length > 0"
        >
          <!-- ID -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="oddId">
            <th mat-header-cell *matHeaderCellDef>Cote (id)</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet?.odd?.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="bonus">
            <th mat-header-cell *matHeaderCellDef>Bonus</th>
            <td mat-cell *matCellDef="let element">
              <a
                mat-dialog-close
                *ngIf="element.bonus"
                [routerLink]="['/bonuses', element.bonus?.guid ?? '']"
                >{{ element.bonus?.name | nullDash }}</a
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="betValue">
            <th mat-header-cell *matHeaderCellDef>Mise</th>
            <td mat-cell *matCellDef="let element">
              {{ element.betValue | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddValue">
            <th mat-header-cell *matHeaderCellDef>Cote</th>
            <td mat-cell *matCellDef="let element">
              {{ element.calculatedOdd | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddType">
            <th mat-header-cell *matHeaderCellDef>Cote (type)</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet.odd.oddType?.name | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddName">
            <th mat-header-cell *matHeaderCellDef>Cote (nom)</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet.odd.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddHeader">
            <th mat-header-cell *matHeaderCellDef>Cote (header)</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet.odd.header | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="calculatedGain">
            <th mat-header-cell *matHeaderCellDef>Gain</th>
            <td mat-cell *matCellDef="let element">
              {{ element.calculatedGain | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>Résultat</th>
            <td mat-cell *matCellDef="let element">
              <app-status-chip
                [color]="getChipColorFromResult(element.result)"
                [tooltip]="getChipTooltipFromResult(element.result)"
              >
              </app-status-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>Match</th>
            <td mat-cell *matCellDef="let element">
              <a
                *ngIf="element.bet.odd?.event"
                mat-dialog-close
                [routerLink]="[
                  '/bets/events',
                  element.bet.odd?.event?.guid ?? ''
                ]"
                >{{ element.bet.odd?.event?.name | nullDash }}</a
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef class="cell-border-left">
              Créé le
            </th>
            <td mat-cell *matCellDef="let element" class="cell-border-left">
              {{ element.bet.createdAt | date: "medium" | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef>Mit à jour le</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet?.odd?.updatedAt | date: "medium" | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="processedAt">
            <th mat-header-cell *matHeaderCellDef>Traité le</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet.processedAt | date: "medium" | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="finishedAt">
            <th mat-header-cell *matHeaderCellDef>Fini le</th>
            <td mat-cell *matCellDef="let element">
              {{ element.bet.finishedAt | date: "medium" | nullDash }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div *ngIf="selectedUser && selectedContest">
        <div class="title">
          <h2>Paris combinés</h2>
        </div>
        <p style="text-align: center" *ngIf="combinatedBets.length === 0">
          Aucun pari combiné
        </p>
        <table
          mat-table
          [dataSource]="combinatedBets"
          matSort
          class="mat-elevation-z8"
          *ngIf="combinatedBets.length > 0"
        >
          <!-- ID -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddId">
            <th mat-header-cell *matHeaderCellDef>Cote (id)</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.bet?.odd?.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="bonus">
            <th mat-header-cell *matHeaderCellDef>Bonus</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              <a
                *ngIf="element.bonus"
                mat-dialog-close
                [routerLink]="['/bonuses', element.bonus?.guid ?? '']"
                >{{ element.bonus?.name | nullDash }}</a
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="betValue">
            <th mat-header-cell *matHeaderCellDef>Mise</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.betValue | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddValue">
            <th mat-header-cell *matHeaderCellDef>Cote</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.oddValue | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddType">
            <th mat-header-cell *matHeaderCellDef>Cote (type)</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.bet?.odd?.oddType?.name | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddName">
            <th mat-header-cell *matHeaderCellDef>Cote (nom)</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.bet?.odd.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oddHeader">
            <th mat-header-cell *matHeaderCellDef>Cote (header)</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.bet?.odd.header | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="calculatedGain">
            <th mat-header-cell *matHeaderCellDef>Gain</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.calculatedGain | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>Résultat</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              <app-status-chip
                [color]="getChipColorFromResult(element.result)"
                [tooltip]="getChipTooltipFromResult(element.result)"
              >
              </app-status-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>Match</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              <a
                *ngIf="element.bet?.odd?.event"
                mat-dialog-close
                [routerLink]="[
                  '/bets/events',
                  element.bet?.odd?.event?.guid ?? ''
                ]"
                >{{ element.bet?.odd?.event?.name | nullDash }}</a
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef class="cell-border-left">
              Créé le
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="cell-border-left"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.createdAt | date: "medium" | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef>Mit à jour le</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{ element.bet?.odd?.updatedAt | date: "medium" | nullDash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="processedAt">
            <th mat-header-cell *matHeaderCellDef>Traité le</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{
                element.betGroup?.processedAt ?? element.bet?.processedAt
                  | date: "medium"
                  | nullDash
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="finishedAt">
            <th mat-header-cell *matHeaderCellDef>Fini le</th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.is-bet]="element.bet !== null"
            >
              {{
                element.betGroup?.finishedAt ?? element.betGroup?.finishedAt
                  | date: "medium"
                  | nullDash
              }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
<ng-template #smallLoader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner" diameter="50"></mat-spinner>
  </div>
</ng-template>
