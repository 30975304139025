import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NavigationService {
  currentPageNameSubject: Subject<string> = new Subject();

  constructor() {
    this.currentPageNameSubject.next('dqqdsqds');
  }

  setCurrentPageName(name: string): void {
    this.currentPageNameSubject.next(name);
  }

  public getCurrentPageName(): Observable<string> {
    return this.currentPageNameSubject;
  }
}
