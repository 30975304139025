import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoMessage } from 'src/entities/InfoMessage';
import { Location } from '@angular/common';
import { InfoMessageService } from 'src/services/info-message.service';

@Component({
  selector: 'app-show-info-message-modal',
  templateUrl: './show-info-message-modal.component.html',
  styleUrls: ['./show-info-message-modal.component.css'],
})
export class ShowInfoMessageModalComponent implements OnInit {
  infoMessage: InfoMessage | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private infoMessageService: InfoMessageService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.infoMessageService
        .getInfoMessage(this.data.guid)
        .subscribe((infoMessage: InfoMessage) => {
          this.infoMessage = infoMessage;
        });
      // Change url
      this.location.replaceState(`/info_messages/${this.data.guid}`);
    }
  }
}
