import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { League } from 'src/entities/League';
import { Event, EventStatus, getNameFromEventStatus } from 'src/entities/Event';
import { LeagueService } from 'src/services/league.service';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-league-modal',
  templateUrl: './show-league-modal.component.html',
  styleUrls: ['./show-league-modal.component.css'],
})
export class ShowLeagueModalComponent implements OnInit {
  league: League | null = null;
  events: Event[] = [];
  displayedColumns: string[] = ['id', 'name', 'playedAt', 'status'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leagueService: LeagueService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.leagueService
        .getLeague(this.data.guid)
        .subscribe((league: League) => {
          this.league = league;
          this.events = league.events;
        });
      // Change url
      this.location.replaceState(`/bets/events/${this.data.guid}`);
    }
  }

  getChipColorFromStatus(event: Event): ChipColor {
    switch (event.status.name) {
      case EventStatus.STATUS_NOT_STARTED:
        return ChipColor.ORANGE;
      case EventStatus.STATUS_INPLAY:
        return ChipColor.BLUE;
      case EventStatus.STATUS_TOBEFIXED:
      case EventStatus.STATUS_POSTPONED:
      case EventStatus.STATUS_CANCELLED:
      case EventStatus.STATUS_WALKOVER:
      case EventStatus.STATUS_INTERRUPTED:
      case EventStatus.STATUS_ABANDONNED:
      case EventStatus.STATUS_RETIRED:
      case EventStatus.STATUS_REMOVED:
        return ChipColor.RED;
      case EventStatus.STATUS_ENDED:
        return ChipColor.GREEN;
      default:
        return ChipColor.GREY;
    }
  }

  getChipTooltipFromStatus(event: Event): string {
    return getNameFromEventStatus(event.status);
  }
}
