<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="ad; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!ad">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="ad">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>
            <div style="flex: 1; display: flex">
              <div style="flex: 1">
                <app-image-manager
                  [image]="ad.phoneImage"
                  [height]="500"
                  [width]="282"
                  [recommendedHeight]="2208"
                  [recommendedWidth]="1242"
                  [isEditable]="true"
                  [path]="'ads/'"
                  [placeholder]="'Téléphone'"
                  (imageChanged)="phoneImageChanged($event)"
                  (imageUploadStart)="imageUploadLoading = true"
                  (imageUploadEnd)="imageUploadLoading = false"
                ></app-image-manager>
              </div>
              <div style="flex: 1">
                <app-image-manager
                  [image]="ad.tabletImage"
                  [height]="500"
                  [width]="312"
                  [recommendedHeight]="2732"
                  [recommendedWidth]="2048"
                  [isEditable]="true"
                  [path]="'ads/'"
                  [placeholder]="'Tablette'"
                  (imageChanged)="tabletImageChanged($event)"
                  (imageUploadStart)="imageUploadLoading = true"
                  (imageUploadEnd)="imageUploadLoading = false"
                ></app-image-manager>
              </div>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom"
                formControlName="name"
                required
              />
              <mat-error *ngIf="formGroup.controls['name']?.errors">{{
                formGroup.controls["name"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Lien</mat-label>
              <input
                matInput
                placeholder="http://oddzone.fr"
                formControlName="link"
                type="url"
                pattern="https?://.+"
              />
              <mat-error *ngIf="formGroup.controls['link']?.errors">{{
                formGroup.controls["link"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Age minimum</mat-label>
              <input
                matInput
                placeholder="Age minimum"
                formControlName="minimalAge"
                type="number"
              />
              <mat-error *ngIf="formGroup.controls['minimalAge']?.errors">{{
                formGroup.controls["minimalAge"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Temps avant le skip</mat-label>
              <input
                matInput
                placeholder="Temps avant le skip"
                formControlName="skipTime"
                required
                type="number"
                min="0"
              />
              <mat-error *ngIf="formGroup.controls['skipTime']?.errors">{{
                formGroup.controls["skipTime"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-slide-toggle formControlName="shownForBonuses"
              >Affiché pour les bonus</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="shownForTicket"
              >Affiché pour le ticket</mat-slide-toggle
            >
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="
        submitLoading ||
        !ad ||
        !formGroup.valid ||
        !ad.tabletImage ||
        !ad.phoneImage
      "
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
