import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import {
  Contest,
  ContestStatus,
  getNameFromContestStatus,
} from 'src/entities/Contest';
import { ContestsMetrics } from 'src/entities/ContestsMetrics';
import { ContestService } from 'src/services/contest.service';
import { NavigationService } from 'src/services/navigation.service';
import { ChipColor } from '../../status-chip/status-chip.component';
import { EditContestModalComponent } from './edit-contest-modal/edit-contest-modal.component';
import { ShowContestModalComponent } from './show-contest-modal/show-contest-modal.component';

@Component({
  selector: 'app-contests',
  templateUrl: './contests.component.html',
  styleUrls: ['./contests.component.css'],
})
export class ContestsComponent implements OnInit, OnDestroy {
  data: Contest[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'name',
    'status',
    'isSponsored',
    'isPremium',
    'mode',
    'creator',
    'userCount',
    'pendingUserCount',
    'createdAt',
    'actions',
  ];
  contestsMetrics: ContestsMetrics | null = null;
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'desc',
  };
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private contestService: ContestService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Arènes');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowContestModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Show the show contest modal
   */
  showContestModal(id: number): void {
    const contest: Contest | null = this.getContestById(id);
    if (contest) {
      this.dialog.open(ShowContestModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: contest.guid,
        },
      });
    }
  }

  /**
   * Show the edit contest modal
   */
  editContestModal(id: number): void {
    const contest: Contest | null = this.getContestById(id);
    if (contest) {
      const dialogRef = this.dialog.open(EditContestModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: contest.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Set the contest metrics (once)
    if (!this.contestsMetrics) {
      this.contestService.getContestsMetrics().subscribe((response: any) => {
        this.contestsMetrics = response;
      });
    }

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.contestService
      .getContests(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a contest in the data by id
   */
  getContestById(id: number): Contest | null {
    const index: number = this.data.findIndex(
      (contest: Contest) => contest.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(event: Event): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  getChipColorFromStatus(contest: Contest): ChipColor {
    switch (contest.status) {
      case ContestStatus.CREATED:
        return ChipColor.GREY;
      case ContestStatus.IN_PROGRESS:
        return ChipColor.GREEN;
      case ContestStatus.FINISHED:
        return ChipColor.BLACK;
    }
  }

  getChipTooltipFromStatus(contest: Contest): string {
    return getNameFromContestStatus(contest.status);
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }
}
