<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="event; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/bets/events/' + event.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ event?.name ?? "" }}
    </h1>
    <div *ngIf="!event">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="event">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Nom : </strong>{{ event?.name }}</p>
          <p>
            <strong>Nom personnalisé : </strong>{{ event.customName ?? "-" }}
          </p>
          <p>
            <strong>Domicile : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/bets/competitors', event?.home?.guid]"
              >{{ event?.home?.name ?? "-" }}</a
            >
          </p>
          <p>
            <strong>Extérieur : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/bets/competitors', event?.away?.guid]"
              >{{ event?.away?.name ?? "-" }}</a
            >
          </p>
          <p>
            <strong>Statut : </strong>{{ getNameFromEventStatus(event.status) }}
          </p>
          <p><strong>Ligue : </strong>{{ event.league.name }}</p>
          <p><strong>Sport : </strong>{{ event.sport.name }}</p>
          <p><strong>Joué le : </strong>{{ event.playedAt | date }}</p>
          <p><strong>En avant : </strong>{{ event.isFeatured }}</p>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div *ngIf="odds.length > 0; else noOdds">
        <h1 class="mat-h1" style="text-align: center">Cotes</h1>
        <div>
          <table
            mat-table
            [dataSource]="odds"
            matSort
            class="mat-elevation-z8"
            *ngIf="odds"
          >
            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nom</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- Header -->
            <ng-container matColumnDef="header">
              <th mat-header-cell *matHeaderCellDef>Header</th>
              <td mat-cell *matCellDef="let element">{{ element.header }}</td>
            </ng-container>

            <!-- Value -->
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>Valeur</th>
              <td mat-cell *matCellDef="let element">{{ element.value }}</td>
            </ng-container>

            <!-- Type -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">
                {{ element.oddType?.name }}
              </td>
            </ng-container>

            <!-- Result -->
            <ng-container matColumnDef="result">
              <th mat-header-cell *matHeaderCellDef>Résultat</th>
              <td mat-cell *matCellDef="let element">
                <app-status-chip
                  [color]="getChipColorFromResult(element)"
                  [tooltip]="getChipTooltipFromResult(element)"
                >
                </app-status-chip>
              </td>
            </ng-container>

            <!-- UpdatedAt -->
            <ng-container matColumnDef="updatedAt">
              <th mat-header-cell *matHeaderCellDef>Mise à jour</th>
              <td mat-cell *matCellDef="let element">
                {{ element.updatedAt | date }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

<ng-template #noOdds>
  <p style="text-align: center">Aucune cote</p>
</ng-template>
