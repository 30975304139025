import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VariantService } from 'src/services/variant.service';
import { Location } from '@angular/common';
import { ModeVariant } from 'src/entities/ModeVariant';

@Component({
  selector: 'app-show-variant-modal',
  templateUrl: './show-variant-modal.component.html',
  styleUrls: ['./show-variant-modal.component.css'],
})
export class ShowVariantModalComponent implements OnInit {
  variant: ModeVariant | null = null;

  displayedVariantBonusColumns: string[] = [
    'bonus',
    'defaultCount',
    'isEditable',
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private variantService: VariantService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.variantService
        .getVariant(this.data.guid)
        .subscribe((variant: ModeVariant) => {
          this.variant = variant;
        });

      // Change url
      this.location.replaceState(`/variants/${this.data?.guid}`);
    }
  }
}
