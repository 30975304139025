<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="league; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/bets/leagues/' + league.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ league?.name ?? "" }}
    </h1>
    <div *ngIf="!league">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="league">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Nom : </strong>{{ league.name }}</p>
          <p><strong>Pays : </strong>{{ league.country?.name | nullDash }}</p>
          <p><strong>Sport : </strong>{{ league.sport?.name | nullDash }}</p>
          <p><strong>En avant : </strong>{{ league.isFeatured | yesNo }}</p>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div *ngIf="events.length > 0; else noEvents">
        <h1 class="mat-h1" style="text-align: center">Matchs</h1>
        <div>
          <table
            mat-table
            [dataSource]="events"
            matSort
            class="mat-elevation-z8"
            *ngIf="events"
          >
            <!-- ID -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nom</th>
              <td mat-cell *matCellDef="let element">
                <a
                  mat-dialog-close
                  [routerLink]="['/bets/events', element.guid]"
                  >{{ element.name }}</a
                >
              </td>
            </ng-container>

            <!-- PlayedAt -->
            <ng-container matColumnDef="playedAt">
              <th mat-header-cell *matHeaderCellDef>Joué le</th>
              <td mat-cell *matCellDef="let element">
                {{ element.playedAt | date }}
              </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Statut</th>
              <td mat-cell *matCellDef="let element">
                <app-status-chip
                  [color]="getChipColorFromStatus(element)"
                  [tooltip]="getChipTooltipFromStatus(element)"
                >
                </app-status-chip>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

<ng-template #noEvents>
  <p style="text-align: center">Aucun match</p>
</ng-template>
