export enum ProductType {
  TYPE_PREMIUM_CONTEST_PASS = 0,
}

export class Product {
  id: number | null = null;
  guid: string | null = null;
  androidStoreName: string | null = null;
  iosStoreName: string | null = null;
  type: ProductType | null = null;
  isDefaultProduct: boolean = false;
}

export function getTypeAsString(type: ProductType | null): string {
  switch (type) {
    case ProductType.TYPE_PREMIUM_CONTEST_PASS:
      return 'Accès à une arène premium';
    default:
      return '-';
  }
}
