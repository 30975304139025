import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationModel } from 'src/entities/Notification';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private apiService: APIService) {}

  public getNotificationModels(
    params: any = null
  ): Observable<NotificationModel[]> {
    return this.apiService.get(`/notification_models`, params, true);
  }
  public getNotificationModel(
    guid: string,
    params: any = null
  ): Observable<NotificationModel> {
    return this.apiService.get(`/notification_models/${guid}`, params, true);
  }

  public createNotificationModel(data: any): Observable<NotificationModel> {
    return this.apiService.post(`/notification_models`, data);
  }

  public putNotificationModel(
    guid: string,
    data: any
  ): Observable<NotificationModel> {
    return this.apiService.put(`/notification_models/${guid}`, data);
  }

  public createCustomNotification(data: any): Observable<void> {
    return this.apiService.post(`/admin/create_custom_notification`, data);
  }
}
