<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="product; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.storeName ?? "" }}
      </h1>
      <div *ngIf="!product">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="product">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <mat-form-field appearance="fill">
              <mat-label>Id sur le store - Android</mat-label>
              <input
                matInput
                required
                placeholder="Id du store - Android"
                formControlName="androidStoreName"
              />
              <mat-error
                *ngIf="formGroup.controls['androidStoreName']?.errors"
                >{{
                  formGroup.controls["androidStoreName"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Id sur le store - iOS</mat-label>
              <input
                matInput
                required
                placeholder="Id du store - iOS"
                formControlName="iosStoreName"
              />
              <mat-error *ngIf="formGroup.controls['iosStoreName']?.errors">{{
                formGroup.controls["iosStoreName"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let type of types" [value]="type.value">
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <p>
              <mat-slide-toggle formControlName="isDefaultProduct">
                Produit par défaut pour ce type</mat-slide-toggle
              >
            </p>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="submitLoading || !product"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
