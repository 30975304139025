<button mat-button [matMenuTriggerFor]="userMenu">
  {{ getFlagFromLanguage(selectedLanguage) }}
  <mat-icon> arrow_drop_down </mat-icon>
</button>
<mat-menu #userMenu="matMenu" xPosition="after" yPosition="below">
  <button
    mat-menu-item
    *ngFor="let language of getAvailableLanguages()"
    (click)="changeLanguage(language)"
  >
    <span>{{ getFlagFromLanguage(language) }}</span>
  </button>
</mat-menu>
