import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationModel } from 'src/entities/Notification';
import { NotificationService } from 'src/services/notification.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-notification-modal',
  templateUrl: './show-notification-modal.component.html',
  styleUrls: ['./show-notification-modal.component.css'],
})
export class ShowNotificationModalComponent implements OnInit {
  notification: NotificationModel | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.notificationService
        .getNotificationModel(this.data.guid)
        .subscribe((notification: NotificationModel) => {
          this.notification = notification;
        });
      // Change url
      this.location.replaceState(`/notifications/${this.data?.guid}`);
    }
  }
}
