import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (typeof value === 'boolean') return value ? 'Oui' : 'Non';
    return value;
  }
}
