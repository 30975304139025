import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-prize-modal',
  templateUrl: './delete-prize-modal.component.html',
  styleUrls: ['./delete-prize-modal.component.css'],
})
export class DeletePrizeModalComponent {
  constructor() {}
}
