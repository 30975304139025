import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { NotificationModel } from 'src/entities/Notification';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'app-general-notification-modal',
  templateUrl: './general-notification-modal.component.html',
  styleUrls: ['./general-notification-modal.component.css'],
})
export class GeneralNotificationModalComponent {
  submitLoading: boolean = false;
  errors: string | null = null;
  isBigMatchNotification: boolean = false;
  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    content: new FormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<GeneralNotificationModalComponent>
  ) {
    this.isBigMatchNotification = this.data.isBigMatchNotification ?? false;
  }

  showConfirmModal(): void {
    if (confirm('Envoyer la notification à tous les utilisateurs ? '))
      this.onSubmit();
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);
    data['isBigMatchNotification'] = this.isBigMatchNotification;

    this.errors = null;

    // POST Notification
    this.notificationService
      .createCustomNotification(data)
      .subscribe(
        (res: any) => {
          this.closeModal();
        },
        (response: any) => {
          // Show errors
          response.error?.violations?.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Create the notification form group
   */
  createNotificationFormGroup(): void {
    this.formGroup.setValue({
      title: '',
      content: '',
    });
  }
}
