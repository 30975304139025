import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contest } from 'src/entities/Contest';
import { ContestsMetrics } from 'src/entities/ContestsMetrics';
import { APIService } from './api.service';

@Injectable()
export class ContestService {
  constructor(private apiService: APIService) {}

  public getContests(params: any = null): Observable<Contest[]> {
    return this.apiService.get(`/admin/all_contests`, params, true);
  }
  public getContest(guid: string): Observable<Contest> {
    return this.apiService.get(`/contests/${guid}`);
  }
  public putContest(guid: string, body: Partial<Contest>): Observable<Contest> {
    return this.apiService.put(`/contests/${guid}`, body);
  }
  public getContestsMetrics(): Observable<ContestsMetrics> {
    return this.apiService.get(`/admin/contests_metrics`);
  }
}
