<section class="main-section">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Période</mat-label>
      <mat-date-range-input
        [formGroup]="range"
        [rangePicker]="picker"
        lang="fr"
      >
        <input
          matStartDate
          formControlName="start"
          placeholder="Start date"
          [(ngModel)]="startDate"
        />
        <input
          matEndDate
          formControlName="end"
          placeholder="End date"
          [(ngModel)]="endDate"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker
        #picker
        (closed)="onSubmit()"
      ></mat-date-range-picker>
      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
        >Invalid start date</mat-error
      >
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
        >Invalid end date</mat-error
      >
    </mat-form-field>
  </div>

  <mat-divider></mat-divider>
  <div>
    <ng-container *ngIf="!loading; else loader">
      <div class="chartsContainer">
        <div class="chartContainer">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Nouveaux utilisateurs</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas #newUsersChart></canvas>
              <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-footer>
              <span
                ><strong>Nouveaux utilisateurs</strong> :
                {{ newUsersCount | number }}</span
              >
            </mat-card-footer>
          </mat-card>
        </div>
        <div class="chartContainer">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Nouvelles arènes</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas #newContestsChart></canvas>
              <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-footer>
              <span
                ><strong>Nouvelles arènes Freemium</strong> :
                {{ newFreemiumContestsCount | number }}</span
              ><br />
              <span
                ><strong>Nouvelles arènes Premium</strong> :
                {{ newPremiumContestsCount | number }}</span
              ><br />
            </mat-card-footer>
          </mat-card>
        </div>
        <div class="chartContainer">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Joueurs actifs</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas #activeUsersChart></canvas>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="chartContainer">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Arènes actives</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas #activeContestsChart></canvas>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="chartContainer">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Paris créés</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas #newBetsChart></canvas>
              <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-footer>
              <span><strong>Total</strong> : {{ newBetsCount | number }}</span>
            </mat-card-footer>
          </mat-card>
        </div>
        <div class="chartContainer">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Bonus débloqués</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas #retrievedBonusesChart></canvas>
              <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-footer>
              <span
                ><strong>Total</strong> :
                {{ retrievedBonusesCount | number }}</span
              >
            </mat-card-footer>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #loader>
    <div class="spinner-wrapper">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </ng-template>
</section>
