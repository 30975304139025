<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="trophy; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!trophy">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="trophy">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>

            <app-image-manager
              [image]="trophy.image"
              [width]="200"
              [height]="200"
              [recommendedHeight]="800"
              [recommendedWidth]="800"
              [isEditable]="true"
              [path]="'trophies/'"
              (imageChanged)="imageChanged($event)"
              (imageUploadStart)="imageUploadLoading = true"
              (imageUploadEnd)="imageUploadLoading = false"
            ></app-image-manager>

            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom"
                formControlName="name"
                required
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['name']?.errors">{{
                formGroup.controls["name"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Catégorie</mat-label>
              <mat-select formControlName="category" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option
                  *ngFor="let category of categoryOptions"
                  [value]="category.value"
                >
                  {{ category.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-slide-toggle formControlName="isEnabled"
              >Activé</mat-slide-toggle
            >
          </div>
        </div>

        <mat-divider style="margin: 10px 0"></mat-divider>

        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Progression</h2>
            <mat-form-field appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select [(value)]="progressionType">
                <mat-option value="trophyProgressionBetWon"
                  >Nombre de paris réussis</mat-option
                >
                <mat-option value="trophyProgressionOdd"
                  >Cote comprise entre x et y</mat-option
                >
              </mat-select>
            </mat-form-field>

            <div *ngIf="progressionType === 'trophyProgressionBetWon'">
              <mat-form-field appearance="fill">
                <mat-label>Valeur</mat-label>
                <input
                  matInput
                  placeholder="Valeur"
                  formControlName="trophyProgressionBetWon_value"
                  type="number"
                  required
                />
                <mat-error
                  *ngIf="
                    formGroup.controls['trophyProgressionBetWon_value']?.errors
                  "
                  >{{
                    formGroup.controls["trophyProgressionBetWon_value"]?.errors
                      ?.error
                  }}</mat-error
                >
              </mat-form-field>
            </div>

            <div *ngIf="progressionType === 'trophyProgressionOdd'">
              <mat-form-field appearance="fill">
                <mat-label>Cote minimale</mat-label>
                <input
                  matInput
                  placeholder="Cote minimale"
                  formControlName="trophyProgressionOdd_minOdd"
                  type="number"
                  required
                />
                <mat-error
                  *ngIf="
                    formGroup.controls['trophyProgressionOdd_minOdd']?.errors
                  "
                  >{{
                    formGroup.controls["trophyProgressionOdd_minOdd"]?.errors
                      ?.error
                  }}</mat-error
                >
              </mat-form-field>
              <br />
              <mat-form-field appearance="fill">
                <mat-label>Cote maximale</mat-label>
                <input
                  matInput
                  placeholder="Cote maximale"
                  formControlName="trophyProgressionOdd_maxOdd"
                  type="number"
                  required
                />
                <mat-error
                  *ngIf="
                    formGroup.controls['trophyProgressionOdd_maxOdd']?.errors
                  "
                  >{{
                    formGroup.controls["trophyProgressionOdd_maxOdd"]?.errors
                      ?.error
                  }}</mat-error
                >
              </mat-form-field>
              <br />
              <mat-form-field appearance="fill">
                <mat-label>Valeur</mat-label>
                <input
                  matInput
                  placeholder="Valeur"
                  formControlName="trophyProgressionOdd_value"
                  type="number"
                  required
                />
                <mat-error
                  *ngIf="
                    formGroup.controls['trophyProgressionOdd_value']?.errors
                  "
                  >{{
                    formGroup.controls["trophyProgressionOdd_value"]?.errors
                      ?.error
                  }}</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Rewards -->
        <mat-divider style="margin: 10px 0"></mat-divider>

        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Récompenses</h2>
            <small
              >Les bonus selectionnés doivent être définis comme bonus du
              mode.</small
            >
            <div style="display: flex" *ngIf="trophy.trophyReward">
              <mat-form-field
                appearance="fill"
                *ngIf="trophy.trophyReward.rewards.length < 2"
              >
                <mat-label>Rechercher un bonus</mat-label>
                <input
                  matInput
                  placeholder="Recherche"
                  type="text"
                  [(ngModel)]="bonusSearchTerm"
                  [ngModelOptions]="{ standalone: true }"
                  (input)="searchBonuses()"
                />
              </mat-form-field>
              <div
                style="margin-left: 20px"
                *ngFor="let bonus of searchedBonuses"
              >
                {{ bonus.name }}
                <button
                  type="button"
                  mat-raised-button
                  color="accent"
                  (click)="addBonus(bonus)"
                >
                  Ajouter
                </button>
              </div>
            </div>
            <div>
              <table
                mat-table
                [dataSource]="trophy.trophyReward?.rewards ?? []"
                matSort
                class="mat-elevation-z8"
                *ngIf="trophy.trophyReward?.rewards ?? []"
              >
                <!-- ID -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>

                <!-- Name -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Nom</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex; justify-content: space-evenly">
                      <mat-icon
                        matTooltip="Retirer"
                        class="cursor_pointer"
                        (click)="removeBonus(element)"
                        color="accent"
                        >highlight_off</mat-icon
                      >
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedBonusesColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedBonusesColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <!-- <div style="flex: 1; display: flex; justify-content: center" *ngIf="formGroup.value.category !== stadiumCategoryGuid"> -->
        <div
          style="flex: 1; display: flex; justify-content: center"
          *ngIf="formGroup.value.category !== stadiumCategoryGuid"
        >
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Sport</h2>
            <div style="display: flex">
              <mat-form-field appearance="fill">
                <mat-label>Rechercher un sport</mat-label>
                <input
                  matInput
                  placeholder="Recherche"
                  type="text"
                  [(ngModel)]="sportSearchTerm"
                  [ngModelOptions]="{ standalone: true }"
                  (input)="searchSports()"
                />
              </mat-form-field>
              <div
                style="margin-left: 20px"
                *ngFor="let sport of searchedSports"
              >
                {{ sport.name }}
                <button
                  type="button"
                  mat-raised-button
                  color="accent"
                  (click)="addSport(sport)"
                >
                  Ajouter
                </button>
              </div>
            </div>
            <div>
              <table
                mat-table
                [dataSource]="trophy.sports"
                matSort
                class="mat-elevation-z8"
                *ngIf="trophy.sports"
              >
                <!-- ID -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>

                <!-- Name -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Nom</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex; justify-content: space-evenly">
                      <mat-icon
                        matTooltip="Retirer"
                        class="cursor_pointer"
                        (click)="removeSport(element)"
                        color="accent"
                        >highlight_off</mat-icon
                      >
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedSportsColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedSportsColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitLoading || !trophy || !formGroup.valid || !trophy.image"
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
