import { Competitor } from './Competitor';
import { League } from './League';
import { Odd } from './Odd';
import { Sport } from './Sport';

export const enum EventStatus {
  STATUS_NOT_STARTED = 'NOT_STARTED',
  STATUS_INPLAY = 'INPLAY',
  STATUS_TOBEFIXED = 'TOBEFIXED',
  STATUS_ENDED = 'ENDED',
  STATUS_POSTPONED = 'POSTPONED',
  STATUS_CANCELLED = 'CANCELLED',
  STATUS_WALKOVER = 'WALKOVER',
  STATUS_INTERRUPTED = 'INTERRUPTED',
  STATUS_ABANDONNED = 'ABANDONNED',
  STATUS_RETIRED = 'RETIRED',
  STATUS_REMOVED = 'REMOVED',
}

export interface Event {
  id: number;
  guid: string;
  name: string;
  customName: string;
  status: { name: string };
  playedAt: Date;
  league: League;
  sport: Sport;
  isFeatured: boolean;
  odds: Odd[];
  home: Competitor;
  away: Competitor;
  hasIndividualOdds: boolean;
}

export function getNameFromEventStatus(status: { name: string }): string {
  switch (status.name) {
    case EventStatus.STATUS_NOT_STARTED:
      return 'Non commencé';
    case EventStatus.STATUS_INPLAY:
      return 'En cours';
    case EventStatus.STATUS_TOBEFIXED:
    case EventStatus.STATUS_POSTPONED:
    case EventStatus.STATUS_CANCELLED:
    case EventStatus.STATUS_WALKOVER:
    case EventStatus.STATUS_INTERRUPTED:
    case EventStatus.STATUS_ABANDONNED:
    case EventStatus.STATUS_RETIRED:
    case EventStatus.STATUS_REMOVED:
      return 'Annulé';
    case EventStatus.STATUS_ENDED:
      return 'Terminé';
    default:
      return '';
  }
}
