import { Component } from '@angular/core';

@Component({
  selector: 'app-bets',
  templateUrl: './bets.component.html',
  styleUrls: ['./bets.component.css'],
})
export class BetsComponent {
  constructor() {}
}
