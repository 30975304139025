<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="adCampain; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/ads/campains/' + adCampain.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ adCampain?.name ?? "" }}
    </h1>
    <div *ngIf="!adCampain">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="adCampain">
      <div>
        <div>
          <p><strong>Nom : </strong>{{ adCampain.name }}</p>
          <p><strong>Vues : </strong>{{ adCampain.currentViewCount }}</p>
          <p>
            <strong>Vues (complètes): </strong>{{ adCampain.currentSkipCount }}
          </p>
          <p>
            <strong>Vues (non terminées): </strong
            >{{ adCampain.currentViewCount - adCampain.currentSkipCount }}
          </p>
          <p><strong>Clics : </strong>{{ adCampain.currentClickCount }}</p>
          <p>
            <strong>Limite de vues : </strong>{{ adCampain.views | nullDash }}
          </p>
          <p>
            <strong>Date de début : </strong
            >{{ adCampain.startAt | date: "medium" | nullDash }}
          </p>
          <p>
            <strong>Date de fin : </strong
            >{{ adCampain.endAt | date: "medium" | nullDash }}
          </p>
          <p><strong>Activé : </strong>{{ adCampain.isEnabled | yesNo }}</p>
          <p>
            <strong>Autorise appodeal : </strong
            >{{ adCampain.allowAppodeal | yesNo }}
          </p>
          <p *ngIf="adCampain.contests.length > 0">
            <strong>Activé pour les arènes : </strong
            ><span *ngFor="let contest of adCampain.contests; let isLast = last"
              >{{ contest.name }}{{ isLast ? "" : ", " }}</span
            >
          </p>
        </div>
      </div>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Publicités : </strong></p>

          <p
            *ngIf="
              adCampain.allowAppodeal === true && sumAdsProbabilities() < 1
            "
          >
            <strong>Probabilité d'avoir une publicité appodeal :</strong>
            {{ calculateOtherProbabilities() | number }}
          </p>
          <p
            *ngIf="
              adCampain.allowAppodeal === false && sumAdsProbabilities() < 1
            "
          >
            Probabilité de ne pas avoir de publicité :
            {{ calculateOtherProbabilities() | number }}
          </p>

          <table
            mat-table
            [dataSource]="adCampain.adCampainAds"
            matSort
            class="mat-elevation-z8"
            style="width: 90%"
            *ngIf="adCampain.adCampainAds.length > 0"
          >
            <!-- Images -->
            <ng-container matColumnDef="images">
              <th mat-header-cell *matHeaderCellDef>Images</th>
              <td mat-cell *matCellDef="let element">x</td>
            </ng-container>

            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nom</th>
              <td mat-cell *matCellDef="let element">
                {{ element.ad.name }}
              </td>
            </ng-container>

            <!-- Probability -->
            <ng-container matColumnDef="probability">
              <th mat-header-cell *matHeaderCellDef>Probabilité</th>
              <td mat-cell *matCellDef="let element">
                {{ element.probability | number }}
              </td>
            </ng-container>

            <!-- Views -->
            <ng-container matColumnDef="views">
              <th mat-header-cell *matHeaderCellDef>Vues</th>
              <td mat-cell *matCellDef="let element">
                {{ element.currentViewCount | number }}
              </td>
            </ng-container>

            <!-- Views -->
            <ng-container matColumnDef="skipped">
              <th mat-header-cell *matHeaderCellDef>Vues (complètes)</th>
              <td mat-cell *matCellDef="let element">
                {{ element.currentViewCount | number }}
              </td>
            </ng-container>

            <!-- Unfinished -->
            <ng-container matColumnDef="unfinished">
              <th mat-header-cell *matHeaderCellDef>Vues (non terminées)</th>
              <td mat-cell *matCellDef="let element">
                {{
                  element.currentViewCount - element.currentSkipCount | number
                }}
              </td>
            </ng-container>

            <!-- Clicks -->
            <ng-container matColumnDef="clicks">
              <th mat-header-cell *matHeaderCellDef>Clics</th>
              <td mat-cell *matCellDef="let element">
                {{ element.currentClickCount | number }}
              </td>
            </ng-container>

            <!-- HourlyViewsLimit -->
            <ng-container matColumnDef="hourlyViewLimit">
              <th mat-header-cell *matHeaderCellDef>Max / H</th>
              <td mat-cell *matCellDef="let element">
                {{ element.hourlyViewsLimit | number | nullDash }}
              </td>
            </ng-container>

            <!-- Actions -->
            <!-- <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <a (click)="removeAdCampainAd(element)">Remove</a>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
