import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdCampain } from 'src/entities/AdCampain';
import { APIService } from './api.service';

@Injectable()
export class AdCampainService {
  constructor(private apiService: APIService) {}

  public getAdCampains(params: any = null): Observable<AdCampain[]> {
    return this.apiService.get(`/ad_campains`, params, true);
  }
  public getAdCampain(guid: string): Observable<AdCampain> {
    return this.apiService.get(`/ad_campains/${guid}.json`);
  }

  public putAdCampain(
    guid: string,
    body: Partial<AdCampain>
  ): Observable<AdCampain> {
    return this.apiService.put(`/ad_campains/${guid}`, body);
  }
  public createAdCampain(body: Partial<AdCampain>): Observable<AdCampain> {
    return this.apiService.post(`/ad_campains`, body);
  }
  public deleteAdCampain(guid: string): Observable<AdCampain> {
    return this.apiService.delete(`/ad_campains/${guid}`);
  }
}
