import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoMessage } from 'src/entities/InfoMessage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InfoMessageService } from 'src/services/info-message.service';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-edit-info-message-modal',
  templateUrl: './edit-info-message-modal.component.html',
  styleUrls: ['./edit-info-message-modal.component.css'],
})
export class EditInfoMessageModalComponent implements OnInit {
  infoMessage: InfoMessage | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    startAt: new FormControl(null, Validators.required),
    endAt: new FormControl(null, Validators.required),
    content: new FormControl(null, Validators.required),
  });
  errors: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private infoMessageService: InfoMessageService,
    private languageService: LanguageService,
    private dialogRef: MatDialogRef<EditInfoMessageModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.infoMessageService
        .getInfoMessage(this.data.guid)
        .subscribe((infoMessage: InfoMessage) => {
          this.infoMessage = infoMessage;
          this.createFormGroup();
        });
    } else {
      this.infoMessage = new InfoMessage();
      this.createFormGroup();
    }
  }

  createFormGroup(): void {
    if (this.infoMessage) {
      this.formGroup.setValue({
        startAt: this.infoMessage.startAt,
        endAt: this.infoMessage.endAt,
        content: this.infoMessage.content,
      });
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    let data: Partial<InfoMessage> = this.formGroup.value;

    data = this.createTranslations(data);

    this.errors = null;

    let request = this.data.guid
      ? this.infoMessageService.putInfoMessage(this.data.guid, data)
      : this.infoMessageService.createInfoMessage(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      .add(() => {
        this.submitLoading = false;
      });
  }

  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      content: data.content === '' ? null : data.content,
    };

    delete data.content;

    // Retrieve all translations and set the new one
    data.translations = this.infoMessage?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }
}
