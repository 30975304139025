import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Prize } from 'src/entities/Prize';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PrizeService {
  constructor(private apiService: APIService) {}

  public getPrizesByContest(
    contestGuid: string,
    params: any = null
  ): Observable<Prize[]> {
    return this.apiService.get(
      `/admin/prizes_by_contest/${contestGuid}`,
      params,
      true
    );
  }

  public getPrize(guid: string, params: any = null): Observable<Prize> {
    return this.apiService.get(`/prizes/${guid}`, params, true);
  }

  public putPrize(guid: string, data: any): Observable<Prize> {
    return this.apiService.put(`/prizes/${guid}`, data);
  }

  public deletePrize(guid: string): Observable<Prize> {
    return this.apiService.delete(`/prizes/${guid}`);
  }

  public createPrize(data: any): Observable<Prize> {
    return this.apiService.post(`/prizes`, data);
  }
}
