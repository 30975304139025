import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { League } from 'src/entities/League';
import { LeagueService } from 'src/services/league.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-edit-league-modal',
  templateUrl: './edit-league-modal.component.html',
  styleUrls: ['./edit-league-modal.component.css'],
})
export class EditLeagueModalComponent implements OnInit {
  league: League | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    isFeatured: new FormControl(),
  });
  errors: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leagueService: LeagueService,
    private dialogRef: MatDialogRef<EditLeagueModalComponent>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.leagueService
        .getLeague(this.data.guid)
        .subscribe((league: League) => {
          this.league = league;
          this.formGroup.setValue({
            name: league.name,
            isFeatured: league.isFeatured,
          });
        });
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    let data: Partial<League> = this.formGroup.value;

    data = this.createTranslations(data);

    this.errors = null;

    this.leagueService
      .putLeague(this.data.guid, data)
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      .add(() => {
        this.submitLoading = false;
      });
  }

  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      name: data.name,
    };

    // Retrieve all translations and set the new one
    data.translations = this.league?.translations;
    data.translations[language] = translation;
    return data;
  }
}
