<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="infoMessage; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        Message d'information
      </h1>
      <div *ngIf="!infoMessage">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="infoMessage">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <mat-form-field appearance="fill">
              <mat-label>Date de début</mat-label>
              <input
                matInput
                [matDatepicker]="startAtPicker"
                formControlName="startAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['startAt']?.errors">{{
                formGroup.controls["startAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Date de fin</mat-label>
              <input
                matInput
                [matDatepicker]="endAtPicker"
                formControlName="endAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['endAt']?.errors">{{
                formGroup.controls["endAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Contenu</mat-label>
              <textarea
                matInput
                placeholder="Contenu"
                formControlName="content"
              ></textarea>
              <mat-error *ngIf="formGroup.controls['content']?.errors">{{
                formGroup.controls["content"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="submitLoading || !infoMessage"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
