<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="transaction; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/payments/transactions/' + transaction.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      Paiement du {{ transaction.createdAt | date: "dd/MM/yyyy" }} par
      {{ transaction.contestUser?.user?.username }}
    </h1>
    <div *ngIf="!transaction">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="transaction">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Type : </strong>{{ getNameFromType() }}</p>
          <p>
            <strong>Utilisateur : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/users', transaction.contestUser?.user?.guid]"
              >{{ transaction.contestUser?.user?.username }}</a
            >
          </p>
          <p>
            <strong>Arène : </strong
            ><a
              mat-dialog-close
              [routerLink]="[
                '/contests',
                transaction.contestUser?.contest?.guid
              ]"
              >{{ transaction.contestUser?.contest?.name }}</a
            >
          </p>
          <p *ngIf="transaction.discount">
            <strong>Promotion : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/payments/discounts', transaction.discount?.guid]"
              >{{ transaction.discount?.code }}</a
            >
          </p>
          <p>
            <strong>Montant : </strong
            >{{ transaction.amount | currency: "EUR" }}
          </p>
          <p *ngIf="transaction.reference">
            <strong>Référence : </strong>{{ transaction.reference }}
          </p>
          <p>
            <strong>Créé le : </strong
            >{{ transaction.createdAt | date: "medium" }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
