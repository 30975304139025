import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InfoMessage } from 'src/entities/InfoMessage';
import { NavigationService } from 'src/services/navigation.service';
import { ShowInfoMessageModalComponent } from './show-info-message-modal/show-info-message-modal.component';
import { DeleteInfoMessageModalComponent } from './delete-info-message-modal/delete-info-message-modal.component';
import { EditInfoMessageModalComponent } from './edit-info-message-modal/edit-info-message-modal.component';
import { InfoMessageService } from 'src/services/info-message.service';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-info-messages',
  templateUrl: './info-messages.component.html',
  styleUrls: ['./info-messages.component.css'],
})
export class InfoMessagesComponent implements OnInit, OnDestroy {
  data: InfoMessage[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['id', 'startAt', 'endAt', 'content', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'desc',
  };
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;
  languageSubscription: any = null;

  constructor(
    private infoMessageService: InfoMessageService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName("Messages d'information");
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowInfoMessageModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();

    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.infoMessageService
      .getInfoMessages(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;

    return params;
  }

  /**
   * Find a product in the data by id
   */
  getInfoMessageById(id: number): InfoMessage | null {
    const index: number = this.data.findIndex(
      (product: InfoMessage) => product.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(product: any): void {
    // Clear the order if no header is sorted
    if (product.active === '' || product.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: product.active,
        direction: product.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(infoMessage: any): void {
    this.searchedValue = (infoMessage.target as HTMLTextAreaElement).value;
    this.setData(false);
  }

  /**
   * Show the show product modal
   */
  showInfoMessageModal(id: number): void {
    const infoMessage: InfoMessage | null = this.getInfoMessageById(id);
    if (infoMessage) {
      this.dialog.open(ShowInfoMessageModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: infoMessage.guid,
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
  /**
   * Show the edit discount modal
   */
  editInfoMessageModal(id: number | null = null): void {
    let infoMessage: InfoMessage | null = null;
    if (id) {
      infoMessage = this.getInfoMessageById(id);
    } else {
      infoMessage = new InfoMessage();
    }

    if (infoMessage) {
      const dialogRef = this.dialog.open(EditInfoMessageModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: infoMessage.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  deleteInfoMessage(guid: string) {
    this.infoMessageService
      .deleteInfoMessage(guid)
      .subscribe(() => this.setData(false));
  }

  /**
   * Show the delete product modal
   */
  deleteInfoMessageModal(id: number): void {
    const product: InfoMessage | null = this.getInfoMessageById(id);
    if (product?.guid) {
      const dialogRef = this.dialog.open(DeleteInfoMessageModalComponent);
      dialogRef.afterClosed().subscribe((result) => {
        // Delete the product
        if (result && product.guid) this.deleteInfoMessage(product.guid);
      });
    }
  }
}
