import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AdsComponent } from './admin/ads/ads.component';
import { BonusesComponent } from './admin/bonuses/bonuses.component';
import { ContestsComponent } from './admin/contests/contests.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { KPIComponent } from './admin/dashboard/kpi/kpi.component';
import { MetricsComponent } from './admin/dashboard/metrics/metrics.component';
import { ModesComponent } from './admin/modes/modes.component';
import { UsersComponent } from './admin/users/users.component';
import { SlideshowImagesComponent } from './admin/ads/slideshow-images/slideshow-images.component';
import { AuthGuard } from './helpers/auth.gard';
import { LoginComponent } from './login/login.component';
import { LeaguesComponent } from './admin/bets/leagues/leagues.component';
import { EventsComponent } from './admin/bets/events/events.component';
import { CompetitorsComponent } from './admin/bets/competitors/competitors.component';
import { HistoricComponent } from './admin/bets/historic/historic.component';
import { PrizesComponent } from './admin/prizes/prizes.component';
import { TrophiesComponent } from './admin/trophies/trophies.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { FullscreenAdsComponent } from './admin/ads/fullscreen-ads/fullscreen-ads.component';
import { CampainsComponent } from './admin/ads/campains/campains.component';
import { VariantsComponent } from './admin/variants/variants.component';
import { DiscountsComponent } from './admin/payments/discounts/discounts.component';
import { TransactionsComponent } from './admin/payments/transactions/transactions.component';
import { ProductsComponent } from './admin/payments/products/products.component';
import { PaymentsComponent } from './admin/payments/payments.component';
import { InfoMessagesComponent } from './admin/info-messages/info-messages.component';

const routes: Routes = [
  // Anonymous user
  { path: 'login', component: LoginComponent },

  // Connected user
  { path: '', redirectTo: '', pathMatch: 'full' },

  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        children: [
          { path: 'metrics', component: MetricsComponent },
          { path: 'kpi', component: KPIComponent },
          { path: '**', redirectTo: 'metrics' },
        ],
      },
      { path: 'info_messages', component: InfoMessagesComponent },
      { path: 'info_messages/:guid', component: InfoMessagesComponent },
      { path: 'users', component: UsersComponent },
      { path: 'users/:guid', component: UsersComponent },
      { path: 'contests', component: ContestsComponent },
      { path: 'contests/:guid', component: ContestsComponent },
      { path: 'prizes', component: PrizesComponent },
      { path: 'prizes/:guid', component: PrizesComponent },
      { path: 'modes', component: ModesComponent },
      { path: 'modes/:guid', component: ModesComponent },
      { path: 'variants', component: VariantsComponent },
      { path: 'variants/:guid', component: VariantsComponent },
      {
        path: 'ads',
        component: AdsComponent,
        children: [
          { path: 'slideshow_images', component: SlideshowImagesComponent },
          {
            path: 'slideshow_images/:guid',
            component: SlideshowImagesComponent,
          },
          { path: 'ads', component: FullscreenAdsComponent },
          {
            path: 'ads/:guid',
            component: FullscreenAdsComponent,
          },
          { path: 'campains', component: CampainsComponent },
          {
            path: 'campains/:guid',
            component: CampainsComponent,
          },
          { path: '**', redirectTo: 'slideshow_images' },
        ],
      },
      {
        path: 'bets',
        component: AdsComponent,
        children: [
          { path: 'competitors', component: CompetitorsComponent },
          { path: 'competitors/:guid', component: CompetitorsComponent },
          { path: 'leagues', component: LeaguesComponent },
          { path: 'leagues/:guid', component: LeaguesComponent },
          { path: 'events', component: EventsComponent },
          { path: 'events/:guid', component: EventsComponent },
          { path: 'historic', component: HistoricComponent },
          {
            path: 'historic/:contestGuid/:userGuid',
            component: HistoricComponent,
          },
          { path: '**', redirectTo: 'competitors' },
        ],
      },

      { path: 'trophies', component: TrophiesComponent },
      { path: 'trophies/:guid', component: TrophiesComponent },

      { path: 'notifications', component: NotificationsComponent },
      { path: 'bonuses', component: BonusesComponent },
      { path: 'bonuses/:guid', component: BonusesComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'payments',
        component: PaymentsComponent,
        children: [
          { path: 'products', component: ProductsComponent },
          { path: 'products/:guid', component: ProductsComponent },
          { path: 'discounts', component: DiscountsComponent },
          { path: 'discounts/:guid', component: DiscountsComponent },
          { path: 'transactions', component: TransactionsComponent },
          { path: 'transactions/:guid', component: TransactionsComponent },
          { path: '**', redirectTo: 'products' },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
