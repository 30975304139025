import { Injectable } from '@angular/core';
import { SlideshowImage } from 'src/entities/SlideshowImage';
import { APIService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlideshowImageService {
  constructor(private apiService: APIService) {}

  public getSlideshowImages(params: any = null): Observable<SlideshowImage[]> {
    return this.apiService.get(`/admin/all_slideshow_images`, params, true);
  }

  public getSlideshowImage(guid: string): Observable<SlideshowImage> {
    return this.apiService.get(`/admin/slideshow_images/${guid}`);
  }

  public putSlideshowImage(
    guid: string,
    data: any
  ): Observable<SlideshowImage> {
    return this.apiService.put(`/slideshow_images/${guid}`, data);
  }

  public createSlideshowImage(data: any): Observable<SlideshowImage> {
    return this.apiService.post(`/slideshow_images`, data);
  }
}
