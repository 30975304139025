<mat-dialog-content class="mat-typography">
  <h3>Supprimer un message d'information</h3>
  <p>
    Voulez-vous vraiment supprimer ce message d'information ? Cette action est
    irreversible.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Supprimer</button>
</mat-dialog-actions>
