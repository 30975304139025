import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Trophy, TrophyCategory } from 'src/entities/Trophy';
import { TrophyService } from 'src/services/trophy.service';
import { EditTrophyModalComponent } from './edit-trophy-modal/edit-trophy-modal.component';
import { ShowTrophyModalComponent } from './show-trophy-modal/show-trophy-modal.component';
import { LanguageService } from 'src/services/language.service';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-trophies',
  templateUrl: './trophies.component.html',
  styleUrls: ['./trophies.component.css'],
})
export class TrophiesComponent implements OnInit, OnDestroy {
  data: Trophy[] = [];
  loading: boolean = true;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'translations.name',
    'category.slug',
    'isEnabled',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;
  languageSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private trophyService: TrophyService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService
  ) {
    this.navigationService.setCurrentPageName('Trophées');
    // Show trophy if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowTrophyModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  /**
   * Show the show trophy modal
   */
  showTrophyModal(id: number): void {
    const trophy: Trophy | null = this.getTrophyById(id);
    if (trophy) {
      this.dialog.open(ShowTrophyModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: trophy.guid,
        },
      });
    }
  }

  /**
   * Show the edit trophy modal
   */
  editTrophyModal(id: number | null = null): void {
    let trophy: Trophy | null = null;
    if (id) {
      trophy = this.getTrophyById(id);
    } else {
      trophy = new Trophy();
    }

    if (trophy) {
      const dialogRef = this.dialog.open(EditTrophyModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: trophy.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.trophyService
      .getTrophies(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['translations.name'] = this.searchedValue;
    return params;
  }

  getCategoryNameFromSlug(slug: string) {
    return TrophyCategory.getNameFromSlug(slug);
  }

  /**
   * Find a trophy in the data by id
   */
  getTrophyById(id: number): Trophy | null {
    const index: number = this.data.findIndex(
      (trophy: Trophy) => trophy.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(event: Event): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
}
