import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Discount } from 'src/entities/Discount';
import { Payment } from 'src/entities/Payment';
import { Product } from 'src/entities/Product';
import { APIService } from './api.service';

@Injectable()
export class PaymentService {
  constructor(private apiService: APIService) {}

  /* Products */
  public getProducts(params: any = null): Observable<Product[]> {
    return this.apiService.get(`/products`, params, true);
  }
  public getProduct(guid: string): Observable<Product> {
    return this.apiService.get(`/products/${guid}`);
  }
  public putProduct(guid: string, data: Partial<Product>): Observable<Product> {
    return this.apiService.put(`/products/${guid}`, data);
  }
  public createProduct(data: Partial<Product>): Observable<Product> {
    return this.apiService.post(`/products`, data);
  }
  public deleteProduct(guid: string): Observable<void> {
    return this.apiService.delete(`/products/${guid}`);
  }

  /* Discounts */
  public getDiscounts(params: any = null): Observable<Discount[]> {
    return this.apiService.get(`/discounts`, params, true);
  }
  public getDiscount(guid: string): Observable<Discount> {
    return this.apiService.get(`/discounts/${guid}`);
  }
  public putDiscount(
    guid: string,
    data: Partial<Discount>
  ): Observable<Discount> {
    return this.apiService.put(`/discounts/${guid}`, data);
  }
  public createDiscount(data: Partial<Discount>): Observable<Discount> {
    return this.apiService.post(`/discounts`, data);
  }
  public deleteDiscount(guid: string): Observable<void> {
    return this.apiService.delete(`/discounts/${guid}`);
  }
  /* Payments */
  public getPayments(params: any = null): Observable<Payment[]> {
    return this.apiService.get(`/payments`, params, true);
  }
  public getPayment(guid: string): Observable<Payment> {
    return this.apiService.get(`/payments/${guid}`);
  }
}
