import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { NotificationModel } from 'src/entities/Notification';
import { LanguageService } from 'src/services/language.service';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'app-edit-notification-modal',
  templateUrl: './edit-notification-modal.component.html',
  styleUrls: ['./edit-notification-modal.component.css'],
})
export class EditNotificationModalComponent implements OnInit {
  notification: NotificationModel | null = null;
  submitLoading: boolean = false;
  imageUploadLoading: boolean = false;
  errors: string | null = null;
  loading: boolean = true;

  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    content: new FormControl(null),
    isPushNotif: new FormControl(false, [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<EditNotificationModalComponent>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve notification and init form
      this.notificationService
        .getNotificationModel(this.data.guid)
        .subscribe((notification: NotificationModel) => {
          this.notification = notification;
          this.createNotificationFormGroup();
          this.loading = false;
        });
    }
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);

    data = this.createTranslations(data);

    if (this.data.newNotification) {
      data.mode = `/s/modes/${this.data.mode?.guid}`;
      data.slug = this.notification?.slug;
      data.isPushNotif = this.notification?.isPushNotif;
      data.shouldBeDisplayed = this.notification?.shouldBeDisplayed;
      data.doesNeedUserAction = this.notification?.doesNeedUserAction;
      data.pushNotifRedirect = this.notification?.pushNotifRedirect;
      data.isPriorityNotification = this.notification?.isPriorityNotification;
    }

    this.errors = null;

    // PUT Notification
    let request: Observable<NotificationModel> = this.data.newNotification
      ? this.notificationService.createNotificationModel(data)
      : this.notificationService.putNotificationModel(this.data.guid, data);

    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error?.violations?.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Create the notification form group
   */
  createNotificationFormGroup(): void {
    if (this.notification) {
      const notification = this.notification;

      this.formGroup.setValue({
        title: notification.title,
        content: notification.content,
        isPushNotif: notification.isPushNotif,
      });
    }
  }

  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      title: data.title === '' ? null : data.title,
      content: data.content === '' ? null : data.content,
    };

    delete data.title;
    delete data.content;

    // Retrieve all translations and set the new one
    data.translations = this.notification?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }
}
