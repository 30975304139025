<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="bonus; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!bonus">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="bonus">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>

            <app-image-manager
              [image]="bonus.image"
              [width]="100"
              [height]="100"
              [recommendedHeight]="800"
              [recommendedWidth]="800"
              [isEditable]="true"
              [path]="'bonuses/'"
              (imageChanged)="imageChanged($event)"
              (imageUploadStart)="imageUploadLoading = true"
              (imageUploadEnd)="imageUploadLoading = false"
            ></app-image-manager>

            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom"
                formControlName="name"
                required
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['name']?.errors">{{
                formGroup.controls["name"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                placeholder="Description du bonus"
                formControlName="description"
                required
              ></textarea>
              <mat-hint align="start"
                ><strong>Valeurs disponibles : </strong>{{ "{{value}},
                {{ operator }}."}}</mat-hint
              >
              <mat-hint align="start" style="margin-left: 2px"
                ><strong>Subrillance : </strong>{{ "[[texte]]" }}
              </mat-hint>
              <mat-error *ngIf="formGroup.controls['description']?.errors">{{
                formGroup.controls["description"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>slug-du-bonus</mat-label>
              <input
                matInput
                placeholder="slug-du-bonus"
                formControlName="slug"
                type="text"
                required
              />
              <mat-error *ngIf="formGroup.controls['slug']?.errors">{{
                formGroup.controls["slug"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Valeur</mat-label>
              <input
                matInput
                placeholder="Valeur"
                formControlName="value"
                required
                type="number"
              />
              <mat-error *ngIf="formGroup.controls['value']?.errors">{{
                formGroup.controls["value"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let type of types" [value]="type.value">
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              *ngIf="formGroup.value.type === 0"
            >
              <mat-label>Opérateur</mat-label>
              <mat-select formControlName="operator">
                <mat-option
                  *ngFor="let operator of operators"
                  [value]="operator.value"
                >
                  {{ operator.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Position</mat-label>
              <input
                matInput
                placeholder="Position"
                formControlName="position"
                required
                type="number"
              />
              <mat-error *ngIf="formGroup.controls['position']?.errors">{{
                formGroup.controls["position"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitLoading || !bonus || !formGroup.valid || !bonus.image"
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
