import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Bonus } from 'src/entities/Bonus';
import { Sport } from 'src/entities/Sport';
import { Trophy, TrophyCategory } from 'src/entities/Trophy';
import { LanguageService } from 'src/services/language.service';
import { BonusService } from 'src/services/bonus.service';
import { SportService } from 'src/services/sport.service';
import { TrophyService } from 'src/services/trophy.service';

@Component({
  selector: 'app-edit-trophy-modal',
  templateUrl: './edit-trophy-modal.component.html',
  styleUrls: ['./edit-trophy-modal.component.css'],
})
export class EditTrophyModalComponent implements OnInit, OnDestroy {
  trophy: Trophy | null = null;
  submitLoading: boolean = false;
  imageUploadLoading: boolean = false;
  errors: string | null = null;
  loading: boolean = true;
  categories: TrophyCategory[] = [];
  categoryOptions: { name: string; value: string }[] = [];
  stadiumCategoryGuid: string = '';

  sportSearchSubscription: any | null = null;
  sportSearchTerm: string = '';
  searchedSports: Sport[] = [];
  displayedSportsColumns: string[] = ['id', 'name', 'actions'];

  bonusSearchSubscription: any | null = null;
  bonusSearchTerm: string = '';
  searchedBonuses: Bonus[] = [];
  displayedBonusesColumns: string[] = ['id', 'name', 'actions'];

  progressionType: 'trophyProgressionBetWon' | 'trophyProgressionOdd' =
    'trophyProgressionBetWon';

  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    category: new FormControl(null, [Validators.required]),
    isEnabled: new FormControl(false, [Validators.required]),

    // TODO : conditional
    trophyProgressionBetWon_value: new FormControl(10),
    trophyProgressionOdd_minOdd: new FormControl(1),
    trophyProgressionOdd_maxOdd: new FormControl(10),
    trophyProgressionOdd_value: new FormControl(1),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private trophyService: TrophyService,
    private dialogRef: MatDialogRef<EditTrophyModalComponent>,
    private languageService: LanguageService,
    private sportService: SportService,
    private bonusService: BonusService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve trophy and init form
      this.trophyService
        .getTrophy(this.data.guid)
        .subscribe((trophy: Trophy) => {
          this.trophy = trophy;
          this.createTrophyFormGroup();
          this.loading = false;
        });
    } else {
      this.trophy = new Trophy();
      this.createTrophyFormGroup();
      this.setCategories();
      this.loading = false;
    }

    this.setCategories();
  }

  imageChanged(event: any) {
    if (this.trophy) {
      if (event === null) {
        this.trophy.image = null;
      } else {
        this.trophy.image = event['@id'] ?? null;
      }
    }
  }

  /**
   * Search sports
   */
  searchSports(): void {
    let params: any = [];
    params['translations.name'] = this.sportSearchTerm;
    params['itemsPerPage'] = 3;

    if (this.sportSearchSubscription)
      this.sportSearchSubscription.unsubscribe();

    // Search leagues and remove the already selected ones
    this.sportSearchSubscription = this.sportService
      .getSports(params)
      .subscribe((data: any) => {
        this.searchedSports = data['hydra:member'].filter((sport: Sport) => {
          return !this.trophy?.sports?.some(
            (_sport: Sport) => _sport.id === sport.id
          );
        });
      });
  }

  /**
   * Select a sport
   */
  addSport(sport: Sport): void {
    this.sportSearchTerm = '';
    if (this?.trophy?.sports) {
      this.sportSearchTerm = '';
      this.searchedSports = [];
      // Push it to the array with a new object to refresh the table
      this.trophy.sports = [...this.trophy.sports, sport];
    }
  }

  /**
   * Remove a sport
   */
  removeSport(sport: Sport): void {
    if (this?.trophy?.sports) {
      this.trophy.sports = this.trophy.sports.filter(
        (_sport: Sport) => sport.id !== _sport.id
      );
    }
  }

  /**
   * Search bonuss
   */
  searchBonuses(): void {
    let params: any = [];
    params['translations.name'] = this.bonusSearchTerm;
    params['itemsPerPage'] = 3;

    if (this.bonusSearchSubscription)
      this.bonusSearchSubscription.unsubscribe();

    // Search leagues and remove the already selected ones
    this.bonusSearchSubscription = this.bonusService
      .getBonuses(params)
      .subscribe((data: any) => {
        this.searchedBonuses = data['hydra:member'].filter((bonus: Bonus) => {
          return !this.trophy?.trophyReward?.rewards.some(
            (_bonus: Bonus) => _bonus.id === bonus.id
          );
        });
      });
  }

  /**
   * Select a bonus
   */
  addBonus(bonus: Bonus): void {
    this.bonusSearchTerm = '';
    if (this?.trophy?.trophyReward) {
      this.bonusSearchTerm = '';
      this.searchedBonuses = [];
      // Push it to the array with a new object to refresh the table
      this.trophy.trophyReward.rewards = [
        ...this.trophy.trophyReward.rewards,
        bonus,
      ];
    }
  }

  /**
   * Remove a bonus
   */
  removeBonus(bonus: Bonus): void {
    if (this?.trophy?.trophyReward) {
      this.trophy.trophyReward.rewards =
        this.trophy.trophyReward.rewards.filter(
          (_bonus: Bonus) => bonus.id !== _bonus.id
        );
    }
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);
    data.image = this.trophy?.image;
    if (data.image.guid) data.image = `/s/media_objects/${data.image.guid}`;

    data.category = `/s/trophy_categories/${data.category}`;

    if (this.trophy) {
      // Transform sports entities to IRIs
      data.sports = [...this.trophy?.sports].map(
        (sport: Sport) => `/s/sports/${sport.guid}`
      );

      // Transform rewards entities to IRIs
      data.trophyReward = {
        id: this.trophy.trophyReward?.guid
          ? `/s/trophy_rewards/${this.trophy.trophyReward.guid}`
          : null,
        rewards: [...(this.trophy.trophyReward?.rewards ?? [])].map(
          (bonus: Bonus) => `/s/bonuses/${bonus.guid}`
        ),
      };

      // Set the correct data for the selected progression
      if (this.progressionType === 'trophyProgressionBetWon') {
        data.trophyProgressionOdd = null;
        data.trophyProgressionBetWon = {
          id: this.trophy.trophyProgressionBetWon
            ? `/s/trophy_progression_bet_wons/${this.trophy?.trophyProgressionBetWon?.guid}`
            : null,
          value: data.trophyProgressionBetWon_value,
        };
      } else if (this.progressionType === 'trophyProgressionOdd') {
        data.trophyProgressionBetWon = null;
        data.trophyProgressionOdd = {
          id: this.trophy.trophyProgressionOdd
            ? `/s/trophy_progression_odds/${this.trophy?.trophyProgressionOdd?.guid}`
            : null,
          minOdd: data.trophyProgressionOdd_minOdd,
          maxOdd: data.trophyProgressionOdd_maxOdd,
          value: data.trophyProgressionOdd_value,
        };
      }
    }

    data = this.createTranslations(data);

    this.errors = null;

    // POST / PUT Trophy
    let request: Observable<Trophy> = this.data.guid
      ? this.trophyService.putTrophy(this.data.guid, data)
      : this.trophyService.createTrophy(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error?.violations?.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Create the trophy form group
   */
  createTrophyFormGroup(): void {
    if (this.trophy) {
      const trophy = this.trophy;
      this.progressionType = trophy.trophyProgressionBetWon
        ? 'trophyProgressionBetWon'
        : 'trophyProgressionOdd';

      this.formGroup.setValue({
        name: trophy.name,
        category: trophy.category?.guid ?? null,
        isEnabled: trophy.isEnabled,
        trophyProgressionBetWon_value:
          trophy.trophyProgressionBetWon?.value ?? 10,
        trophyProgressionOdd_minOdd: trophy.trophyProgressionOdd?.minOdd ?? 1,
        trophyProgressionOdd_maxOdd: trophy.trophyProgressionOdd?.maxOdd ?? 10,
        trophyProgressionOdd_value: trophy.trophyProgressionOdd?.value ?? 1,
      });
    }
  }

  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      name: data.name === '' ? null : data.name,
    };

    delete data.name;

    // Retrieve all translations and set the new one
    data.translations = this.trophy?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }

  setCategories(): void {
    this.trophyService.getCategories().subscribe((data: any) => {
      this.categories = data['hydra:member'];
      this.categoryOptions = this.getCategoryOptions();
      this.stadiumCategoryGuid =
        this.categories[
          this.categories.findIndex(
            (category: TrophyCategory) => category.slug === 'arenas'
          )
        ].guid;
    });
  }

  getCategoryOptions(): { name: string; value: string }[] {
    return this.categories.map((category: TrophyCategory) => {
      return {
        name: TrophyCategory.getNameFromSlug(category.slug ?? ''),
        value: category.guid,
      };
    });
  }

  ngOnDestroy(): void {
    if (this.sportSearchSubscription)
      this.sportSearchSubscription.unsubscribe();
    if (this.bonusSearchSubscription)
      this.bonusSearchSubscription.unsubscribe();
  }
}
