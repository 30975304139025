import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum AppLanguages {
  LANGUAGE_FRENCH = 'fr',
  LANGUAGE_ENGLISH = 'en',
}

@Injectable()
export class LanguageService {
  appLanguage: AppLanguages = AppLanguages.LANGUAGE_FRENCH;
  languageSubject: Subject<AppLanguages> = new Subject();

  constructor() {
    const localStorageLanguage: string | null =
      localStorage.getItem('language');

    if (localStorageLanguage) {
      if (
        Object.values(AppLanguages).includes(
          localStorageLanguage as AppLanguages
        )
      ) {
        this.appLanguage = localStorageLanguage as AppLanguages;
      }
    }
  }

  changeLanguage(newLanguage: AppLanguages): void {
    this.appLanguage = newLanguage;
    this.languageSubject.next(newLanguage);
    localStorage.setItem('language', newLanguage);
  }

  getAvailableLanguages(): AppLanguages[] {
    return [AppLanguages.LANGUAGE_ENGLISH, AppLanguages.LANGUAGE_FRENCH];
  }

  getAppLanguage(): AppLanguages {
    return this.appLanguage;
  }

  /**
   * Get a country flag as emoji from a country code
   */
  getFlagByCountryCode(code: string): string {
    code = code.toUpperCase();
    if (code == 'EN') return '🇬🇧 Anglais';
    if (code == 'ES') return '🇪🇸 Espagnol';
    if (code == 'FR') return '🇫🇷 Français';
    return '🏳';
  }
}
