import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Bonus } from 'src/entities/Bonus';
import { APIService } from './api.service';

@Injectable()
export class BonusService {
  constructor(private apiService: APIService) {}

  public getBonuses(params: any = null): Observable<Bonus[]> {
    return this.apiService.get(`/bonuses`, params, true);
  }
  public getBonus(guid: string): Observable<Bonus> {
    return this.apiService.get(`/bonuses/${guid}`);
  }

  public putBonus(guid: string, body: Partial<Bonus>): Observable<Bonus> {
    return this.apiService.put(`/bonuses/${guid}`, body);
  }
  public createBonus(body: Partial<Bonus>): Observable<Bonus> {
    return this.apiService.post(`/bonuses`, body);
  }
}
