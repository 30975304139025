import { MediaObject } from './MediaObject';
import { Mode } from './Mode';
import { ModeVariant } from './ModeVariant';
import { User } from './User';

export const enum ContestStatus {
  IN_PROGRESS = 0,
  CREATED = 1,
  FINISHED = 2,
}
export interface Contest {
  id: number;
  guid: string;
  name: string;
  creator: User;
  isPremium: boolean;
  isSponsored: boolean;
  mode: Mode;
  variant: ModeVariant;
  image: any | null;
  status: ContestStatus;
  createdAt: Date;
  userCount: number;
  pendingUserCount: number;
  createdBetsCount: number;
  retrievedBonusesCount: number;
  contestUsers: any;
  startAt: Date;
  endAt: Date;
  code: string;
  description: string | null;
  hasAutoAcceptation: boolean;
}

export function getNameFromContestStatus(contestStatus: ContestStatus): string {
  switch (contestStatus) {
    case ContestStatus.CREATED:
      return 'Création';
    case ContestStatus.IN_PROGRESS:
      return 'En cours';
    case ContestStatus.FINISHED:
      return 'Terminé';
  }
}
