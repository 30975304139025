import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Chart, Color, registerables } from 'chart.js';
import { APIService } from 'src/services/api.service';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.css'],
})
export class MetricsComponent implements OnInit {
  graphColor1: Color = 'rgb(255, 99, 132)';
  graphColor2: Color = 'rgb(241, 207, 103)';

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  // Default date range
  startDate: Date = new Date();
  endDate: Date = new Date();

  data: any = null;
  loading: boolean = true;

  // new users chart
  newUsersChart: Chart | null = null;
  @ViewChild('newUsersChart', { static: false }) newUsersChartElement:
    | ElementRef
    | undefined;
  newUsersCount: number = 0;

  // New contests chart
  newContestsChart: Chart | null = null;
  @ViewChild('newContestsChart', { static: false }) newContestsChartElement:
    | ElementRef
    | undefined;
  newFreemiumContestsCount: number = 0;
  newPremiumContestsCount: number = 0;

  // active users chart
  activeUsersChart: Chart | null = null;
  @ViewChild('activeUsersChart', { static: false }) activeUsersChartElement:
    | ElementRef
    | undefined;
  activeUsersCount: number = 0;

  // active contests chart
  activeContestsChart: Chart | null = null;
  @ViewChild('activeContestsChart', { static: false })
  activeContestsChartElement: ElementRef | undefined;
  activeContestsCount: number = 0;

  // new bets chart
  newBetsChart: Chart | null = null;
  @ViewChild('newBetsChart', { static: false }) newBetsChartElement:
    | ElementRef
    | undefined;
  newBetsCount: number = 0;

  // retrieved bonuses chart
  retrievedBonusesChart: Chart | null = null;
  @ViewChild('retrievedBonusesChart', { static: false })
  retrievedBonusesChartElement: ElementRef | undefined;
  retrievedBonusesCount: number = 0;

  constructor(
    private apiService: APIService,
    private navigationService: NavigationService
  ) {
    this.startDate.setMonth(this.startDate.getMonth() - 1);
    this.navigationService.setCurrentPageName('Metrics');
  }

  ngOnInit(): void {
    // Register all types
    Chart.register(...registerables);

    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data and create graphs
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;
    this.destroyGraphs();
    // Retrieve data from API
    this.apiService
      .get('/admin/metrics', {
        from: this.startDate.toISOString(),
        to: this.endDate.toISOString(),
      })
      .subscribe(
        (response) => {
          this.data = response;
          this.loading = false;

          // Create graphs after the canvas spaces are conditionally rendered
          setTimeout(() => {
            this.createNewUsersChart();
            this.createNewContestsChart();
            this.createActiveContestsChart();
            this.createActiveUsersChart();
            this.createNewBetsChart();
            this.createRetrievedBonusesChart();
          }, 100);
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  destroyGraphs(): void {
    if (this.newUsersChart) this.newUsersChart.destroy();

    if (this.newContestsChart) this.newContestsChart.destroy();

    if (this.activeUsersChart) this.activeUsersChart.destroy();

    if (this.activeContestsChart) this.activeContestsChart.destroy();

    if (this.newBetsChart) this.newBetsChart.destroy();

    if (this.retrievedBonusesChart) this.retrievedBonusesChart.destroy();
  }
  /**
   * Create the new users graph
   */
  createNewUsersChart() {
    let newUsersLabels: string[] = [];
    let newUsersData: number[] = [];

    // Set labels and data
    this.data['newUsers'].forEach((newUser: any) => {
      newUsersLabels.push(newUser.to);
      newUsersData.push(newUser.count);
    });

    // Sums up the number of new users
    this.newUsersCount = this.data['newUsers'].reduce(
      (acc: number, newUser: any) => acc + parseInt(newUser.count),
      0
    );

    const data = {
      labels: newUsersLabels,
      datasets: [
        {
          label: 'Nouveaux utilisateurs',
          backgroundColor: this.graphColor1,
          borderColor: this.graphColor1,
          data: newUsersData,
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        plugins: { legend: { display: false } },
        ticks: {
          precision: 0,
          min: 0,
        },
      },
    };

    // Create chart
    if (this.newUsersChartElement) {
      this.newUsersChart = new Chart(
        this.newUsersChartElement.nativeElement,
        // @ts-ignore
        config
      );
    }
  }

  /**
   * Create the new contests graph
   */
  createNewContestsChart() {
    let newContestsLabels: string[] = [];
    let newFreemiumContestsData: number[] = [];
    let newPremiumContestsData: number[] = [];

    // Set labels and data
    this.data['newFreemiumContests'].forEach((newFreemiumContests: any) => {
      newContestsLabels.push(newFreemiumContests.from);
      newFreemiumContestsData.push(newFreemiumContests.count);
    });
    this.data['newPremiumContests'].forEach((newPremiumContests: any) => {
      newPremiumContestsData.push(newPremiumContests.count);
    });

    // Sums up the number of new arenas
    this.newFreemiumContestsCount = this.data['newFreemiumContests'].reduce(
      (acc: number, newFreemiumContests: any) =>
        acc + parseInt(newFreemiumContests.count),
      0
    );
    this.newPremiumContestsCount = this.data['newPremiumContests'].reduce(
      (acc: number, newPremiumContestsData: any) =>
        acc + parseInt(newPremiumContestsData.count),
      0
    );

    const data = {
      labels: newContestsLabels,
      datasets: [
        {
          label: 'Freemium',
          backgroundColor: this.graphColor1,
          borderColor: this.graphColor1,
          data: newFreemiumContestsData,
        },
        {
          label: 'Premium',
          backgroundColor: this.graphColor2,
          borderColor: this.graphColor2,
          data: newPremiumContestsData,
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        ticks: {
          precision: 0,
          min: 0,
        },
      },
    };

    // Create chart
    if (this.newContestsChartElement) {
      this.newContestsChart = new Chart(
        this.newContestsChartElement.nativeElement,
        // @ts-ignore
        config
      );
    }
  }

  /**
   * Create the active users graph
   */
  createActiveUsersChart() {
    let activeUsersLabels: string[] = [];
    let activeUsersData: number[] = [];

    // Set labels and data
    this.data['activeUsers'].forEach((activeUser: any) => {
      activeUsersLabels.push(activeUser.from);
      activeUsersData.push(activeUser.count);
    });

    // Sums up the number of new users
    this.activeUsersCount = this.data['activeUsers'].reduce(
      (acc: number, activeUser: any) => acc + parseInt(activeUser.count),
      0
    );

    const data = {
      labels: activeUsersLabels,
      datasets: [
        {
          backgroundColor: this.graphColor1,
          borderColor: this.graphColor1,
          data: activeUsersData,
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        plugins: { legend: { display: false } },
        ticks: {
          precision: 0,
          min: 0,
        },
      },
    };

    // Create chart
    if (this.activeUsersChartElement) {
      this.activeUsersChart = new Chart(
        this.activeUsersChartElement.nativeElement,
        // @ts-ignore
        config
      );
    }
  }

  /**
   * Create the active contests graph
   */
  createActiveContestsChart() {
    let activeContestsLabels: string[] = [];
    let activeContestsData: number[] = [];

    // Set labels and data
    this.data['activeContests'].forEach((activeUser: any) => {
      activeContestsLabels.push(activeUser.from);
      activeContestsData.push(activeUser.count);
    });

    // Sums up the number of new users
    this.activeContestsCount = this.data['activeContests'].reduce(
      (acc: number, activeUser: any) => acc + parseInt(activeUser.count),
      0
    );

    const data = {
      labels: activeContestsLabels,
      datasets: [
        {
          label: 'Arènes actives',
          backgroundColor: this.graphColor1,
          borderColor: this.graphColor1,
          data: activeContestsData,
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        plugins: { legend: { display: false } },
        ticks: {
          precision: 0,
          min: 0,
        },
      },
    };

    // Create chart
    if (this.activeContestsChartElement) {
      this.activeContestsChart = new Chart(
        this.activeContestsChartElement.nativeElement,
        // @ts-ignore
        config
      );
    }
  }
  /**
   * Create the new bets graph
   */
  createNewBetsChart() {
    let newBetsLabels: string[] = [];
    let newBetsData: number[] = [];

    // Set labels and data
    this.data['newBets'].forEach((activeUser: any) => {
      newBetsLabels.push(activeUser.from);
      newBetsData.push(activeUser.count);
    });

    // Sums up the number of new users
    this.newBetsCount = this.data['newBets'].reduce(
      (acc: number, activeUser: any) => acc + parseInt(activeUser.count),
      0
    );

    const data = {
      labels: newBetsLabels,
      datasets: [
        {
          label: 'Paris créés',
          backgroundColor: this.graphColor1,
          borderColor: this.graphColor1,
          data: newBetsData,
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        plugins: { legend: { display: false } },
        ticks: {
          precision: 0,
          min: 0,
        },
      },
    };

    // Create chart
    if (this.newBetsChartElement) {
      this.newBetsChart = new Chart(
        this.newBetsChartElement.nativeElement,
        // @ts-ignore
        config
      );
    }
  }

  /**
   * Create the active contests graph
   */
  createRetrievedBonusesChart() {
    let retrievedBonusesLabels: string[] = [];
    let retrievedBonusesData: number[] = [];

    // Set labels and data
    this.data['retrievedBonuses'].forEach((retrievedBonus: any) => {
      retrievedBonusesLabels.push(retrievedBonus.from);
      retrievedBonusesData.push(retrievedBonus.count);
    });

    // Sums up the number of new users
    this.retrievedBonusesCount = this.data['retrievedBonuses'].reduce(
      (acc: number, retrievedBonus: any) =>
        acc + parseInt(retrievedBonus.count),
      0
    );

    const data = {
      labels: retrievedBonusesLabels,
      datasets: [
        {
          label: 'Bonus débloqués',
          backgroundColor: this.graphColor1,
          borderColor: this.graphColor1,
          data: retrievedBonusesData,
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        plugins: { legend: { display: false } },
        ticks: {
          precision: 0, // Avoid decimal numbers on Y axis,
          min: 0,
        },
      },
    };

    // Create chart
    if (this.retrievedBonusesChartElement) {
      this.retrievedBonusesChart = new Chart(
        this.retrievedBonusesChartElement.nativeElement,
        // @ts-ignore
        config
      );
    }
  }

  async onSubmit(): Promise<void> {
    this.setData();
  }
}
