<div *ngIf="!loading; else loader">
  <p>
    <strong
      >{{ placeholder }}
      {{
        recommendedHeight && recommendedWidth
          ? "(" + recommendedWidth + " * " + recommendedHeight + ")"
          : ""
      }}
      :
    </strong>
  </p>
  <div *ngIf="imageSource" class="image-preview">
    <img [src]="imageSource" [width]="width" [height]="height" />
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="clearImage()"
      *ngIf="isEditable"
      style="margin: 20px 0px"
    >
      Supprimer l'image
    </button>
  </div>
  <div *ngIf="!base64 && !imageSource" class="image-preview">
    <input
      type="file"
      title="Ajouter un fichier"
      #fileInput
      hidden
      (change)="uploadFile($event)"
      accept="image/*"
      [disabled]="!isEditable"
    />

    <div
      class="image-placeholder-container"
      title="Ajouter une image"
      [style.cursor]="isEditable ? 'pointer' : 'default'"
      (click)="fileInput.click()"
    >
      <div
        class="image-placeholder"
        [style.height.px]="height"
        [style.width.px]="width"
      >
        <p>
          {{
            recommendedHeight && recommendedWidth
              ? recommendedWidth + " x " + recommendedWidth
              : ""
          }}
        </p>
      </div>
    </div>

    <div *ngIf="isEditable" style="margin-bottom: 20px">
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="fileInput.click()"
      >
        Ajouter une image
      </button>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
