import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationService } from 'src/services/navigation.service';

type SideNavItemType = {
  title: string;
  link: string;
  children?: SideNavItemType[];
  isActive?: boolean;
  isOpened?: boolean;
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit, OnDestroy {
  sideNavShown: boolean = true;
  sideNavItems: SideNavItemType[] = [];
  currentRoute: string = '';
  currentPageName: string = '';
  currentPageNameSubscription: any = null;
  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {
    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        this.currentRoute = val.url.split('/')[1];
      }
    });

    this.currentPageNameSubscription = this.navigationService
      .getCurrentPageName()
      .subscribe((val: string) => (this.currentPageName = val));
  }

  ngOnInit(): void {
    this.sideNavItems = this.getSideNavItems();
  }

  toggleSideNav(): void {
    this.sideNavShown = !this.sideNavShown;
  }

  getSideNavItems() {
    const sidenavItems: SideNavItemType[] = [
      {
        title: 'Dashboard',
        link: '/dashboard',
        children: [
          {
            title: 'Metrics',
            link: '/dashboard/metrics',
          },
          // {
          //   title: 'KPI',
          //   link: '/dashboard/kpi',
          // },
        ],
      },
      {
        title: "Messages d'information",
        link: '/info_messages',
      },
      {
        title: 'Ads',
        link: '/ads',
        children: [
          {
            title: 'Forum des cotes',
            link: '/ads/slideshow_images',
          },
          {
            title: 'Publicités',
            link: '/ads/ads',
          },
          {
            title: 'Campagnes',
            link: '/ads/campains',
          },
        ],
      },
      {
        title: 'Arènes',
        link: '/contests',
      },
      {
        title: 'Bonus',
        link: '/bonuses',
      },
      {
        title: 'Modes',
        link: '/modes',
      },
      {
        title: 'Règles',
        link: '/variants',
      },
      {
        title: 'Notifications',
        link: '/notifications',
      },
      {
        title: 'Paris',
        link: '/bets',
        children: [
          {
            title: 'Compétiteurs',
            link: '/bets/competitors',
          },
          {
            title: 'Ligues',
            link: '/bets/leagues',
          },
          {
            title: 'Matchs',
            link: '/bets/events',
          },
          {
            title: 'Historique des paris',
            link: '/bets/historic',
          },
        ],
      },
      {
        title: 'Lots',
        link: '/prizes',
      },
      {
        title: 'Trophées',
        link: '/trophies',
      },
      {
        title: 'Utilisateurs',
        link: '/users',
      },
      {
        title: 'Paiements',
        link: '/payments',
        children: [
          {
            title: 'Produits',
            link: '/payments/products',
          },
          {
            title: 'Promotions',
            link: '/payments/discounts',
          },
          {
            title: 'Achats effectués',
            link: '/payments/transactions',
          },
        ],
      },
    ];

    return sidenavItems;
  }

  isPanelExpanded(link: string): boolean {
    return link.split('/')[1] === this.currentRoute;
  }

  ngOnDestroy(): void {
    if (this.currentPageNameSubscription)
      this.currentPageNameSubscription.unsubscribe();
  }
}
