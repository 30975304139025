import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-campain-modal',
  templateUrl: './delete-campain-modal.component.html',
  styleUrls: ['./delete-campain-modal.component.css'],
})
export class DeleteCampainModalComponent {
  constructor() {}
}
