import { Component, EventEmitter, Output } from '@angular/core';
import { AppLanguages, LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
})
export class LanguageSelectorComponent {
  @Output() languageChanged = new EventEmitter<AppLanguages>();
  selectedLanguage = this.languageService.getAppLanguage();

  constructor(private languageService: LanguageService) {}

  getAppLanguage(): AppLanguages {
    return this.languageService.getAppLanguage();
  }
  getAvailableLanguages(): AppLanguages[] {
    return this.languageService.getAvailableLanguages();
  }
  changeLanguage(event: any): void {
    this.selectedLanguage = event;
    this.languageService.changeLanguage(this.selectedLanguage);
    this.languageChanged.emit(this.selectedLanguage);
  }
  getFlagFromLanguage(code: string): string {
    return this.languageService.getFlagByCountryCode(code);
  }
}
