import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Contest } from 'src/entities/Contest';
import { Prize } from 'src/entities/Prize';
import { ContestService } from 'src/services/contest.service';
import { PrizeService } from 'src/services/prize.service';
import { DeletePrizeModalComponent } from './delete-prize-modal/delete-prize-modal.component';
import { EditPrizeModalComponent } from './edit-prize-modal/edit-prize-modal.component';
import { ShowPrizeModalComponent } from './show-prize-modal/show-prize-modal.component';
import { LanguageService } from 'src/services/language.service';
import { NavigationService } from 'src/services/navigation.service';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.css'],
})
export class PrizesComponent implements OnDestroy, OnInit {
  data: Prize[] | null = null;

  loading: boolean = false;

  selectedContest: Contest | null = null;
  contestsSearchedValue: string | null = null;
  contestsSearchSubscription: any | null = null;
  contestsSearchLoading: boolean = false;
  searchedContests: Contest[] = [];

  // Columns to display
  displayedColumns: string[] = ['title', 'content', 'position', 'actions'];

  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;
  languageSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private prizeService: PrizeService,
    private contestService: ContestService,
    private location: Location,
    public dialog: MatDialog,
    private languageService: LanguageService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Prix');

    if (this.activatedRoute.snapshot.params['guid']) {
      this.loading = true;
      let requests: any = [];
      requests.push(
        contestService.getContest(this.activatedRoute.snapshot.params['guid'])
      );

      forkJoin(requests).subscribe(([contest, user]: any) => {
        this.selectedContest = contest;
        this.setData();
      });
    }
  }

  ngOnInit(): void {
    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  resetData(): void {
    this.data = null;
    this.selectedContest = null;
    this.location.replaceState('/prizes');
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    if (this.selectedContest?.guid) {
      this.location.replaceState(`/prizes/${this.selectedContest.guid}`);

      this.loading = showLoader;

      // Cancel the previous search
      if (this.loadSubscription) this.loadSubscription.unsubscribe();

      // Retrieve data from API
      this.loadSubscription = this.prizeService
        .getPrizesByContest(this.selectedContest.guid, this.getParams())
        .subscribe(
          (response: any) => {
            // Set all data
            this.data = response['hydra:member'];
            // Set the total count for the paginator
            this.totalItems = response['hydra:totalItems'];
          },
          () => null,
          () => {
            this.loading = false;
          }
        );
    }
  }

  searchContests(): void {
    let params: any = {};
    params['name'] = this.contestsSearchedValue;
    params[`order[name]`] = 'ASC';
    params['itemsPerPage'] = 3;

    this.contestsSearchLoading = true;

    if (this.contestsSearchSubscription)
      this.contestsSearchSubscription.unsubscribe();

    this.contestsSearchSubscription = this.contestService
      .getContests(params)
      .subscribe(
        (response: any) => (this.searchedContests = response['hydra:member']),
        () => null,
        () => (this.contestsSearchLoading = false)
      );
  }

  /**
   * Show the show prize modal
   */
  showPrizeModal(id: number): void {
    const prize: Prize | null = this.getPrizeById(id);
    if (prize) {
      this.dialog.open(ShowPrizeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: prize.guid,
        },
      });
    }
  }

  /**
   * Show the edit prize modal
   */
  editPrizeModal(id: number | null = null): void {
    let prize: Prize | null = null;
    if (id) {
      prize = this.getPrizeById(id);
    } else {
      prize = new Prize();
    }

    if (prize && this.selectedContest) {
      const dialogRef = this.dialog.open(EditPrizeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: prize.guid,
          contestGuid: this.selectedContest.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    return params;
  }

  /**
   * Find a prize in the data by id
   */
  getPrizeById(id: number): Prize | null {
    if (this.data) {
      const index: number = this.data.findIndex(
        (prize: Prize) => prize.id === id
      );
      if (index !== -1) {
        return this.data[index];
      }
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  /**
   * Show the delete prize modal
   */
  deletePrizeModal(id: number): void {
    const prize: Prize | null = this.getPrizeById(id);
    if (prize?.guid) {
      const dialogRef = this.dialog.open(DeletePrizeModalComponent);
      dialogRef.afterClosed().subscribe((result) => {
        // Delete the prize
        if (result && prize.guid) this.deletePrize(prize.guid);
      });
    }
  }

  deletePrize(guid: string) {
    this.prizeService.deletePrize(guid).subscribe(() => this.setData(false));
  }

  ngOnDestroy(): void {
    if (this.contestsSearchSubscription)
      this.contestsSearchSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
}
