import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Competitor } from 'src/entities/Competitor';
import { APIService } from './api.service';

@Injectable()
export class CompetitorService {
  constructor(private apiService: APIService) {}

  public getCompetitors(params: any = null): Observable<Competitor[]> {
    return this.apiService.get(`/competitors`, params, true);
  }
  public getCompetitor(guid: string): Observable<Competitor> {
    return this.apiService.get(`/competitors/${guid}`);
  }
}
