<!-- Loader -->
<mat-spinner *ngIf="!data"></mat-spinner>

<section class="main-section">
  <ng-container *ngIf="data">
    <div *ngIf="!loading; else loader" style="width: 100%">
      <div style="margin: 10px; float: right">
        <button
          matSuffix
          mat-raised-button
          color="primary"
          (click)="editSlideshowImageModal()"
        >
          Nouvelle publicité
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Slug -->
        <ng-container matColumnDef="slug">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Slug</th>
          <td mat-cell *matCellDef="let element">{{ element.slug }}</td>
        </ng-container>

        <!-- startAt -->
        <ng-container matColumnDef="startAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>startAt</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startAt | date }}
          </td>
        </ng-container>

        <!-- endAt -->
        <ng-container matColumnDef="endAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>endAt</th>
          <td mat-cell *matCellDef="let element">{{ element.endAt | date }}</td>
        </ng-container>

        <!-- isEnabled -->
        <ng-container matColumnDef="isEnabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activé</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip [bool]="element.isEnabled"></app-status-chip>
          </td>
        </ng-container>

        <!-- isAlsoForPremium -->
        <ng-container matColumnDef="isAlsoForPremium">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Affiché en mode premium
          </th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip
              [bool]="element.isAlsoForPremium"
            ></app-status-chip>
          </td>
        </ng-container>

        <!-- actionType -->
        <ng-container matColumnDef="actionType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Redirection</th>
          <td mat-cell *matCellDef="let element">
            {{ getActionTypeStringFromType(element.actionType) }}
          </td>
        </ng-container>

        <!-- target -->
        <ng-container matColumnDef="target">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cible</th>
          <td mat-cell *matCellDef="let element">
            {{ getTargetString(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showSlideshowImageModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="editSlideshowImageModal(element.id)"
                class="cursor_pointer"
                matTooltip="Editer"
              >
                <mat-icon>edit</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        #paginator
        showFirstLastButtons
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </ng-container>
</section>
<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
