<section class="main-section">
  <div *ngIf="!loading; else loader" style="width: 100%">
    <div class="text-center">
      <!-- <mat-form-field appearance="fill">
        <mat-label>Rechercher un message d'information</mat-label>
        <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
        <input
          matInput
          type="search"
          [(ngModel)]="searchedValue"
          (input)="setSearchedValue($event)"
        />
        <button
          *ngIf="searchedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchedValue = ''; this.setData(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field> -->
    </div>
    <div style="margin: 20px 10px; float: right">
      <button
        matSuffix
        mat-raised-button
        color="primary"
        (click)="editInfoMessageModal()"
      >
        Nouveau message d'information
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Start at -->
        <ng-container matColumnDef="startAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date de début
          </th>
          <td mat-cell *matCellDef="let element">
            {{ (element.startAt | date) ?? "-" }}
          </td>
        </ng-container>
        <!-- End at -->
        <ng-container matColumnDef="endAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de fin</th>
          <td mat-cell *matCellDef="let element">
            {{ (element.endAt | date) ?? "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contenu</th>
          <td mat-cell *matCellDef="let element" style="width: 50%">
            {{ element.content }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showInfoMessageModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="editInfoMessageModal(element.id)"
                class="cursor_pointer"
                matTooltip="Editer"
              >
                <mat-icon>edit</mat-icon>
              </div>
              <div
                (click)="deleteInfoMessageModal(element.id)"
                class="cursor_pointer"
                matTooltip="Supprimer"
                style="color: red"
              >
                <mat-icon>delete</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        #paginator
        showFirstLastButtons
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
