export enum BonusType {
  BOOSTER = 0,
  INSURANCE = 1,
  ZEUROS = 2,
}

export enum BonusOperatorType {
  PERCENTAGE = 0,
  POWER = 1,
}
export class Bonus {
  id: number | null = null;
  guid: string | null = null;
  name: string | null = null;
  description: string | null = null;
  type: BonusType = BonusType.BOOSTER;
  operator: BonusOperatorType = BonusOperatorType.PERCENTAGE;
  slug: string | null = null;
  value: number | null = null;
  translations: any | null = null;
  image: any | null = null;
  position: number = 1;
}

export function getNameFromBonusType(bonusType: BonusType): string {
  switch (bonusType) {
    case BonusType.BOOSTER:
      return 'Booster';
    case BonusType.INSURANCE:
      return 'Assurance';
    case BonusType.ZEUROS:
      return 'Zeuros';
  }
}

export function getOperatorFromOperatorType(
  operatorType: BonusOperatorType
): string {
  switch (operatorType) {
    case BonusOperatorType.PERCENTAGE:
      return '%';
    case BonusOperatorType.POWER:
      return 'ⁿ';
  }
}
