<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="bonus; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/bonuses/' + bonus.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-h1">{{ bonus?.name ?? "" }}</h1>
    <div *ngIf="!bonus">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="bonus">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <app-image-manager
            *ngIf="bonus.image"
            [image]="bonus.image"
            [width]="100"
            [height]="100"
            [recommendedHeight]="800"
            [recommendedWidth]="800"
          ></app-image-manager>
          <p><strong>Nom : </strong>{{ bonus.name }}</p>
          <p><strong>Description : </strong>{{ bonus.description }}</p>
          <p><strong>Type : </strong>{{ getTypeName(bonus.type) }}</p>
          <p>
            <strong>Opérateur : </strong>{{ getOperatorName(bonus.operator) }}
          </p>
          <p></p>
        </div>
      </div>
      <!-- <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">Modes : TODO</div>
      </div> -->
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
