<section class="main-section">
  <div *ngIf="!loading; else loader" style="width: 100%">
    <div class="text-center">
      <mat-form-field appearance="fill">
        <mat-label>Rechercher un paiement</mat-label>
        <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
        <input
          matInput
          type="search"
          [(ngModel)]="searchedValue"
          (input)="setSearchedValue($event)"
        />
        <button
          *ngIf="searchedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchedValue = ''; this.setData(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngIf="data.length > 0">
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Utilisateur</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              *ngIf="element.contestUser?.user; else empty"
              [routerLink]="['/users', element.contestUser?.user?.guid ?? '']"
              >{{ element.contestUser?.user?.username }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="contest">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Arène</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              *ngIf="element.contestUser?.contest; else empty"
              [routerLink]="[
                '/contests',
                element.contestUser?.contest?.guid ?? ''
              ]"
              >{{ element.contestUser?.contest?.name }}</a
            >
          </td>
          <td mat-cell *matCellDef="let element">
            {{ element.contestUser.contest?.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">
            {{ getNameByType(element.type) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Promotion</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              *ngIf="element.discount; else empty"
              [routerLink]="[
                '/payments/discounts',
                element.discount?.guid ?? ''
              ]"
              >{{ element.discount.code }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant</th>
          <td mat-cell *matCellDef="let element">
            {{ element.amount | currency: "EUR" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Créé le</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date: "medium" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showTransactionModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        #paginator
        showFirstLastButtons
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
    <div *ngIf="data.length === 0">
      <p style="text-align: center">Aucun paiement effectué</p>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

<ng-template #empty>
  <p>-</p>
</ng-template>
