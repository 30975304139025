<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="ad; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/ads/ads/' + ad.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ ad?.name ?? "" }}
    </h1>
    <div *ngIf="!ad">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="ad">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <app-image-manager
            *ngIf="ad.phoneImage"
            [image]="ad.phoneImage"
            [width]="1242 / 4"
            [height]="2208 / 4"
            [recommendedHeight]="2208"
            [recommendedWidth]="1242"
            [placeholder]="'Téléphone'"
          ></app-image-manager>
        </div>
        <div style="flex: 1">
          <app-image-manager
            *ngIf="ad.tabletImage"
            [image]="ad.tabletImage"
            [height]="1280 / 4"
            [width]="800 / 4"
            [placeholder]="'Tablette'"
            [recommendedHeight]="1280"
            [recommendedWidth]="800"
          ></app-image-manager>
        </div>
      </div>
      <div>
        <div>
          <p><strong>Nom : </strong>{{ ad.name }}</p>
          <p><strong>Temps avant le skip : </strong>{{ ad.skipTime }}</p>
          <p><strong>Age minimum : </strong>{{ ad.minimalAge | nullDash }}</p>
          <p>
            <strong>Lien : </strong
            ><a *ngIf="ad.link" [href]="ad.link" target="_blank">{{
              ad.link
            }}</a
            ><span *ngIf="!ad.link">-</span>
          </p>
          <p>
            <strong>Affiché sur les bonus : </strong
            >{{ ad.shownForBonuses | yesNo }}
          </p>
          <p>
            <strong>Affiché sur le ticket : </strong
            >{{ ad.shownForTicket | yesNo }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
