import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Trophy, TrophyCategory } from 'src/entities/Trophy';
import { TrophyService } from 'src/services/trophy.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-trophy-modal',
  templateUrl: './show-trophy-modal.component.html',
  styleUrls: ['./show-trophy-modal.component.css'],
})
export class ShowTrophyModalComponent implements OnInit {
  trophy: Trophy | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private trophyService: TrophyService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.trophyService
        .getTrophy(this.data.guid)
        .subscribe((trophy: Trophy) => {
          this.trophy = trophy;
        });
      // Change url
      this.location.replaceState(`/trophies/${this.data?.guid}`);
    }
  }

  getCategoryNameFromSlug(slug: string): string {
    return TrophyCategory.getNameFromSlug(slug);
  }
}
