import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/services/navigation.service';
import { AdCampainService } from 'src/services/adCampain.service';
import { EditCampainModalComponent } from './edit-campain-modal/edit-campain-modal.component';
import { ShowCampainModalComponent } from './show-campain-modal/show-campain-modal.component';
import { AdCampain } from 'src/entities/AdCampain';
import { DeleteCampainModalComponent } from './delete-campain-modal/delete-campain-modal.component';

@Component({
  selector: 'app-contests',
  templateUrl: './campains.component.html',
  styleUrls: ['./campains.component.css'],
})
export class CampainsComponent implements OnInit {
  data: AdCampain[] = [];
  loading: boolean = false;
  // Columns to display
  displayedColumns: string[] = [
    'name',
    'startAt',
    'endAt',
    'isEnabled',
    'currentViews',
    'views',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';
  constructor(
    private adCampainService: AdCampainService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Publicités');
    // Show ad if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowCampainModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.adCampainService.getAdCampains(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;

    return params;
  }

  /**
   * Find a slideshow image in the data by id
   */
  getCampainById(id: number): AdCampain | null {
    const index: number = this.data.findIndex(
      (adCampain: AdCampain) => adCampain.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }

    // Set all data
    this.setData(false);
  }

  /**
   * Show the show ad modal
   */
  showCampainModal(id: number): void {
    const adCampain: AdCampain | null = this.getCampainById(id);

    if (adCampain) {
      this.dialog.open(ShowCampainModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: adCampain.guid,
        },
      });
    }
  }

  /**
   * Show the edit ad modal
   */
  editCampainModal(id: number | null = null): void {
    let adCampain: AdCampain | null = null;

    if (id) {
      adCampain = this.getCampainById(id);
      if (!adCampain) return;
    } else {
      adCampain = new AdCampain();
    }

    if (adCampain) {
      const dialogRef = this.dialog.open(EditCampainModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: adCampain.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Show the delete campain modal
   */
  deleteCampainModal(id: number): void {
    const campain: AdCampain | null = this.getCampainById(id);
    if (campain?.guid) {
      const dialogRef = this.dialog.open(DeleteCampainModalComponent);
      dialogRef.afterClosed().subscribe((result) => {
        // Delete the campain
        if (result && campain.guid) this.deleteCampain(campain.guid);
      });
    }
  }

  deleteCampain(guid: string) {
    this.adCampainService
      .deleteAdCampain(guid)
      .subscribe(() => this.setData(false));
  }
}
