import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModeVariant } from 'src/entities/ModeVariant';
import { APIService } from './api.service';

@Injectable()
export class VariantService {
  constructor(private apiService: APIService) {}

  public getVariants(params: any = null): Observable<ModeVariant[]> {
    return this.apiService.get(`/mode_variants`, params, true);
  }
  public getVariantsByMode(modeGuid: string): Observable<ModeVariant[]> {
    return this.apiService.get(`/variants_by_mode/${modeGuid}`);
  }
  public getVariant(guid: string): Observable<ModeVariant> {
    return this.apiService.get(`/mode_variants/${guid}`);
  }
  public putVariant(
    guid: string,
    body: Partial<ModeVariant>
  ): Observable<ModeVariant> {
    return this.apiService.put(`/mode_variants/${guid}`, body);
  }
  public createVariant(body: Partial<ModeVariant>): Observable<ModeVariant> {
    return this.apiService.post(`/mode_variants`, body);
  }
}
