<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="mode && bonusesGroups.length; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.title ?? "-" }}
      </h1>
      <div *ngIf="!mode">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="mode">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>

            <mat-form-field appearance="fill">
              <mat-label>Titre</mat-label>
              <input
                matInput
                placeholder="Titre du mode"
                formControlName="title"
                required
              />
              <mat-error *ngIf="formGroup.controls['title']?.errors">{{
                formGroup.controls["title"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                placeholder="Description du mode"
                formControlName="description"
                required
              ></textarea>
              <mat-error *ngIf="formGroup.controls['description']?.errors">{{
                formGroup.controls["description"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>slug-du-mode</mat-label>
              <input
                matInput
                placeholder="slug-du-mode"
                formControlName="slug"
                required
              />
              <mat-error *ngIf="formGroup.controls['slug']?.errors">{{
                formGroup.controls["slug"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Date de début</mat-label>
              <input
                matInput
                [matDatepicker]="startAtPicker"
                formControlName="startAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['startAt']?.errors">{{
                formGroup.controls["startAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Date de fin</mat-label>
              <input
                matInput
                [matDatepicker]="endAtPicker"
                formControlName="endAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['endAt']?.errors">{{
                formGroup.controls["endAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-slide-toggle formControlName="enabled"
              >Activé</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="isForFreemium"
              >Activé en mode freemium</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="isForPremium"
              >Activé en mode premium</mat-slide-toggle
            >
            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Configuration</h2>
            <mat-form-field appearance="fill">
              <mat-label>Mise par défault maximale</mat-label>
              <input
                matInput
                placeholder="Mise par défault maximale"
                formControlName="maxInitialBet"
                type="number"
                required
              />
              <mat-error *ngIf="formGroup.controls['maxInitialBet']?.errors">{{
                formGroup.controls["maxInitialBet"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Type de ticket lorsqu'au moins 2 paris : </mat-label>
              <mat-select formControlName="defaultTicketType" required>
                <mat-option [value]="0">Simple</mat-option>
                <mat-option [value]="1">Combiné</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-slide-toggle formControlName="isConfigurable"
              >Configurable</mat-slide-toggle
            >
            <mat-form-field appearance="fill">
              <mat-label>Bourse par défaut</mat-label>
              <input
                matInput
                placeholder="Bourse par défaut"
                formControlName="defaultWallet"
                type="number"
                required
              />
              <mat-error *ngIf="formGroup.controls['defaultWallet']?.errors">{{
                formGroup.controls["defaultWallet"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-slide-toggle formControlName="isDefaultWalletEditable"
              >Bourse par défaut configurable</mat-slide-toggle
            >
            <br />
            <mat-form-field appearance="fill">
              <mat-label>Revenus quotidiens</mat-label>
              <input
                matInput
                placeholder="Revenus quotidiens"
                formControlName="dailyIncome"
                type="number"
                required
              />
              <mat-error *ngIf="formGroup.controls['dailyIncome']?.errors">{{
                formGroup.controls["dailyIncome"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-slide-toggle formControlName="isDailyIncomeEditable"
              >Revenus quotidiens configurables</mat-slide-toggle
            >
            <br />
            <mat-form-field appearance="fill">
              <mat-label>Bonus quotidiens</mat-label>
              <input
                matInput
                placeholder="Revenus quotidiens"
                formControlName="dailyBonuses"
                type="number"
                required
              />
              <mat-error *ngIf="formGroup.controls['dailyBonuses']?.errors">{{
                formGroup.controls["dailyBonuses"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-slide-toggle formControlName="areDailyBonusesEditable"
              >Bonus quotidiens configurables</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="isAssiduous"
              >Assidu</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="doesDefaultBonusesNeedRetrieval"
              >Les bonus par défaut ont besoin d'être récupérés
              manuellement</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="autoRetrieveExcessBonuses"
              >Les bonus non récupérés le sont à la prochaine
              distribution</mat-slide-toggle
            >
            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Couleurs</h2>

            <mat-form-field
              appearance="fill"
              [style.color]="formGroup.value.primaryColor"
              style="width: 100px"
            >
              <mat-label>Couleur 1</mat-label>
              <input
                matInput
                placeholder="#123456"
                formControlName="primaryColor"
                [style.font-weight]="'bold'"
                [class.empty-color]="formGroup.value['primaryColor'] === null"
                type="color"
              />
              <mat-error *ngIf="formGroup.controls['primaryColor']?.errors">{{
                formGroup.controls["primaryColor"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              style="width: 100px"
              [style.color]="formGroup.value.secondaryColor"
            >
              <mat-label>Couleur 2</mat-label>
              <input
                matInput
                placeholder="#123456"
                formControlName="secondaryColor"
                [style.font-weight]="'bold'"
                type="color"
                [class.empty-color]="formGroup.value['secondaryColor'] === null"
              />
              <mat-error *ngIf="formGroup.controls['secondaryColor']?.errors">{{
                formGroup.controls["secondaryColor"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              [style.color]="formGroup.value.tertiaryColor"
              style="width: 100px"
            >
              <mat-label>Couleur 3</mat-label>
              <input
                matInput
                placeholder="#123456"
                [style.font-weight]="'bold'"
                formControlName="tertiaryColor"
                type="color"
                [class.empty-color]="formGroup.value['tertiaryColor'] === null"
              />
              <mat-error *ngIf="formGroup.controls['tertiaryColor']?.errors">{{
                formGroup.controls["tertiaryColor"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              [style.color]="formGroup.value.textColor"
              style="width: 100px"
            >
              <mat-label>Couleur du texte</mat-label>
              <input
                matInput
                placeholder="#123456"
                [style.font-weight]="'bold'"
                formControlName="textColor"
                type="color"
                [class.empty-color]="formGroup.value['textColor'] === null"
              />
              <mat-error *ngIf="formGroup.controls['textColor']?.errors">{{
                formGroup.controls["textColor"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Traductions</h2>
            <mat-form-field appearance="fill">
              <mat-label>OddZeus</mat-label>
              <input matInput placeholder="OddZeus" formControlName="oddzeus" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Zeuros (court)</mat-label>
              <input
                matInput
                placeholder="Zeuros"
                formControlName="moneyShort"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['moneyShort']?.errors">{{
                formGroup.controls["moneyShort"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Zeuros (court - suffixe)</mat-label>
              <input
                matInput
                placeholder="Zeuros"
                formControlName="moneyShortSuffix"
                type="text"
              />
              <mat-error
                *ngIf="formGroup.controls['moneyShortSuffix']?.errors"
                >{{
                  formGroup.controls["moneyShortSuffix"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Zeuros (long)</mat-label>
              <input
                matInput
                placeholder="Zeuros"
                formControlName="moneyLong"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['moneyLong']?.errors">{{
                formGroup.controls["moneyLong"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Widgets</h2>
            <mat-form-field appearance="fill">
              <mat-label>Bonus</mat-label>
              <input
                matInput
                placeholder="Mes bonus"
                formControlName="bonusesWidgetTitle"
                type="text"
              />
              <mat-error
                *ngIf="formGroup.controls['bonusesWidgetTitle']?.errors"
                >{{
                  formGroup.controls["bonusesWidgetTitle"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Classement</mat-label>
              <input
                matInput
                placeholder="Mon classement"
                formControlName="rankingsWidgetTitle"
                type="text"
              />
            </mat-form-field>
            <mat-error
              *ngIf="formGroup.controls['rankingsWidgetTitle']?.errors"
              >{{
                formGroup.controls["rankingsWidgetTitle"]?.errors?.error
              }}</mat-error
            >
            <mat-form-field appearance="fill">
              <mat-label>Paris</mat-label>
              <input
                matInput
                placeholder="Mes paris"
                formControlName="betsWidgetTitle"
                type="text"
              />
            </mat-form-field>
            <mat-error *ngIf="formGroup.controls['betsWidgetTitle']?.errors">{{
              formGroup.controls["betsWidgetTitle"]?.errors?.error
            }}</mat-error>
            <mat-form-field appearance="fill">
              <mat-label>Pacte</mat-label>
              <input
                matInput
                placeholder="Pacte"
                formControlName="contractWidgetTitle"
                type="text"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Trophées</mat-label>
              <input
                matInput
                placeholder="Mes trophées"
                formControlName="trophiesWidgetTitle"
                type="text"
              />
              <mat-error
                *ngIf="formGroup.controls['trophiesWidgetTitle']?.errors"
                >{{
                  formGroup.controls["trophiesWidgetTitle"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Onglets</h2>
            <mat-form-field appearance="fill">
              <mat-label>Résumé</mat-label>
              <input
                matInput
                placeholder="Mes prouesses"
                formControlName="dashboardTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['dashboardTitle']?.errors">{{
                formGroup.controls["dashboardTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Forum des cotes</mat-label>
              <input
                matInput
                placeholder="Forum des cotes"
                formControlName="battlefieldTitle"
                type="text"
              />
              <mat-error
                *ngIf="formGroup.controls['battlefieldTitle']?.errors"
                >{{
                  formGroup.controls["battlefieldTitle"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Matchs</mat-label>
              <input
                matInput
                placeholder="Matchs"
                formControlName="eventsTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['eventsTitle']?.errors">{{
                formGroup.controls["eventsTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Mes paris</mat-label>
              <input
                matInput
                placeholder="Mes paris"
                formControlName="myBetsTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['myBetstTitle']?.errors">{{
                formGroup.controls["myBetstTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Ticket</mat-label>
              <input
                matInput
                placeholder="Ticket"
                formControlName="ticketTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['ticketTitle']?.errors">{{
                formGroup.controls["ticketTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Classement</mat-label>
              <input
                matInput
                placeholder="Classement"
                formControlName="rankingsTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['rankingsTitle']?.errors">{{
                formGroup.controls["rankingsTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Trophées</mat-label>
              <input
                matInput
                placeholder="Trophées"
                formControlName="trophiesTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['trophiesTitle']?.errors">{{
                formGroup.controls["trophiesTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Demi-dieux</mat-label>
              <input
                matInput
                placeholder="Demi-dieux"
                formControlName="halfGodsTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['halfGodsTitle']?.errors">{{
                formGroup.controls["halfGodsTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Héros</mat-label>
              <input
                matInput
                placeholder="Héros"
                formControlName="heroesTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['heroesTitle']?.errors">{{
                formGroup.controls["heroesTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Légendes</mat-label>
              <input
                matInput
                placeholder="Légends"
                formControlName="legendsTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['legendsTitle']?.errors">{{
                formGroup.controls["legendsTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Stadiums</mat-label>
              <input
                matInput
                placeholder="Stadiums"
                formControlName="stadiumsTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['stadiumsTitle']?.errors">{{
                formGroup.controls["eventsTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Bonus</h2>
            <mat-form-field appearance="fill">
              <mat-label>Bonus - Présentation</mat-label>
              <textarea
                matInput
                placeholder="Bonus - Présentation"
                formControlName="bonusesParagraph"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['bonusesParagraph']?.errors"
                >{{
                  formGroup.controls["bonusesParagraph"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Boosters (titre)</mat-label>
              <input
                matInput
                placeholder="Boosters (titre)"
                formControlName="boosterTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['boosterTitle']?.errors">{{
                formGroup.controls["boosterTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Boosters (description)</mat-label>
              <textarea
                matInput
                placeholder="Boosters (description)"
                formControlName="boosterDescription"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['boosterDescription']?.errors"
                >{{
                  formGroup.controls["boosterDescription"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Boosters (présentation)</mat-label>
              <textarea
                matInput
                placeholder="Boosters (présentation)"
                formControlName="boosterPresentation"
                type="text"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['boosterPresentation']?.errors"
                >{{
                  formGroup.controls["boosterPresentation"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Assurances (titre)</mat-label>
              <input
                matInput
                placeholder="Assurances (titre)"
                formControlName="insuranceTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['insuranceTitle']?.errors">{{
                formGroup.controls["insuranceTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Assurances (description)</mat-label>
              <textarea
                matInput
                placeholder="Assurances (description)"
                formControlName="insuranceDescription"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['insuranceDescription']?.errors"
                >{{
                  formGroup.controls["insuranceDescription"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Assurances (présentation)</mat-label>
              <textarea
                matInput
                placeholder="Assurances (présentation)"
                formControlName="insurancePresentation"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['insurancePresentation']?.errors"
                >{{
                  formGroup.controls["insurancePresentation"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Zeuros (titre)</mat-label>
              <input
                matInput
                placeholder="Zeuros (titre)"
                formControlName="zeurosTitle"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['zeurosTitle']?.errors">{{
                formGroup.controls["zeurosTitle"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Zeuros (description)</mat-label>
              <textarea
                matInput
                placeholder="Zeuros (description)"
                formControlName="zeurosDescription"
                type="text"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['zeurosDescription']?.errors"
                >{{
                  formGroup.controls["zeurosDescription"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Zeuros (présentation)</mat-label>
              <textarea
                matInput
                placeholder="Zeuros (présentation)"
                formControlName="zeurosPresentation"
              ></textarea>
              <mat-error
                *ngIf="formGroup.controls['zeurosPresentation']?.errors"
                >{{
                  formGroup.controls["zeurosPresentation"]?.errors?.error
                }}</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <mat-divider style="margin: 10px 0"></mat-divider>
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h1>Bonus</h1>
            <!-- Mode bonuses -->
            <div>
              <div
                formArrayName="modeBonuses"
                *ngFor="
                  let modeBonusFormGroup of modeBonuses.controls;
                  let i = index
                "
              >
                <div
                  [formGroupName]="i"
                  style="
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                  "
                >
                  <mat-form-field appearance="fill">
                    <mat-label>Bonus</mat-label>
                    <mat-select formControlName="selectedBonusGuid" required>
                      <mat-option [value]="null">--</mat-option>
                      <mat-optgroup
                        *ngFor="let group of bonusesGroups"
                        [label]="group.name"
                      >
                        <mat-option
                          *ngFor="let bonus of group.bonuses"
                          [value]="bonus.guid ?? ''"
                          [disabled]="!canBonusBeSelected(bonus.guid ?? '')"
                        >
                          {{ bonus.name }}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                    <mat-hint>{{
                      getBonusTypeByBonusGuid(
                        modeBonuses.controls[i].value["selectedBonusGuid"]
                      )
                    }}</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>Probabilité</mat-label>
                    <input
                      matInput
                      placeholder="Probabilité"
                      type="number"
                      formControlName="probability"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>Nb par défaut</mat-label>
                    <input
                      matInput
                      placeholder="Nb par défaut"
                      type="number"
                      formControlName="defaultCount"
                      required
                    />
                  </mat-form-field>
                  <mat-slide-toggle formControlName="isEditable"
                    >Configurable</mat-slide-toggle
                  >
                  <div>
                    <button
                      mat-raised-button
                      (click)="removeModeBonusFormGroup(i)"
                      color="accent"
                      type="button"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
                <br />
              </div>
              <div class="text-center">
                <button
                  mat-raised-button
                  (click)="addModeBonusFormGroup()"
                  color="accent"
                  type="button"
                >
                  Ajouter un bonus
                </button>
              </div>
            </div>
            <mat-divider style="margin: 10px 0"></mat-divider>
            <h1>Ligues</h1>
            <div style="display: flex">
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Ligues</mat-label>
                <mat-chip-list #leagueChipList [multiple]="true">
                  <mat-chip
                    *ngFor="let league of mode.leagues"
                    (removed)="
                      removeLeague(league); leagueSearchInput.value = ''
                    "
                  >
                    {{ league.id + " - " + league.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher une ligue..."
                    #leagueSearchInput
                    [(ngModel)]="leagueSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchLeagues()"
                    [matAutocomplete]="leagueAutocomplete"
                    [matChipInputFor]="leagueChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #leagueAutocomplete="matAutocomplete"
                  (optionSelected)="leagueSearchTerm = ''"
                >
                  <mat-option disabled *ngIf="leagueSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let league of searchedLeagues"
                    (click)="addLeague(league)"
                  >
                    {{ league.id + " - " + league.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <mat-divider style="margin: 10px 0"></mat-divider>
            <h1>Sports</h1>
            <div style="display: flex">
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Sports</mat-label>
                <mat-chip-list #sportChipList [multiple]="true">
                  <mat-chip
                    *ngFor="let sport of mode.sports"
                    (removed)="removeSport(sport); sportSearchInput.value = ''"
                  >
                    {{ sport.id + " - " + sport.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher une ligue..."
                    #sportSearchInput
                    [(ngModel)]="sportSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchSports()"
                    [matAutocomplete]="sportAutocomplete"
                    [matChipInputFor]="sportChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #sportAutocomplete="matAutocomplete"
                  (optionSelected)="sportSearchTerm = ''"
                >
                  <mat-option disabled *ngIf="sportSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let sport of searchedSports"
                    (click)="addSport(sport)"
                  >
                    {{ sport.id + " - " + sport.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <mat-divider style="margin: 10px 0"></mat-divider>
            <h1>Trophées personnalisés</h1>
            <p>Les trophées selectionnés remplacent les trophées par défaut</p>
            <div style="display: flex; flex-direction: column">
              <!-- Stadiums -->
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Trophées (stadium)</mat-label>
                <mat-chip-list #trophyStadiumChipList [multiple]="true">
                  <mat-chip
                    *ngFor="let trophy of mode.customStadiumsTrophies"
                    (removed)="
                      removeTrophy(trophy, 'arenas');
                      trophyStadiumSearchInput.value = ''
                    "
                  >
                    {{ trophy.id + " - " + trophy.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher un trophée (stadium)..."
                    #trophyStadiumSearchInput
                    [(ngModel)]="trophyStadiumSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchTrophy('arenas')"
                    [matAutocomplete]="trophyStadiumAutocomplete"
                    [matChipInputFor]="trophyStadiumChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #trophyStadiumAutocomplete="matAutocomplete"
                  (optionSelected)="trophyStadiumSearchTerm = ''"
                >
                  <mat-option disabled *ngIf="trophyStadiumSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let trophy of searchedTrophyStadium"
                    (click)="addTrophy(trophy, 'arenas')"
                  >
                    {{ trophy.id + " - " + trophy.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <!-- Legends -->
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Trophées (légendes)</mat-label>
                <mat-chip-list #trophyLegendsChipList [multiple]="true">
                  <mat-chip
                    *ngFor="let trophy of mode.customLegendsTrophies"
                    (removed)="
                      removeTrophy(trophy, 'legends');
                      trophyLegendsSearchInput.value = ''
                    "
                  >
                    {{ trophy.id + " - " + trophy.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher un trophée (légendes)..."
                    #trophyLegendsSearchInput
                    [(ngModel)]="trophyLegendsSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchTrophy('legends')"
                    [matAutocomplete]="trophyLegendsAutocomplete"
                    [matChipInputFor]="trophyLegendsChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #trophyLegendsAutocomplete="matAutocomplete"
                  (optionSelected)="trophyLegendsSearchTerm = ''"
                >
                  <mat-option disabled *ngIf="trophyLegendsSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let trophy of searchedTrophyLegends"
                    (click)="addTrophy(trophy, 'legends')"
                  >
                    {{ trophy.id + " - " + trophy.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <!-- Heroes -->
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Trophées (héros)</mat-label>
                <mat-chip-list #trophyHeroesChipList [multiple]="true">
                  <mat-chip
                    *ngFor="let trophy of mode.customHeroesTrophies"
                    (removed)="
                      removeTrophy(trophy, 'heroes');
                      trophyHeroesSearchInput.value = ''
                    "
                  >
                    {{ trophy.id + " - " + trophy.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher un trophée (héros)..."
                    #trophyHeroesSearchInput
                    [(ngModel)]="trophyHeroesSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchTrophy('heroes')"
                    [matAutocomplete]="trophyHeroesAutocomplete"
                    [matChipInputFor]="trophyHeroesChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #trophyHeroesAutocomplete="matAutocomplete"
                  (optionSelected)="trophyHeroesSearchTerm = ''"
                >
                  <mat-option disabled *ngIf="trophyHeroesSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let trophy of searchedTrophyHeroes"
                    (click)="addTrophy(trophy, 'heroes')"
                  >
                    {{ trophy.id + " - " + trophy.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <!-- HalfGods -->
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Trophées (demi-dieux)</mat-label>
                <mat-chip-list #trophyHalfGodsChipList [multiple]="true">
                  <mat-chip
                    *ngFor="let trophy of mode.customHalfGodsTrophies"
                    (removed)="
                      removeTrophy(trophy, 'half_gods');
                      trophyHalfGodsSearchInput.value = ''
                    "
                  >
                    {{ trophy.id + " - " + trophy.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher un trophée (demi-dieux)..."
                    #trophyHalfGodsSearchInput
                    [(ngModel)]="trophyHalfGodsSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchTrophy('half_gods')"
                    [matAutocomplete]="trophyHalfGodsAutocomplete"
                    [matChipInputFor]="trophyHalfGodsChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #trophyHalfGodsAutocomplete="matAutocomplete"
                  (optionSelected)="trophyHalfGodsSearchTerm = ''"
                >
                  <mat-option disabled *ngIf="trophyHalfGodsSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let trophy of searchedTrophyHalfGods"
                    (click)="addTrophy(trophy, 'half_gods')"
                  >
                    {{ trophy.id + " - " + trophy.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <mat-divider style="margin: 10px 0"></mat-divider>
            <h1>Images</h1>

            <div>
              <h1>Résumé</h1>
              <div
                style="
                  display: flex;
                  flex-direction: 'row';
                  justify-content: 'space-around';
                "
              >
                <div>
                  <app-image-manager
                    [image]="mode?.dashboardImage"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Résumé (activé)'"
                    (imageChanged)="imageChanged($event, 'dashboard')"
                    (imageUploadStart)="dashboardImageUploadLoading = true"
                    (imageUploadEnd)="dashboardImageUploadLoading = false"
                  ></app-image-manager>
                </div>
                <div>
                  <app-image-manager
                    [image]="mode?.dashboardImageDisabled"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Résumé (désactivé)'"
                    (imageChanged)="imageChanged($event, 'dashboardDisabled')"
                    (imageUploadStart)="
                      dashboardDisabledImageUploadLoading = true
                    "
                    (imageUploadEnd)="
                      dashboardDisabledImageUploadLoading = false
                    "
                  ></app-image-manager>
                </div>
              </div>
            </div>

            <div>
              <h1>Forum des cotes</h1>
              <div
                style="
                  display: flex;
                  flex-direction: 'row';
                  justify-content: 'space-around';
                "
              >
                <div>
                  <app-image-manager
                    [image]="mode?.battlefieldImage"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Forum des cotes (activé)'"
                    (imageChanged)="imageChanged($event, 'battlefield')"
                    (imageUploadStart)="battlefieldImageUploadLoading = true"
                    (imageUploadEnd)="battlefieldImageUploadLoading = false"
                  ></app-image-manager>
                </div>
                <div>
                  <app-image-manager
                    [image]="mode?.battlefieldImageDisabled"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Forum des cotes (désactivé)'"
                    (imageChanged)="imageChanged($event, 'battlefieldDisabled')"
                    (imageUploadStart)="
                      battlefieldDisabledImageUploadLoading = true
                    "
                    (imageUploadEnd)="
                      battlefieldDisabledImageUploadLoading = false
                    "
                  ></app-image-manager>
                </div>
              </div>
            </div>

            <div>
              <h1>Bonus</h1>
              <div
                style="
                  display: flex;
                  flex-direction: 'row';
                  justify-content: 'space-around';
                "
              >
                <div>
                  <app-image-manager
                    [image]="mode?.bonusesImage"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Bonus (activé)'"
                    (imageChanged)="imageChanged($event, 'bonuses')"
                    (imageUploadStart)="bonusesImageUploadLoading = true"
                    (imageUploadEnd)="bonusesImageUploadLoading = false"
                  ></app-image-manager>
                </div>
                <div>
                  <app-image-manager
                    [image]="mode?.bonusesImageDisabled"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Bonus (désactivé)'"
                    (imageChanged)="imageChanged($event, 'bonusesDisabled')"
                    (imageUploadStart)="
                      bonusesDisabledImageUploadLoading = true
                    "
                    (imageUploadEnd)="bonusesDisabledImageUploadLoading = false"
                  ></app-image-manager>
                </div>
              </div>
            </div>

            <div>
              <h1>Classement</h1>
              <div
                style="
                  display: flex;
                  flex-direction: 'row';
                  justify-content: 'space-around';
                "
              >
                <div>
                  <app-image-manager
                    [image]="mode?.rankingsImage"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Classement (activé)'"
                    (imageChanged)="imageChanged($event, 'rankings')"
                    (imageUploadStart)="rankingsImageUploadLoading = true"
                    (imageUploadEnd)="rankingsImageUploadLoading = false"
                  ></app-image-manager>
                </div>
                <div>
                  <app-image-manager
                    [image]="mode?.rankingsImageDisabled"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Classement (désactivé)'"
                    (imageChanged)="imageChanged($event, 'rankingsDisabled')"
                    (imageUploadStart)="
                      rankingsDisabledImageUploadLoading = true
                    "
                    (imageUploadEnd)="
                      rankingsDisabledImageUploadLoading = false
                    "
                  ></app-image-manager>
                </div>
              </div>
            </div>

            <div>
              <h1>Trophées</h1>
              <div
                style="
                  display: flex;
                  flex-direction: 'row';
                  justify-content: 'space-around';
                "
              >
                <div>
                  <app-image-manager
                    [image]="mode?.prizesImage"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Trophées (activé)'"
                    (imageChanged)="imageChanged($event, 'trophies')"
                    (imageUploadStart)="trophiesImageUploadLoading = true"
                    (imageUploadEnd)="trophiesImageUploadLoading = false"
                  ></app-image-manager>
                </div>
                <div>
                  <app-image-manager
                    [image]="mode?.prizesImageDisabled"
                    [width]="124"
                    [height]="127"
                    [recommendedWidth]="124"
                    [recommendedHeight]="127"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Trophées (désactivé)'"
                    (imageChanged)="imageChanged($event, 'trophiesDisabled')"
                    (imageUploadStart)="
                      trophiesDisabledImageUploadLoading = true
                    "
                    (imageUploadEnd)="
                      trophiesDisabledImageUploadLoading = false
                    "
                  ></app-image-manager>
                </div>
              </div>
            </div>

            <div>
              <h1>Oddzeus</h1>
              <div
                style="
                  display: flex;
                  flex-direction: 'row';
                  justify-content: 'space-around';
                "
              >
                <div>
                  <app-image-manager
                    [image]="mode?.oddzeusImage"
                    [width]="400"
                    [height]="450"
                    [recommendedWidth]="900"
                    [recommendedHeight]="800"
                    [isEditable]="true"
                    [path]="'modes/'"
                    [placeholder]="'Oddzeus'"
                    (imageChanged)="imageChanged($event, 'oddzeus')"
                    (imageUploadStart)="oddzeusImageUploadLoading = true"
                    (imageUploadEnd)="oddzeusImageUploadLoading = false"
                  ></app-image-manager>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitLoading || !mode || !formGroup.valid"
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
