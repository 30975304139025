import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { ContestUser, ContestUserStatus } from 'src/entities/ContestUser';
import { User } from 'src/entities/User';
import { UserService } from 'src/services/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-user-modal',
  templateUrl: './show-user-modal.component.html',
  styleUrls: ['./show-user-modal.component.css'],
})
export class ShowUserModalComponent implements OnInit {
  user: User | null = null;
  contestUsers: ContestUser[] = [];

  displayedColumns: string[] = ['username', 'status'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.userService.getUser(this.data.guid).subscribe((user: User) => {
        this.user = user;
        this.contestUsers = user.contestUsers['hydra:member'];
      });

      // Change url
      this.location.replaceState(`/users/${this.data?.guid}`);
    }
  }

  getChipColorFromStatus(contestUser: ContestUser): ChipColor {
    if (contestUser.isCreator) return ChipColor.BLUE;

    switch (contestUser.status) {
      case ContestUserStatus.STATUS_PENDING:
        return ChipColor.ORANGE;
      case ContestUserStatus.STATUS_JOINED:
        return ChipColor.GREEN;
    }
  }

  getChipTooltipFromStatus(contestUser: ContestUser): string {
    if (contestUser.isCreator) return 'Créateur';

    switch (contestUser.status) {
      case ContestUserStatus.STATUS_PENDING:
        return 'En attente';
      case ContestUserStatus.STATUS_JOINED:
        return 'Participant';
    }
  }
}
