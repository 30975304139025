<!-- Loader -->
<mat-spinner *ngIf="!data"></mat-spinner>

<section class="main-section">
  <ng-container *ngIf="data">
    <div *ngIf="!loading; else loader" style="width: 100%">
      <div style="margin: 10px; float: right">
        <button
          matSuffix
          mat-raised-button
          color="primary"
          (click)="editAdModal()"
        >
          Nouvelle publicité
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- link -->
        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>URL</th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.link" [href]="element.link" target="_blank">{{
              element.link
            }}</a
            ><span *ngIf="!element.link">-</span>
          </td>
        </ng-container>

        <!-- skipTime -->
        <ng-container matColumnDef="skipTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Temps avant le skip
          </th>
          <td mat-cell *matCellDef="let element">{{ element.skipTime }}</td>
        </ng-container>

        <!-- minimalAge -->
        <ng-container matColumnDef="minimalAge">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Age minimum</th>
          <td mat-cell *matCellDef="let element">
            {{ element.minimalAge | nullDash }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showAdModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="editAdModal(element.id)"
                class="cursor_pointer"
                matTooltip="Editer"
              >
                <mat-icon>edit</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        #paginator
        showFirstLastButtons
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </ng-container>
</section>
<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
