import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Discount } from 'src/entities/Discount';
import { PaymentService } from 'src/services/payment.service';
import { NavigationService } from 'src/services/navigation.service';
import { ShowDiscountModalComponent } from './show-discount-modal/show-discount-modal.component';
import { DeleteDiscountModalComponent } from './delete-discount-modal/delete-discount-modal.component';
import { EditDiscountModalComponent } from './edit-discount-modal/edit-discount-modal.component';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css'],
})
export class DiscountsComponent implements OnInit, OnDestroy {
  data: Discount[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'code',
    'product',
    'currentUses',
    'maxUses',
    'expiresAt',
    'active',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'desc',
  };
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;

  constructor(
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Compétiteurs');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowDiscountModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.paymentService
      .getDiscounts(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['code'] = this.searchedValue;

    return params;
  }

  /**
   * Find a discount in the data by id
   */
  getDiscountById(id: number): Discount | null {
    const index: number = this.data.findIndex(
      (discount: Discount) => discount.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(discount: any): void {
    // Clear the order if no header is sorted
    if (discount.active === '' || discount.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: discount.active,
        direction: discount.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(discount: any): void {
    this.searchedValue = (discount.target as HTMLTextAreaElement).value;
    this.setData(false);
  }

  /**
   * Show the show discount modal
   */
  showDiscountModal(id: number): void {
    const discount: Discount | null = this.getDiscountById(id);
    if (discount) {
      this.dialog.open(ShowDiscountModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: discount.guid,
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }

  /**
   * Show the edit discount modal
   */
  editDiscountModal(id: number | null = null): void {
    let discount: Discount | null = null;
    if (id) {
      discount = this.getDiscountById(id);
    } else {
      discount = new Discount();
    }

    if (discount) {
      const dialogRef = this.dialog.open(EditDiscountModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: discount.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  deleteDiscount(guid: string) {
    this.paymentService
      .deleteDiscount(guid)
      .subscribe(() => this.setData(false));
  }
  /**
   * Show the delete discount modal
   */
  deleteDiscountModal(id: number): void {
    const discount: Discount | null = this.getDiscountById(id);
    if (discount?.guid) {
      const dialogRef = this.dialog.open(DeleteDiscountModalComponent);
      dialogRef.afterClosed().subscribe((result) => {
        // Delete the discount
        if (result && discount.guid) this.deleteDiscount(discount.guid);
      });
    }
  }
}
