<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="variant; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/variants/' + variant.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ variant?.title ?? "" }}
    </h1>
    <div *ngIf="!variant">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="variant">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2>Général</h2>
          <p><strong>Title : </strong>{{ variant.title }}</p>
          <p><strong>Description : </strong>{{ variant.description }}</p>
          <p>
            <strong>Activée : </strong
            >{{ variant.isEnabled | yesNo | nullDash }}
          </p>
          <mat-divider></mat-divider>
          <br />
          <h2>Configuration</h2>
          <p>
            <strong>Bourse par défaut : </strong
            >{{ variant.defaultWallet | number | nullDash }}
          </p>
          <p>
            <strong>Bourse par défaut configurable : </strong
            >{{ variant.isDefaultWalletEditable | yesNo }}
          </p>
          <p>
            <strong>Revenus quotidiens : </strong
            >{{ variant.dailyIncome | number | nullDash }}
          </p>
          <p>
            <strong>Revenus quotidiens configurable : </strong
            >{{ variant.isDailyIncomeEditable | yesNo }}
          </p>
          <p>
            <strong>Bonus quotidiens : </strong
            >{{ variant.dailyBonuses | number | nullDash }}
          </p>
          <p>
            <strong>Bonus quotidiens configurable : </strong
            >{{ variant.areDailyBonusesEditable | yesNo | nullDash }}
          </p>
          <p>
            <strong>Assidu: </strong
            >{{ variant.isAssiduous | yesNo | nullDash }}
          </p>
          <p>
            <strong
              >Les bonus par défaut ont besoin d'être récupérés manuellement : </strong
            >{{ variant.doesDefaultBonusesNeedRetrieval | yesNo }}
          </p>
          <p>
            <strong
              >Les bonus non récupérés le sont à la prochaine distribution : </strong
            >{{ variant.autoRetrieveExcessBonuses | yesNo }}
          </p>
          <br />
          <mat-divider></mat-divider>
          <div style="flex: 1; display: flex">
            <div style="flex: 1">
              <h2><strong>Bonus : </strong></h2>
              <p
                *ngIf="variant.variantBonuses.length === 0"
                style="text-align: center"
              >
                Aucun bonus ajouté
              </p>
              <table
                mat-table
                [dataSource]="variant.variantBonuses"
                matSort
                class="mat-elevation-z8"
                *ngIf="variant.variantBonuses.length > 0"
              >
                <!-- Bonus -->
                <ng-container matColumnDef="bonus">
                  <th mat-header-cell *matHeaderCellDef>Bonus</th>
                  <td mat-cell *matCellDef="let element">
                    <a
                      mat-dialog-close
                      [routerLink]="['/bonuses', element.bonus.guid]"
                      >{{ element.bonus.name }}</a
                    >
                  </td>
                </ng-container>

                <!-- DefaultCount -->
                <ng-container matColumnDef="defaultCount">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="cursor_help"
                    [matTooltip]="'Nb de bonus au début de l\'arène'"
                  >
                    Par défaut
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.defaultCount }}
                  </td>
                </ng-container>

                <!-- isEditable -->
                <ng-container matColumnDef="isEditable">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="cursor_help"
                    [matTooltip]="
                      'Le créateur peut changer le nb de bonus au début de l\'arène'
                    "
                  >
                    Editable
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <app-status-chip
                      [bool]="element.isEditable"
                    ></app-status-chip>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedVariantBonusColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedVariantBonusColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
