import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Bonus, BonusOperatorType, BonusType } from 'src/entities/Bonus';
import { AppLanguages, LanguageService } from 'src/services/language.service';
import { BonusService } from 'src/services/bonus.service';

@Component({
  selector: 'app-edit-bonus-modal',
  templateUrl: './edit-bonus-modal.component.html',
  styleUrls: ['./edit-bonus-modal.component.css'],
})
export class EditBonusModalComponent implements OnInit {
  bonus: Bonus | null = null;
  submitLoading: boolean = false;
  imageUploadLoading: boolean = false;
  errors: string | null = null;
  loading: boolean = true;
  operators: { name: string; value: BonusOperatorType }[] = [
    { name: 'Pourcentage', value: BonusOperatorType.PERCENTAGE },
    { name: 'Puissance', value: BonusOperatorType.POWER },
  ];
  types: { name: string; value: BonusType }[] = [
    { name: 'Booster', value: BonusType.BOOSTER },
    { name: 'Assurance', value: BonusType.INSURANCE },
    { name: 'Zeuros', value: BonusType.ZEUROS },
  ];

  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    slug: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    value: new FormControl(null, [Validators.required]),
    position: new FormControl(1, [Validators.required]),
    operator: new FormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bonusService: BonusService,
    private dialogRef: MatDialogRef<EditBonusModalComponent>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve bonus and init form
      this.bonusService.getBonus(this.data.guid).subscribe((bonus: Bonus) => {
        this.bonus = bonus;
        this.createBonusFormGroup();
        this.loading = false;
      });
    } else {
      this.bonus = new Bonus();
      this.createBonusFormGroup();
      this.loading = false;
    }
  }

  imageChanged(event: any) {
    if (this.bonus) {
      if (event === null) {
        this.bonus.image = null;
      } else {
        this.bonus.image = event['@id'] ?? null;
      }
    }
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);
    data.image = this.bonus?.image;
    if (data.image.guid) data.image = `/s/media_objects/${data.image.guid}`;

    if (data.type === BonusType.INSURANCE)
      data.operator = BonusOperatorType.PERCENTAGE;

    if (data.type === BonusType.ZEUROS) data.operator = null;

    data = this.createTranslations(data);

    this.errors = null;

    // POST / PUT Bonus
    let request: Observable<Bonus> = this.data.guid
      ? this.bonusService.putBonus(this.data.guid, data)
      : this.bonusService.createBonus(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error?.violations?.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Create the bonus form group
   */
  createBonusFormGroup(): void {
    if (this.bonus) {
      const bonus = this.bonus;

      this.formGroup.setValue({
        name: bonus.name,
        description: bonus.description,
        slug: bonus.slug,
        type: bonus.type,
        position: bonus.position,
        operator: bonus.operator,
        value: bonus.value,
      });
    }
  }

  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      name: data.name === '' ? null : data.name,
      description: data.description === '' ? null : data.description,
    };

    delete data.name;
    delete data.description;

    // Retrieve all translations and set the new one
    data.translations = this.bonus?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }
}
