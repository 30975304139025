import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Mode } from 'src/entities/Mode';
import { NotificationModel } from 'src/entities/Notification';
import { LanguageService } from 'src/services/language.service';
import { ModeService } from 'src/services/mode.service';
import { NavigationService } from 'src/services/navigation.service';
import { NotificationService } from 'src/services/notification.service';
import { EditNotificationModalComponent } from './edit-notification-modal/edit-notification-modal.component';
import { GeneralNotificationModalComponent } from './general-notification-modal/general-notification-modal.component';
import { ShowNotificationModalComponent } from './show-notification-modal/show-notification-modal.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  data: NotificationModel[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'slug',
    'title',
    'content',
    'isPushNotif',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  selectedMode: Mode | null = null;
  modesSearchedValue: string | null = null;
  modesSearchSubscription: any | null = null;
  modesSearchLoading: boolean = false;
  searchedModes: Mode[] = [];
  loadSubscription: any = null;
  languageSubscription: any = null;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private notificationService: NotificationService,
    private modeService: ModeService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService
  ) {
    this.navigationService.setCurrentPageName('Notifications');
    // Show notification if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowNotificationModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  searchModes(): void {
    let params: any = {};
    params['translations.title'] = this.modesSearchedValue;
    params[`order[translations.title]`] = 'ASC';
    params['itemsPerPage'] = 3;

    this.modesSearchLoading = true;

    if (this.modesSearchSubscription)
      this.modesSearchSubscription.unsubscribe();

    this.modesSearchSubscription = this.modeService.getModes(params).subscribe(
      (response: any) => (this.searchedModes = response['hydra:member']),
      () => null,
      () => (this.modesSearchLoading = false)
    );
  }

  /**
   * Show the show notification modal
   */
  showNotificationModal(id: number): void {
    const notification: NotificationModel | null = this.getNotificationById(id);

    if (notification) {
      this.dialog.open(ShowNotificationModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: notification.guid,
        },
      });
    }
  }

  showGeneralNotificationModal(isBigMatchNotification: boolean = false): void {
    this.dialog.open(GeneralNotificationModalComponent, {
      width: this.MODAL_WIDTH,
      data: { isBigMatchNotification: isBigMatchNotification },
    });
  }

  /**
   * Show the edit notification modal
   */
  editNotificationModal(id: number | null = null): void {
    let notification: NotificationModel | null = null;

    if (id) {
      notification = this.getNotificationById(id);
      if (!notification) return;
    }

    if (notification) {
      const dialogRef = this.dialog.open(EditNotificationModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: notification.guid,
          mode: this.selectedMode,
          newNotification:
            notification.mode === null && this.selectedMode !== null,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.notificationService
      .getNotificationModels(this.getParams())
      .subscribe(
        (response: any) => {
          this.data = this.filterNotifications(response['hydra:member']);

          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  filterNotifications(notifications: NotificationModel[]): NotificationModel[] {
    let filtered: NotificationModel[] = [];

    notifications.forEach((notification: NotificationModel) => {
      if (this.selectedMode) {
        if (notification.mode?.guid === this.selectedMode?.guid) {
          filtered.push(notification);
        }
      } else {
        if (!notification.mode) filtered.push(notification);
      }
    });

    notifications.forEach((notification: NotificationModel) => {
      if (this.selectedMode !== null && notification.mode === null) {
        let index: number = filtered.findIndex(
          (_notif: NotificationModel) => _notif.slug === notification.slug
        );
        if (index === -1) {
          filtered.push(notification);
        }
      }
    });

    return filtered;
  }
  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['translations.title'] = this.searchedValue;

    return params;
  }

  /**
   * Find a notification in the data by id
   */
  getNotificationById(id: number): NotificationModel | null {
    const index: number = this.data.findIndex(
      (notification: NotificationModel) => notification.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  /**
   * Search a notification by title
   */
  setSearchedValue(event: Event): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }
}
