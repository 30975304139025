<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="notification; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.title ?? "-" }}
      </h1>
      <div *ngIf="!notification">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="notification">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>
            <mat-form-field appearance="fill">
              <mat-label>Titre</mat-label>
              <input
                matInput
                placeholder="Titre"
                formControlName="title"
                required
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['title']?.errors">{{
                formGroup.controls["title"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Contenu</mat-label>
              <textarea
                matInput
                placeholder="Contenu"
                formControlName="content"
              ></textarea>
              <mat-error *ngIf="formGroup.controls['contenu']?.errors">{{
                formGroup.controls["contenu"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <p>
              <mat-slide-toggle formControlName="isPushNotif"
                >Notification push</mat-slide-toggle
              >
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitLoading || !notification || !formGroup.valid"
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
