import { Bonus } from './Bonus';
import { Mode } from './Mode';

export interface ModeVariantBonus {
  variant: ModeVariant;
  bonus: Bonus;
  defaultCount: number;
  isEditable: boolean;
}

export class ModeVariant {
  id: number | null = null;
  guid: string | null = null;
  title: string | null = null;
  isEnabled: boolean = false;
  mode: Mode | null = null;
  description: string | null = null;
  isAssiduous: boolean | null = null;
  doesDefaultBonusesNeedRetrieval: boolean | null = null;
  autoRetrieveExcessBonuses: boolean | null = null;
  defaultWallet: number | null = null;
  isDefaultWalletEditable: boolean | null = null;
  dailyIncome: number | null = null;
  isDailyIncomeEditable: boolean | null = null;
  dailyBonuses: number | null = null;
  areDailyBonusesEditable: boolean | null = null;
  translations: any;
  variantBonuses: any = [];
}
