<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="league; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        Edition - {{ league?.name ?? "" }}
      </h1>
      <div *ngIf="!league">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="league">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                required
                placeholder="Nom de l'arène"
                formControlName="name"
              />
              <mat-error *ngIf="formGroup.controls['name']?.errors">{{
                formGroup.controls["name"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <p>
              <mat-slide-toggle formControlName="isFeatured"
                >En avant</mat-slide-toggle
              >
            </p>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="submitLoading || !league"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
