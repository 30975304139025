import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  getActionTypeStringFromActionType,
  SlideshowImage,
  SlideshowImageActionType,
} from 'src/entities/SlideshowImage';
import { NavigationService } from 'src/services/navigation.service';
import { SlideshowImageService } from 'src/services/slideshow-image.service';
import { EditModeModalComponent } from '../../modes/edit-mode-modal/edit-mode-modal.component';
import { EditSlideshowImageModalComponent } from './edit-slideshow-image-modal/edit-slideshow-image-modal.component';
import { ShowSlideshowImageModalComponent } from './show-slideshow-image-modal/show-slideshow-image-modal.component';

@Component({
  selector: 'app-contests',
  templateUrl: './slideshow-images.component.html',
  styleUrls: ['./slideshow-images.component.css'],
})
export class SlideshowImagesComponent implements OnInit {
  data: SlideshowImage[] = [];
  loading: boolean = false;
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'slug',
    'startAt',
    'endAt',
    'isEnabled',
    'isAlsoForPremium',
    'actionType',
    'target',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private slideshowImageService: SlideshowImageService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Forum des cotes');
    // Show slideshow image if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowSlideshowImageModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.slideshowImageService.getSlideshowImages(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;

    return params;
  }

  /**
   * Find a slideshow image in the data by id
   */
  getSlideshowImageById(id: number): SlideshowImage | null {
    const index: number = this.data.findIndex(
      (slideshowImage: SlideshowImage) => slideshowImage.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }

    // Set all data
    this.setData(false);
  }

  getActionTypeStringFromType(actionType: SlideshowImageActionType) {
    return getActionTypeStringFromActionType(actionType);
  }

  getTargetString(element: any): string {
    switch (element.actionType) {
      case SlideshowImageActionType.ACTION_TYPE_CONTEST:
        return element.contest?.name;
      case SlideshowImageActionType.ACTION_TYPE_EVENT:
        return element.event?.name;
      case SlideshowImageActionType.ACTION_TYPE_HANDICAP:
        return element.handicap?.name;
      case SlideshowImageActionType.ACTION_TYPE_LEAGUE:
        return element.league?.name;
      case SlideshowImageActionType.ACTION_TYPE_URL:
        return element.URL;
    }

    return '-';
  }

  /**
   * Show the show slideshowImage modal
   */
  showSlideshowImageModal(id: number): void {
    const slideshowImage: SlideshowImage | null =
      this.getSlideshowImageById(id);

    if (slideshowImage) {
      this.dialog.open(ShowSlideshowImageModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: slideshowImage.guid,
        },
      });
    }
  }

  /**
   * Show the edit slideshowImage modal
   */
  editSlideshowImageModal(id: number | null = null): void {
    let slideshowImage: SlideshowImage | null = null;

    if (id) {
      slideshowImage = this.getSlideshowImageById(id);
      if (!slideshowImage) return;
    } else {
      slideshowImage = new SlideshowImage();
    }

    if (slideshowImage) {
      const dialogRef = this.dialog.open(EditSlideshowImageModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: slideshowImage.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }
}
