<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="prize; else loader">
    <h1 class="mat-h1">{{ prize?.title ?? "" }}</h1>
    <div *ngIf="!prize">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="prize">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <app-image-manager
            *ngIf="prize.image"
            [image]="prize.image"
            [width]="200"
            [height]="200"
            [recommendedHeight]="500"
            [recommendedWidth]="500"
          ></app-image-manager>
          <p><strong>Titre : </strong>{{ prize.title }}</p>
          <p><strong>Contenu : </strong>{{ prize.content }}</p>
          <p><strong>Position : </strong>{{ prize.position | number }}</p>
          <p></p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
