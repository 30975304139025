import { Bet } from './Bet';
import { BetGroup } from './BetGroup';
import { Bonus } from './Bonus';
import { Contest } from './Contest';
import { OddResult } from './Odd';
import { User } from './User';

export class ContestBonusBetUser {
  id: number | null = null;
  guid: string | null = null;
  user: User | null = null;
  contest: Contest | null = null;
  bet: Bet | null = null;
  bonus: Bonus | null = null;
  createdAt: Date | null = null;
  betValue: number | null = null;
  betGroup: BetGroup | null = null;
  result: OddResult | null = null;
  calculatedOdd: number | null = null;
  calculatedGain: number | null = null;
}

export enum TicketType {
  TICKET_SIMPLE = 0,
  TICKET_COMBINATED = 1,
}

export function getTicketTypeName(value: TicketType | undefined): string {
  switch (value) {
    case TicketType.TICKET_SIMPLE:
      return 'Simple';
    case TicketType.TICKET_COMBINATED:
      return 'Combiné';
    default:
      return '-';
  }
}
