import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetricsComponent } from './admin/dashboard/metrics/metrics.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { APIService } from 'src/services/api.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { NotFoundComponent } from './not-found/not-found.component';
import { KPIComponent } from './admin/dashboard/kpi/kpi.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { UserService } from 'src/services/user.service';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { ContestService } from 'src/services/contest.service';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { ModeService } from 'src/services/mode.service';
import { BonusService } from 'src/services/bonus.service';
import { AdsComponent } from './admin/ads/ads.component';
import { ShowBonusModalComponent } from './admin/bonuses/show-bonus-modal/show-bonus-modal.component';
import { BonusesComponent } from './admin/bonuses/bonuses.component';
import { ShowModeModalComponent } from './admin/modes/show-mode-modal/show-mode-modal.component';
import { ModesComponent } from './admin/modes/modes.component';
import { ContestsComponent } from './admin/contests/contests.component';
import { UnbanUserModalComponent } from './admin/users/unban-user-modal/unban-user-modal.component';
import { ShowContestModalComponent } from './admin/contests/show-contest-modal/show-contest-modal.component';
import { ShowUserModalComponent } from './admin/users/show-user-modal/show-user-modal.component';
import { DeleteUserModalComponent } from './admin/users/delete-user-modal/delete-user-modal.component';
import { BanUserModalComponent } from './admin/users/ban-user-modal/ban-user-modal.component';
import { UsersComponent } from './admin/users/users.component';
import { SlideshowImagesComponent } from './admin/ads/slideshow-images/slideshow-images.component';
import { SlideshowImageService } from 'src/services/slideshow-image.service';
import { BetsComponent } from './admin/bets/bets.component';
import { LeaguesComponent } from './admin/bets/leagues/leagues.component';
import { EventsComponent } from './admin/bets/events/events.component';
import { LeagueService } from 'src/services/league.service';
import { EventService } from 'src/services/event.service';
import { ShowEventModalComponent } from './admin/bets/events/show-event-modal/show-event-modal.component';
import { ShowLeagueModalComponent } from './admin/bets/leagues/show-league-modal/show-league-modal.component';
import { EditLeagueModalComponent } from './admin/bets/leagues/edit-league-modal/edit-league-modal.component';
import { EditContestModalComponent } from './admin/contests/edit-contest-modal/edit-contest-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { LanguageService } from 'src/services/language.service';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CopyEntityLinkComponent } from './copy-entity-link/copy-entity-link.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CompetitorService } from 'src/services/competitor.service';
import { ShowCompetitorModalComponent } from './admin/bets/competitors/show-competitor-modal/show-competitor-modal.component';
import { CompetitorsComponent } from './admin/bets/competitors/competitors.component';
import { EditModeModalComponent } from './admin/modes/edit-mode-modal/edit-mode-modal.component';
import { SportService } from 'src/services/sport.service';
import { YesNoPipe } from './pipe/yes-no.pipe';
import { NullDashPipe } from './pipe/null-dash.pipe';
import { EditBonusModalComponent } from './admin/bonuses/edit-bonus-modal/edit-bonus-modal.component';
import { HistoricComponent } from './admin/bets/historic/historic.component';
import { ContestBonusBetUserService } from 'src/services/contestBonusBetUser.service';
import { EditSlideshowImageModalComponent } from './admin/ads/slideshow-images/edit-slideshow-image-modal/edit-slideshow-image-modal.component';
import { ShowSlideshowImageModalComponent } from './admin/ads/slideshow-images/show-slideshow-image-modal/show-slideshow-image-modal.component';
import { LOCALE_ID } from '@angular/core';
import { ImageManagerComponent } from './image-manager/image-manager.component';
import { PrizesComponent } from './admin/prizes/prizes.component';
import { ShowPrizeModalComponent } from './admin/prizes/show-prize-modal/show-prize-modal.component';
import { EditPrizeModalComponent } from './admin/prizes/edit-prize-modal/edit-prize-modal.component';
import { DeletePrizeModalComponent } from './admin/prizes/delete-prize-modal/delete-prize-modal.component';
import { TrophiesComponent } from './admin/trophies/trophies.component';
import { EditTrophyModalComponent } from './admin/trophies/edit-trophy-modal/edit-trophy-modal.component';
import { ShowTrophyModalComponent } from './admin/trophies/show-trophy-modal/show-trophy-modal.component';
import { TrophyService } from 'src/services/trophy.service';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { GeneralNotificationModalComponent } from './admin/notifications/general-notification-modal/general-notification-modal.component';
import { NotificationService } from 'src/services/notification.service';
import { EditNotificationModalComponent } from './admin/notifications/edit-notification-modal/edit-notification-modal.component';
import { ShowNotificationModalComponent } from './admin/notifications/show-notification-modal/show-notification-modal.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { NavigationService } from 'src/services/navigation.service';
import { AdService } from 'src/services/ad.service';
import { FullscreenAdsComponent } from './admin/ads/fullscreen-ads/fullscreen-ads.component';
import { ShowFullscreenAdModalComponent } from './admin/ads/fullscreen-ads/show-fullscreen-ad-modal/show-fullscreen-ad-modal.component';
import { EditFullscreenAdModalComponent } from './admin/ads/fullscreen-ads/edit-fullscreen-ad-modal/edit-fullscreen-ad-modal.component';

import { CampainsComponent } from './admin/ads/campains/campains.component';
import { ShowCampainModalComponent } from './admin/ads/campains/show-campain-modal/show-campain-modal.component';
import { EditCampainModalComponent } from './admin/ads/campains/edit-campain-modal/edit-campain-modal.component';
import { AdCampainService } from 'src/services/adCampain.service';
import { DeleteCampainModalComponent } from './admin/ads/campains/delete-campain-modal/delete-campain-modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { VariantService } from 'src/services/variant.service';
import { VariantsComponent } from './admin/variants/variants.component';
import { ShowVariantModalComponent } from './admin/variants/show-variant-modal/show-variant-modal.component';
import { EditVariantModalComponent } from './admin/variants/edit-variant-modal/edit-variant-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaymentsComponent } from './admin/payments/payments.component';
import { ProductsComponent } from './admin/payments/products/products.component';
import { DeleteProductModalComponent } from './admin/payments/products/delete-product-modal/delete-product-modal.component';
import { EditProductModalComponent } from './admin/payments/products/edit-product-modal/edit-product-modal.component';
import { ShowProductModalComponent } from './admin/payments/products/show-product-modal/show-product-modal.component';
import { PaymentService } from 'src/services/payment.service';
import { TransactionsComponent } from './admin/payments/transactions/transactions.component';
import { ShowTransactionModalComponent } from './admin/payments/transactions/show-transaction-modal/show-transaction-modal.component';
import { DiscountsComponent } from './admin/payments/discounts/discounts.component';
import { ShowDiscountModalComponent } from './admin/payments/discounts/show-discount-modal/show-discount-modal.component';
import { EditDiscountModalComponent } from './admin/payments/discounts/edit-discount-modal/edit-discount-modal.component';
import { DeleteDiscountModalComponent } from './admin/payments/discounts/delete-discount-modal/delete-discount-modal.component';
import { InfoMessagesComponent } from './admin/info-messages/info-messages.component';
import { ShowInfoMessageModalComponent } from './admin/info-messages/show-info-message-modal/show-info-message-modal.component';
import { DeleteInfoMessageModalComponent } from './admin/info-messages/delete-info-message-modal/delete-info-message-modal.component';
import { EditInfoMessageModalComponent } from './admin/info-messages/edit-info-message-modal/edit-info-message-modal.component';
import { InfoMessageService } from 'src/services/info-message.service';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    MetricsComponent,
    DashboardComponent,
    NotFoundComponent,
    KPIComponent,
    UsersComponent,
    BanUserModalComponent,
    DeleteUserModalComponent,
    ShowUserModalComponent,
    ShowContestModalComponent,
    UnbanUserModalComponent,
    LoginComponent,
    AdminComponent,
    ContestsComponent,
    StatusChipComponent,
    ModesComponent,
    ShowModeModalComponent,
    EditModeModalComponent,
    BonusesComponent,
    ShowBonusModalComponent,
    AdsComponent,
    SlideshowImagesComponent,
    BetsComponent,
    LeaguesComponent,
    EventsComponent,
    ShowEventModalComponent,
    ShowLeagueModalComponent,
    EditContestModalComponent,
    LanguageSelectorComponent,
    CopyEntityLinkComponent,
    CompetitorsComponent,
    ShowCompetitorModalComponent,
    YesNoPipe,
    NullDashPipe,
    EditLeagueModalComponent,
    EditBonusModalComponent,
    HistoricComponent,
    ShowSlideshowImageModalComponent,
    EditSlideshowImageModalComponent,
    ImageManagerComponent,
    PrizesComponent,
    ShowPrizeModalComponent,
    EditPrizeModalComponent,
    DeletePrizeModalComponent,
    TrophiesComponent,
    EditTrophyModalComponent,
    ShowTrophyModalComponent,
    NotificationsComponent,
    GeneralNotificationModalComponent,
    EditNotificationModalComponent,
    ShowNotificationModalComponent,
    PageHeaderComponent,
    FullscreenAdsComponent,
    ShowFullscreenAdModalComponent,
    EditFullscreenAdModalComponent,
    CampainsComponent,
    ShowCampainModalComponent,
    EditCampainModalComponent,
    DeleteCampainModalComponent,
    VariantsComponent,
    ShowVariantModalComponent,
    EditVariantModalComponent,
    PaymentsComponent,
    ProductsComponent,
    ShowProductModalComponent,
    EditProductModalComponent,
    DeleteProductModalComponent,

    TransactionsComponent,
    ShowTransactionModalComponent,

    DiscountsComponent,
    ShowDiscountModalComponent,
    EditDiscountModalComponent,
    DeleteDiscountModalComponent,

    InfoMessagesComponent,
    ShowInfoMessageModalComponent,
    EditInfoMessageModalComponent,
    DeleteInfoMessageModalComponent,
  ],
  imports: [
    NgxMaterialTimepickerModule.setLocale('fr-FR'),
    BrowserModule,
    MatProgressBarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatTreeModule,
    MatGridListModule,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    ClipboardModule,
    MatSnackBarModule,
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' }, //replace "en-US" with your locale
    APIService,
    UserService,
    ContestService,
    ModeService,
    BonusService,
    SlideshowImageService,
    LeagueService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    EventService,
    LanguageService,
    NavigationService,
    CompetitorService,
    SportService,
    ContestBonusBetUserService,
    TrophyService,
    NotificationService,
    AdService,
    VariantService,
    AdCampainService,
    PaymentService,
    InfoMessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
