import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Mode } from 'src/entities/Mode';
import { LanguageService } from 'src/services/language.service';
import { ModeService } from 'src/services/mode.service';
import { NavigationService } from 'src/services/navigation.service';
import { EditModeModalComponent } from './edit-mode-modal/edit-mode-modal.component';
import { ShowModeModalComponent } from './show-mode-modal/show-mode-modal.component';

@Component({
  selector: 'app-modes',
  templateUrl: './modes.component.html',
  styleUrls: ['./modes.component.css'],
})
export class ModesComponent implements OnInit, OnDestroy {
  data: Mode[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'title',
    'isDisabled',
    'startAt',
    'endAt',
    'isForFreemium',
    'isForPremium',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  loadSubscription: any = null;
  languageSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private modeService: ModeService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Modes');
    // Show mode if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowModeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();

    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  /**
   * Show the show mode modal
   */
  showModeModal(id: number): void {
    const mode: Mode | null = this.getModeById(id);

    if (mode) {
      this.dialog.open(ShowModeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: mode.guid,
        },
      });
    }
  }

  /**
   * Show the edit mode modal
   */
  editModeModal(id: number | null = null): void {
    let mode: Mode | null = null;

    if (id) {
      mode = this.getModeById(id);
      if (!mode) return;
    } else {
      mode = new Mode();
    }

    if (mode) {
      const dialogRef = this.dialog.open(EditModeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: mode.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.modeService
      .getModes(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['translations.title'] = this.searchedValue;

    return params;
  }

  /**
   * Find a mode in the data by id
   */
  getModeById(id: number): Mode | null {
    const index: number = this.data.findIndex((mode: Mode) => mode.id === id);
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  /**
   * Search a mode by title
   */
  setSearchedValue(event: Event): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
}
