<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="slideshowImage; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.slug ?? "-" }}
      </h1>
      <div *ngIf="!slideshowImage">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="slideshowImage">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>

            <app-image-manager
              [image]="slideshowImage.image"
              [width]="500"
              [height]="225"
              [recommendedHeight]="1000"
              [recommendedWidth]="450"
              [isEditable]="true"
              [path]="'bonuses/'"
              (imageChanged)="imageChanged($event)"
              (imageUploadStart)="imageUploadLoading = true"
              (imageUploadEnd)="imageUploadLoading = false"
            ></app-image-manager>

            <mat-form-field appearance="fill">
              <mat-label>Slug</mat-label>
              <input
                matInput
                placeholder="slug-de-la-publicite"
                formControlName="slug"
                required
              />
              <mat-error *ngIf="formGroup.controls['slug']?.errors">{{
                formGroup.controls["slug"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Date de début</mat-label>
              <input
                matInput
                [matDatepicker]="startAtPicker"
                formControlName="startAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['startAt']?.errors">{{
                formGroup.controls["startAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Date de fin</mat-label>
              <input
                matInput
                [matDatepicker]="endAtPicker"
                formControlName="endAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['endAt']?.errors">{{
                formGroup.controls["endAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-slide-toggle formControlName="isEnabled"
              >Activé</mat-slide-toggle
            >
            <mat-slide-toggle formControlName="isAlsoForPremium"
              >Activé en mode premium</mat-slide-toggle
            >
            <br />
            <mat-divider></mat-divider>
            <br />
            <h2>Configuration</h2>

            <mat-form-field appearance="fill">
              <mat-label>Action</mat-label>
              <mat-select formControlName="actionType" required>
                <mat-option
                  *ngFor="let actionType of actionTypes"
                  [value]="actionType.value"
                >
                  {{ actionType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearance="fill"
              *ngIf="
                formGroup.value.actionType ===
                slideshowImageActionType.ACTION_TYPE_URL
              "
            >
              <mat-label>URL</mat-label>
              <input
                matInput
                placeholder="http://exemple.fr"
                formControlName="URL"
                type="url"
                required
              />
              <mat-error *ngIf="formGroup.controls['URL']?.errors">{{
                formGroup.controls["URL"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <ng-container
              *ngIf="
                formGroup.value.actionType ===
                slideshowImageActionType.ACTION_TYPE_CONTEST
              "
            >
              <div style="display: flex">
                <mat-form-field style="flex: 1" appearance="fill">
                  <mat-label>Arène selectionnée</mat-label>
                  <mat-chip-list #contestChipList [multiple]="false">
                    <mat-chip
                      *ngIf="selectedContest"
                      (removed)="
                        selectedContest = null; contestSearchInput.value = ''
                      "
                    >
                      {{ selectedContest.id + " - " + selectedContest.name }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="Rechercher une arène..."
                      #contestSearchInput
                      [(ngModel)]="contestSearchTerm"
                      [ngModelOptions]="{ standalone: true }"
                      (input)="searchContests()"
                      [matAutocomplete]="contestAutocomplete"
                      [disabled]="selectedContest !== null"
                      [hidden]="selectedContest !== null"
                      [matChipInputFor]="contestChipList"
                    />
                  </mat-chip-list>
                  <mat-autocomplete #contestAutocomplete="matAutocomplete">
                    <mat-option disabled *ngIf="contestSearchLoading"
                      ><mat-spinner
                        [diameter]="25"
                        style="margin: auto"
                      ></mat-spinner
                    ></mat-option>
                    <mat-option
                      *ngFor="let contest of searchedContests"
                      (click)="selectedContest = contest"
                    >
                      {{ contest.id + " - " + contest.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                formGroup.value.actionType ===
                slideshowImageActionType.ACTION_TYPE_LEAGUE
              "
            >
              <div style="display: flex">
                <mat-form-field style="flex: 1" appearance="fill">
                  <mat-label>Ligue selectionnée</mat-label>
                  <mat-chip-list #leagueChipList [multiple]="false">
                    <mat-chip
                      *ngIf="selectedLeague"
                      (removed)="
                        selectedLeague = null; leagueSearchInput.value = ''
                      "
                    >
                      {{ selectedLeague.id + " - " + selectedLeague.name }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="Rechercher une ligue..."
                      #leagueSearchInput
                      [(ngModel)]="leagueSearchTerm"
                      [ngModelOptions]="{ standalone: true }"
                      (input)="searchLeagues()"
                      [matAutocomplete]="leagueAutocomplete"
                      [disabled]="selectedLeague !== null"
                      [hidden]="selectedLeague !== null"
                      [matChipInputFor]="leagueChipList"
                    />
                  </mat-chip-list>
                  <mat-autocomplete #leagueAutocomplete="matAutocomplete">
                    <mat-option disabled *ngIf="leagueSearchLoading"
                      ><mat-spinner
                        [diameter]="25"
                        style="margin: auto"
                      ></mat-spinner
                    ></mat-option>
                    <mat-option
                      *ngFor="let league of searchedLeagues"
                      (click)="selectedLeague = league"
                    >
                      {{ league.id + " - " + league.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                formGroup.value.actionType ===
                slideshowImageActionType.ACTION_TYPE_EVENT
              "
            >
              <div style="display: flex">
                <mat-form-field style="flex: 1" appearance="fill">
                  <mat-label>Match selectionné</mat-label>
                  <mat-chip-list #eventChipList [multiple]="false">
                    <mat-chip
                      *ngIf="selectedEvent"
                      (removed)="
                        selectedEvent = null; eventSearchInput.value = ''
                      "
                    >
                      {{ selectedEvent.id + " - " + selectedEvent.name }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="Rechercher un match..."
                      #eventSearchInput
                      [(ngModel)]="eventSearchTerm"
                      [ngModelOptions]="{ standalone: true }"
                      (input)="searchEvents()"
                      [matAutocomplete]="eventAutocomplete"
                      [disabled]="selectedEvent !== null"
                      [hidden]="selectedEvent !== null"
                      [matChipInputFor]="eventChipList"
                    />
                  </mat-chip-list>
                  <mat-autocomplete #eventAutocomplete="matAutocomplete">
                    <mat-option disabled *ngIf="eventSearchLoading"
                      ><mat-spinner
                        [diameter]="25"
                        style="margin: auto"
                      ></mat-spinner
                    ></mat-option>
                    <mat-option
                      *ngFor="let event of searchedEvents"
                      (click)="selectedEvent = event"
                    >
                      {{ event.id + " - " + event.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </ng-container>

            <div style="display: flex">
              <mat-form-field style="flex: 1" appearance="fill">
                <mat-label>Visible uniquement pour certaines arènes</mat-label>
                <mat-chip-list #visibleForContestChipList multiple>
                  <mat-chip
                    *ngFor="let contest of slideshowImage.visibleForContests"
                    (removed)="
                      removeVisibleForContestItem(contest);
                      visibleForContestSearchInput.value = ''
                    "
                  >
                    {{ contest.id + " - " + contest.name }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Rechercher une arène..."
                    #visibleForContestSearchInput
                    [(ngModel)]="contestSearchTerm"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="searchContests()"
                    [matAutocomplete]="visibleForContestAutocomplete"
                    [matChipInputFor]="visibleForContestChipList"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #visibleForContestAutocomplete="matAutocomplete"
                >
                  <mat-option disabled *ngIf="contestSearchLoading"
                    ><mat-spinner
                      [diameter]="25"
                      style="margin: auto"
                    ></mat-spinner
                  ></mat-option>
                  <mat-option
                    *ngFor="let contest of searchedContests"
                    (click)="
                      addVisibleForContestItem(contest);
                      visibleForContestSearchInput.value = ''
                    "
                  >
                    {{ contest.id + " - " + contest.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="
        submitLoading ||
        !slideshowImage ||
        !formGroup.valid ||
        !slideshowImage.image
      "
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
