<form
  [formGroup]="formGroup"
  (ngSubmit)="submitLoading ? null : showConfirmModal()"
>
  <mat-dialog-content class="mat-typography">
    <ng-container>
      <h1 class="mat-display-1" style="text-align: center">
        {{
          isBigMatchNotification
            ? "Notification - Match épique"
            : "Notification générale"
        }}
      </h1>
      <div>
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <mat-form-field appearance="fill">
              <mat-label>Titre</mat-label>
              <input
                matInput
                placeholder="Titre"
                formControlName="title"
                required
                type="text"
              />
              <mat-error *ngIf="formGroup.controls['title']?.errors">{{
                formGroup.controls["title"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Contenu</mat-label>
              <textarea
                matInput
                placeholder="Contenu"
                formControlName="content"
              ></textarea>
              <mat-error *ngIf="formGroup.controls['contenu']?.errors">{{
                formGroup.controls["contenu"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitLoading || !formGroup.valid"
    >
      Envoyer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
