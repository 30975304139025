<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="mode; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/modes/' + mode.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ mode?.title ?? "" }}
    </h1>
    <div *ngIf="!mode">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="mode">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2>Général</h2>
          <p><strong>Title : </strong>{{ mode.title }}</p>
          <p><strong>Description : </strong>{{ mode.description }}</p>
          <p><strong>slug : </strong>{{ mode.slug }}</p>
          <p>
            <strong>Date de début : </strong
            >{{ mode.startAt | date | nullDash }}
          </p>
          <p>
            <strong>Date de fin : </strong>{{ mode.endAt | date | nullDash }}
          </p>
          <p>
            <strong>Activé : </strong>{{ mode.isDisabled | yesNo | nullDash }}
          </p>
          <p>
            <strong>Activé en mode freemium : </strong
            >{{ mode.isForFreemium | yesNo | nullDash }}
          </p>
          <p>
            <strong>Activé en mode premium : </strong
            >{{ mode.isForPremium | yesNo | nullDash }}
          </p>
          <mat-divider></mat-divider>
          <br />
          <h2>Configuration</h2>
          <p>
            <strong>Mise par défault maximale : </strong
            >{{ mode.maxInitialBet | number }}
          </p>
          <p>
            <strong>Type de ticket par défaut lorsqu'au moins 2 paris : </strong
            >{{ getTicketTypeName() }}
          </p>
          <p>
            <strong>Configurable : </strong
            >{{ mode.isConfigurable | yesNo | nullDash }}
          </p>
          <p>
            <strong>Bourse par défaut : </strong
            >{{ mode.defaultWallet | number }}
          </p>
          <p>
            <strong>Bourse par défaut configurable : </strong
            >{{ mode.isDefaultWalletEditable | yesNo }}
          </p>
          <p>
            <strong>Revenus quotidiens : </strong
            >{{ mode.dailyIncome | number }}
          </p>
          <p>
            <strong>Revenus quotidiens configurable : </strong
            >{{ mode.isDailyIncomeEditable | yesNo }}
          </p>
          <p>
            <strong>Bonus quotidiens : </strong>{{ mode.dailyBonuses | number }}
          </p>
          <p>
            <strong>Bonus quotidiens configurable : </strong
            >{{ mode.areDailyBonusesEditable | yesNo }}
          </p>
          <p><strong>Assidu: </strong>{{ mode.isAssiduous | yesNo }}</p>
          <p>
            <strong
              >Les bonus par défaut ont besoin d'être récupérés manuellement : </strong
            >{{ mode.doesDefaultBonusesNeedRetrieval | yesNo }}
          </p>
          <p>
            <strong
              >Les bonus non récupérés le sont à la prochaine distribution : </strong
            >{{ mode.autoRetrieveExcessBonuses | yesNo }}
          </p>
          <br />
          <mat-divider></mat-divider>
          <h2>Couleurs</h2>
          <div class="color-container">
            <p class="color-text">
              <strong>Couleur 1 : </strong>
              {{ mode.primaryColor | nullDash }}
            </p>
            <div
              class="color-box"
              *ngIf="mode.primaryColor"
              [style.background-color]="mode.primaryColor"
            ></div>
          </div>
          <div class="color-container">
            <p class="color-text">
              <strong>Couleur 2 : </strong>
              {{ mode.secondaryColor | nullDash }}
            </p>
            <div
              class="color-box"
              *ngIf="mode.secondaryColor"
              [style.background-color]="mode.secondaryColor"
            ></div>
          </div>
          <div class="color-container">
            <p class="color-text">
              <strong>Couleur 3 : </strong>
              {{ mode.tertiaryColor | nullDash }}
            </p>
            <div
              class="color-box"
              *ngIf="mode.tertiaryColor"
              [style.background-color]="mode.tertiaryColor"
            ></div>
          </div>
          <div class="color-container">
            <p class="color-text">
              <strong>Couleur texte : </strong>
              {{ mode.textColor | nullDash }}
            </p>
            <div
              class="color-box"
              *ngIf="mode.textColor"
              [style.background-color]="mode.textColor"
            ></div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <h2>Traductions</h2>
          <p><strong>OddZeus : </strong>{{ mode.oddzeus | nullDash }}</p>
          <p>
            <strong>Zeuros (court) : </strong>{{ mode.moneyShort | nullDash }}
          </p>
          <p>
            <strong>Zeuros (court - suffixe) : </strong
            >{{ mode.moneyShortSuffix | nullDash }}
          </p>
          <p>
            <strong>Zeuros (long) : </strong>{{ mode.moneyLong | nullDash }}
          </p>
          <p>
            <strong>Zeuros (long) : </strong>{{ mode.moneyLong | nullDash }}
          </p>

          <br />
          <mat-divider></mat-divider>
          <h2>Widgets</h2>
          <p>
            <strong>Bonus : </strong>{{ mode.bonusesWidgetTitle | nullDash }}
          </p>
          <p>
            <strong>Classement : </strong
            >{{ mode.rankingsWidgetTitle | nullDash }}
          </p>
          <p><strong>Paris : </strong>{{ mode.betsWidgetTitle | nullDash }}</p>
          <p>
            <strong>Pacte : </strong>{{ mode.contractWidgetTitle | nullDash }}
          </p>
          <p>
            <strong>Trophées : </strong
            >{{ mode.trophiesWidgetTitle | nullDash }}
          </p>

          <br />
          <mat-divider></mat-divider>
          <h2>Onglets</h2>
          <p><strong>Résumé : </strong>{{ mode.dashboardTitle | nullDash }}</p>
          <p>
            <strong>Forum des cotes : </strong
            >{{ mode.battlefieldTitle | nullDash }}
          </p>
          <p><strong>Matchs : </strong>{{ mode.eventsTitle | nullDash }}</p>
          <p><strong>Mes paris : </strong>{{ mode.myBetsTitle | nullDash }}</p>
          <p><strong>Ticket : </strong>{{ mode.ticketTitle | nullDash }}</p>
          <p>
            <strong>Classement : </strong>{{ mode.rankingsTitle | nullDash }}
          </p>
          <p><strong>Trophées : </strong>{{ mode.trophiesTitle | nullDash }}</p>
          <p>
            <strong>Demi-dieux : </strong>{{ mode.halfGodsTitle | nullDash }}
          </p>
          <p><strong>Héros : </strong>{{ mode.heroesTitle | nullDash }}</p>
          <p><strong>Légendes : </strong>{{ mode.legendsTitle | nullDash }}</p>
          <p><strong>Stadiums : </strong>{{ mode.stadiumsTitle | nullDash }}</p>

          <br />
          <mat-divider></mat-divider>
          <h2>Bonus</h2>
          <p>
            <strong>Bonus (présentation) : </strong
            >{{ mode.bonusesParagraph | nullDash }}
          </p>
          <p>
            <strong>Boosters (description) : </strong
            >{{ mode.boosterDescription | nullDash }}
          </p>
          <p>
            <strong>Boosters (présentation) : </strong
            >{{ mode.boosterPresentation | nullDash }}
          </p>
          <p>
            <strong>Boosters (titre) : </strong
            >{{ mode.boosterTitle | nullDash }}
          </p>

          <p>
            <strong>Assurances (description) : </strong
            >{{ mode.insuranceDescription | nullDash }}
          </p>
          <p>
            <strong>Assurances (présentation) : </strong
            >{{ mode.insurancePresentation | nullDash }}
          </p>
          <p>
            <strong>Assurances (titre) : </strong
            >{{ mode.insuranceTitle | nullDash }}
          </p>

          <p>
            <strong>Zeuros (description) : </strong
            >{{ mode.zeurosDescription | nullDash }}
          </p>
          <p>
            <strong>Zeuros (présentation) : </strong
            >{{ mode.zeurosPresentation | nullDash }}
          </p>
          <p>
            <strong>Zeuros (titre) : </strong>{{ mode.zeurosTitle | nullDash }}
          </p>
        </div>
      </div>
      <br />
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2><strong>Bonus : </strong></h2>
          <p *ngIf="mode.modeBonuses.length === 0" style="text-align: center">
            Aucun bonus ajouté
          </p>
          <table
            mat-table
            [dataSource]="mode.modeBonuses"
            matSort
            class="mat-elevation-z8"
            *ngIf="mode.modeBonuses.length > 0"
          >
            <!-- Bonus -->
            <ng-container matColumnDef="bonus">
              <th mat-header-cell *matHeaderCellDef>Bonus</th>
              <td mat-cell *matCellDef="let element">
                <a
                  mat-dialog-close
                  [routerLink]="['/bonuses', element.bonus.guid]"
                  >{{ element.bonus.name }}</a
                >
              </td>
            </ng-container>

            <!-- DefaultCount -->
            <ng-container matColumnDef="defaultCount">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="cursor_help"
                [matTooltip]="'Nb de bonus au début de l\'arène'"
              >
                Par défaut
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.defaultCount }}
              </td>
            </ng-container>

            <!-- isEditable -->
            <ng-container matColumnDef="isEditable">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="cursor_help"
                [matTooltip]="
                  'Le créateur peut changer le nb de bonus au début de l\'arène'
                "
              >
                Editable
              </th>
              <td mat-cell *matCellDef="let element">
                <app-status-chip [bool]="element.isEditable"></app-status-chip>
              </td>
            </ng-container>

            <!-- Probability -->
            <ng-container matColumnDef="probability">
              <th mat-header-cell *matHeaderCellDef>Probabiblité</th>
              <td mat-cell *matCellDef="let element">
                {{ element.probability }}
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedModeBonusColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedModeBonusColumns"
            ></tr>
          </table>
        </div>
      </div>
      <br />
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2><strong>Sports : </strong></h2>
          <p *ngIf="mode.sports.length === 0" style="text-align: center">
            Aucun sport ajouté
          </p>
          <table
            mat-table
            [dataSource]="mode.sports"
            matSort
            class="mat-elevation-z8"
            *ngIf="mode.sports.length > 0"
          >
            <!-- Sport -->
            <ng-container matColumnDef="sport">
              <th mat-header-cell *matHeaderCellDef>Sport</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['sport']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['sport']"></tr>
          </table>
        </div>
      </div>
      <br />
      <mat-divider></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2><strong>Ligues : </strong></h2>
          <p *ngIf="mode.leagues.length === 0" style="text-align: center">
            Aucune ligue ajoutée
          </p>
          <table
            mat-table
            [dataSource]="mode.leagues"
            matSort
            class="mat-elevation-z8"
            *ngIf="mode.leagues.length > 0"
          >
            <!-- Ligue -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Ligue</th>
              <td mat-cell *matCellDef="let element">
                <a
                  mat-dialog-close
                  [routerLink]="['/bets/leagues', element.guid]"
                  >{{ element.name }}</a
                >
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['name']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name']"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
