import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Mode } from 'src/entities/Mode';
import { APIService } from './api.service';

@Injectable()
export class ModeService {
  constructor(private apiService: APIService) {}

  public getModes(params: any = null): Observable<Mode[]> {
    return this.apiService.get(`/modes`, params, true);
  }
  public getMode(guid: string): Observable<Mode> {
    return this.apiService.get(`/modes/${guid}`);
  }
  public putMode(guid: string, body: Partial<Mode>): Observable<Mode> {
    return this.apiService.put(`/modes/${guid}`, body);
  }
  public createMode(body: Partial<Mode>): Observable<Mode> {
    return this.apiService.post(`/modes`, body);
  }
}
