<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="notification; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/notifications/' + notification.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-h1">{{ notification?.slug ?? "" }}</h1>
    <div *ngIf="!notification">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="notification">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Titre : </strong>{{ notification.title }}</p>
          <p>
            <strong>Contenu : </strong>{{ notification.content | nullDash }}
          </p>
          <p><strong>Slug : </strong>{{ notification.slug }}</p>
          <p>
            <strong>Notification push : </strong
            >{{ notification.isPushNotif | yesNo }}
          </p>
          <p></p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
