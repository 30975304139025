<!-- Loader -->
<mat-spinner *ngIf="!data"></mat-spinner>

<section class="main-section">
  <ng-container *ngIf="data">
    <p style="text-align: left">
      Une règle remplace une ou plusieurs propriétés d'un mode. Lorsqu'un mode
      est créé, les règles générales sont copiées pour celui-ci.
    </p>
    <div *ngIf="!loading; else loader" style="width: 100%">
      <div class="text-center">
        <mat-form-field appearance="fill">
          <mat-label>Rechercher une règle</mat-label>
          <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
          <input
            matInput
            type="search"
            [(ngModel)]="searchedValue"
            (input)="setSearchedValue($event)"
          />
          <button
            *ngIf="searchedValue"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchedValue = ''; this.setData(false)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div style="margin: 20px 10px; float: right">
        <button
          matSuffix
          mat-raised-button
          color="primary"
          (click)="editVariantModal()"
        >
          Nouvelle règle
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div>
        <table
          mat-table
          [dataSource]="data"
          matSort
          class="mat-elevation-z8"
          *ngIf="data"
          (matSortChange)="this.sortChange($event)"
        >
          <!-- ID -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <!-- Title -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
            <td mat-cell *matCellDef="let element">{{ element.title }}</td>
          </ng-container>

          <!-- Mode -->
          <ng-container matColumnDef="mode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mode</th>
            <td mat-cell *matCellDef="let element">
              <a
                *ngIf="element.mode"
                mat-dialog-close
                [routerLink]="['/modes', element.mode?.guid ?? '']"
                >{{ element.mode?.title }}</a
              >
              <span *ngIf="!element.mode">Règle générale</span>
            </td>
          </ng-container>

          <!-- isEnabled -->
          <ng-container matColumnDef="isEnabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activé</th>
            <td mat-cell *matCellDef="let element">
              <app-status-chip [bool]="element.isEnabled"> </app-status-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex; justify-content: space-evenly">
                <div
                  (click)="showVariantModal(element.id)"
                  class="cursor_pointer"
                  matTooltip="Afficher"
                >
                  <mat-icon>visibility</mat-icon>
                </div>
                <div
                  (click)="editVariantModal(element.id)"
                  class="cursor_pointer"
                  matTooltip="Editer"
                >
                  <mat-icon>edit</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- Pagination -->
        <mat-paginator
          showFirstLastButtons
          #paginator
          [pageSize]="30"
          [length]="totalItems"
          (page)="page = $event.pageIndex + 1; setData(false)"
        >
        </mat-paginator>
      </div>
    </div>
  </ng-container>
</section>
<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
