import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Ad } from 'src/entities/Ad';
import { AdCampain, AdCampainAd } from 'src/entities/AdCampain';
import { Contest } from 'src/entities/Contest';
import { AdService } from 'src/services/ad.service';
import { AdCampainService } from 'src/services/adCampain.service';
import { ContestService } from 'src/services/contest.service';

@Component({
  selector: 'app-edit-campain-modal',
  templateUrl: './edit-campain-modal.component.html',
  styleUrls: ['./edit-campain-modal.component.css'],
})
export class EditCampainModalComponent implements OnInit {
  adCampain: AdCampain | null = null;
  submitLoading: boolean = false;
  errors: string | null = null;

  loading: boolean = true;
  imageUploadLoading: boolean = false;

  searchedContests: Contest[] = [];
  contestSearchTerm: string = '';
  selectedContest: Contest | null = null;
  searchContestsSubscription: any | null = null;
  contestSearchLoading: boolean = false;

  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    startAt: new FormControl(null),
    endAt: new FormControl(null),
    isEnabled: new FormControl(null),
    allowAppodeal: new FormControl(null),
    adCampainAds: new FormArray([]),
    views: new FormControl(null),
    unlimitedViews: new FormControl(null),
  });
  ads: Ad[] = [];
  displayedColumns: string[] = [
    'images',
    'name',
    'probability',
    'views',
    'hourlyViewsLimit',
    'actions',
  ];

  startAtTime: string | null = '00:00';
  endAtTime: string | null = '00:00';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adCampainService: AdCampainService,
    private adService: AdService,
    private contestService: ContestService,
    private dialogRef: MatDialogRef<EditCampainModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve slideshow image and init form
      this.adCampainService
        .getAdCampain(this.data.guid)
        .subscribe((adCampain: AdCampain) => {
          this.adCampain = adCampain;
          // Retrieve hours and minutes as 'XX:XX' format
          if (this.adCampain.startAt) {
            this.adCampain.startAt = new Date(this.adCampain.startAt);
            const startAtTimeHours = this.adCampain.startAt.getHours();
            this.startAtTime =
              startAtTimeHours < 10
                ? '0' + startAtTimeHours
                : startAtTimeHours.toString();
            const startAtTimeMinutes = this.adCampain.startAt.getMinutes();
            this.startAtTime +=
              ':' +
              (startAtTimeMinutes < 10
                ? '0' + startAtTimeMinutes
                : startAtTimeMinutes.toString());
          }
          if (this.adCampain.endAt) {
            this.adCampain.endAt = new Date(this.adCampain.endAt);
            const endAtTimeHours = this.adCampain.endAt.getHours();
            this.endAtTime =
              endAtTimeHours < 10
                ? '0' + endAtTimeHours
                : endAtTimeHours.toString();
            const endAtTimeMinutes = this.adCampain.endAt.getMinutes();
            this.endAtTime +=
              ':' +
              (endAtTimeMinutes < 10
                ? '0' + endAtTimeMinutes
                : endAtTimeMinutes.toString());
          }

          this.createFormGroup();
          this.createAdCampainAdsFormGroup();
        });
    } else {
      this.adCampain = new AdCampain();
      this.createFormGroup();
    }

    // Retrieve all ads
    this.adService.getAds().subscribe((ads: any) => {
      this.ads = ads['hydra:member'];
    });
  }

  createFormGroup(): void {
    if (this.adCampain)
      this.formGroup.setValue({
        name: this.adCampain.name,
        startAt: this.adCampain.startAt ? this.adCampain.startAt : null,
        endAt: this.adCampain.endAt ? this.adCampain.endAt : null,
        allowAppodeal: this.adCampain.allowAppodeal,
        isEnabled: this.adCampain.isEnabled,
        views: this.adCampain.views,
        adCampainAds: [],
        unlimitedViews: this.adCampain.views === null,
      });

    if (this.formGroup.value['unlimitedViews']) {
      this.formGroup.controls['views'].disable();
    }
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);

    // Set the correct adCampainAd IRIs
    data.adCampainAds
      .filter((adCampainAd: any) => adCampainAd.selectedAdGuid !== null)
      .forEach((adCampainAd: any) => {
        adCampainAd.ad = '/s/ads/' + adCampainAd.selectedAdGuid;
        if (adCampainAd.guid) {
          adCampainAd.id = '/s/ad_campain_ads/' + adCampainAd.guid;
          delete adCampainAd.guid;
        }
        delete adCampainAd.selectedAdGuid;

        adCampainAd.hourlyViewsLimit =
          adCampainAd.hourlyViewsLimit === 0
            ? null
            : adCampainAd.hourlyViewsLimit;
      });

    if (this.adCampain) {
      data.contests = [...this.adCampain?.contests].map(
        (contest: Contest) => `/s/contests/${contest.guid}`
      );
      data.views = data.unlimitedViews ? null : data.views;
    }

    // Add time to startAt and endAt
    if (data.startAt) {
      data.startAt = new Date(data.startAt.toString());
      if (this.startAtTime && this.startAtTime.length > 0) {
        data.startAt.setHours(
          parseInt(this.startAtTime.substring(0, 2)),
          parseInt(this.startAtTime.substring(3, 5)),
          0
        );
      }
      data.startAt = data.startAt.toISOString();
    }
    if (data.endAt) {
      data.endAt = new Date(data.endAt.toString());
      if (this.endAtTime && this.endAtTime.length > 0) {
        data.endAt.setHours(
          parseInt(this.endAtTime.substring(0, 2)),
          parseInt(this.endAtTime.substring(3, 5)),
          0
        );
      }
      data.endAt = data.endAt.toISOString();
    }

    this.errors = null;

    // POST / PUT Mode
    let request: Observable<AdCampain> = this.data.guid
      ? this.adCampainService.putAdCampain(this.data.guid, data)
      : this.adCampainService.createAdCampain(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Add a mode bonus
   */
  addAdCampainAdFormGroup(adCampainAd?: AdCampainAd): void {
    this.adCampainAds?.push(
      new FormGroup({
        probability: new FormControl(
          adCampainAd?.probability ?? null,
          Validators.required
        ),
        selectedAdGuid: new FormControl(
          adCampainAd?.ad?.guid ?? null,
          Validators.required
        ),
        hourlyViewsLimit: new FormControl(
          adCampainAd?.hourlyViewsLimit ?? null
        ),
        guid: new FormControl(adCampainAd?.guid ?? null),
      })
    );
  }

  /**
   * Get the adCampainAds form array
   */
  get adCampainAds() {
    return this.formGroup.get('adCampainAds') as FormArray;
  }

  /**
   * Delete a mode bonus
   */
  removeAdCampainAdFormGroup(i: number): void {
    this.adCampainAds.removeAt(i);
  }

  /**
   * Create a mode bonus form group for every existing mode bonus
   */
  createAdCampainAdsFormGroup(): any {
    this.adCampain?.adCampainAds?.forEach((adCampainAd: AdCampainAd) => {
      this.addAdCampainAdFormGroup(adCampainAd);
    });

    return this.adCampainAds;
  }

  /**
   * Check if a ad is not already selected
   */
  canAdBeSelected(guid: string): boolean {
    return !this.adCampainAds.value.some(
      (adCampainAd: AdCampainAd) => adCampainAd.ad?.guid === guid
    );
  }

  /**
   * Enable/Disable the views input when we check the unlimitedViews checkbox
   */
  changeUnlimitedViewsInput(event: any): void {
    if (event.checked) {
      this.formGroup.controls['views'].disable();
    } else {
      this.formGroup.controls['views'].enable();
    }
  }

  /**
   * Search contests
   */
  searchContests(): void {
    this.contestSearchLoading = true;
    let params: any = [];
    params['name'] = this.contestSearchTerm;
    params['itemsPerPage'] = 3;
    params[`order[id]`] = 'desc';

    if (this.searchContestsSubscription)
      this.searchContestsSubscription.unsubscribe();

    // Search contests
    this.searchContestsSubscription = this.contestService
      .getContests(params)
      .subscribe((data: any) => {
        this.searchedContests = data['hydra:member'];
        this.contestSearchLoading = false;
      });
  }

  /**
   * Remove a contest array element
   */
  removeContestItem(contest: Contest): void {
    this.adCampain?.contests.splice(
      this.adCampain.contests.findIndex(
        (_contest: Contest) => _contest.guid === contest.guid
      ),
      1
    );
  }

  /**
   * Add a contest element
   */
  addContestItem(contest: Contest): void {
    this.adCampain?.contests.push(contest);
  }

  sumAdsProbabilities(): number {
    return this.formGroup.value.adCampainAds.reduce(
      (acc: number, adCampainAd: any) => acc + Number(adCampainAd.probability),
      0
    );
  }

  calculateOtherProbabilities(): number {
    return 1 - this.sumAdsProbabilities();
  }
}
