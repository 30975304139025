import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdCampain, AdCampainAd } from 'src/entities/AdCampain';
import { Location, NumberSymbol } from '@angular/common';
import { AdCampainService } from 'src/services/adCampain.service';

@Component({
  selector: 'app-show-campain-modal',
  templateUrl: './show-campain-modal.component.html',
  styleUrls: ['./show-campain-modal.component.css'],
})
export class ShowCampainModalComponent implements OnInit {
  adCampain: AdCampain | null = null;

  // Columns to display
  displayedColumns: string[] = [
    // 'images',
    'name',
    'probability',
    'views',
    'skipped',
    'clicks',
    'hourlyViewLimit',
    // 'actions',
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adCampainService: AdCampainService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.adCampainService
        .getAdCampain(this.data.guid)
        .subscribe((adCampain: AdCampain) => {
          this.adCampain = adCampain;
        });
      // Change url
      this.location.replaceState(`/ads/campains/${this.data.guid}`);
    }
  }

  sumAdsProbabilities(): number {
    if (this.adCampain?.adCampainAds) {
      return this.adCampain.adCampainAds.reduce(
        (acc: number, adCampainAd: AdCampainAd) =>
          acc + Number(adCampainAd.probability),
        0
      );
    }
    return 0;
  }

  calculateOtherProbabilities(): number {
    return 1 - this.sumAdsProbabilities();
  }
}
