import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { Contest } from 'src/entities/Contest';
import { ContestBonusBetUser } from 'src/entities/ContestBonusBetUser';
import {
  getChipColorFromResult,
  getNameFromOddResult,
  OddResult,
} from 'src/entities/Odd';
import { User } from 'src/entities/User';
import { ContestService } from 'src/services/contest.service';
import { ContestBonusBetUserService } from 'src/services/contestBonusBetUser.service';
import { NavigationService } from 'src/services/navigation.service';
import { UserService } from 'src/services/user.service';
import { Location } from '@angular/common';
import { Wallet } from 'src/entities/Wallet';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.css'],
})
export class HistoricComponent implements OnDestroy {
  simpleBets: ContestBonusBetUser[] | null = null;
  combinatedBets: ContestBonusBetUser[] | null = null;

  loading: boolean = false;
  wallet: Wallet | null = null;
  selectedUser: User | null = null;
  selectedContest: Contest | null = null;

  contestsSearchedValue: string | null = null;
  usersSearchedValue: string | null = null;

  contestsSearchSubscription: any | null = null;
  usersSearchSubscription: any | null = null;

  contestsSearchLoading: boolean = false;
  usersSearchLoading: boolean = false;

  searchedContests: Contest[] = [];
  searchedUsers: User[] = [];

  error: string | null = null;
  loadSubscription: any = null;

  // Columns to display
  displayedColumns: string[] = [
    'id',
    'oddId',
    'betValue',
    'bonus',
    'oddType',
    'oddName',
    'oddHeader',
    'oddValue',
    'event',
    'result',
    'calculatedGain',
    'createdAt',
    'updatedAt',
    'processedAt',
    'finishedAt',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private contestBonusBetUserService: ContestBonusBetUserService,
    private contestService: ContestService,
    private location: Location,
    private userService: UserService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Historique des paris');

    if (
      this.activatedRoute.snapshot.params['contestGuid'] &&
      this.activatedRoute.snapshot.params['userGuid']
    ) {
      this.loading = true;
      let requests: any = [];
      requests.push(
        contestService.getContest(
          this.activatedRoute.snapshot.params['contestGuid']
        )
      );
      requests.push(
        userService.getUser(this.activatedRoute.snapshot.params['userGuid'])
      );

      forkJoin(requests).subscribe(([contest, user]: any) => {
        this.selectedContest = contest;
        this.selectedUser = user;
        this.getBets();
      });
    }
  }

  resetData(): void {
    this.simpleBets = null;
    this.combinatedBets = null;
    this.error = null;
    this.wallet = null;
    this.location.replaceState('/bets/historic');
  }

  banUser(): void {
    if (this.selectedUser && this.selectedContest) {
      if (
        confirm(
          `Êtes vous sûr de bannir ${this.selectedUser.username} de l'arène ${this.selectedContest.name} ? Cette action est irreversible.`
        )
      ) {
        this.userService
          .banUserFromContest(this.selectedContest.guid, this.selectedUser.guid)
          .subscribe(() => null);
      }
    }
  }

  searchContests(): void {
    let params: any = {};
    params['name'] = this.contestsSearchedValue;
    params[`order[name]`] = 'ASC';
    params['itemsPerPage'] = 10;

    this.contestsSearchLoading = true;

    if (this.contestsSearchSubscription)
      this.contestsSearchSubscription.unsubscribe();

    this.contestsSearchSubscription = this.contestService
      .getContests(params)
      .subscribe(
        (response: any) => (this.searchedContests = response['hydra:member']),
        () => null,
        () => (this.contestsSearchLoading = false)
      );
  }

  searchUsers(): void {
    let params: any = {};
    params['username'] = this.usersSearchedValue;
    params[`order[username]`] = 'ASC';
    params['itemsPerPage'] = 10;

    this.usersSearchLoading = true;

    if (this.usersSearchSubscription)
      this.usersSearchSubscription.unsubscribe();

    this.usersSearchSubscription = this.userService.getUsers(params).subscribe(
      (response: any) => (this.searchedUsers = response['hydra:member']),
      () => null,
      () => (this.usersSearchLoading = false)
    );
  }

  getBets(): void {
    if (this.selectedUser && this.selectedContest) {
      // Change url
      this.location.replaceState(
        `/bets/historic/${this.selectedContest.guid}/${this.selectedUser.guid}`
      );

      this.loading = true;

      // Retrieve wallet
      this.contestBonusBetUserService
        .getWalletByContestAndUser(
          this.selectedContest.guid,
          this.selectedUser.guid
        )
        .subscribe((wallet: Wallet) => (this.wallet = wallet));

      // Retrieve bets
      this.contestBonusBetUserService
        .getBetsByContestAndUser(
          this.selectedContest.guid,
          this.selectedUser.guid
        )
        .subscribe(
          (contestBonusBetUsers: any) => {
            this.error = '';
            this.simpleBets = contestBonusBetUsers['hydra:member'].filter(
              (cbbu: ContestBonusBetUser) => cbbu.betGroup === null
            );

            let combinatedCBBUs = contestBonusBetUsers['hydra:member'].filter(
              (cbbu: ContestBonusBetUser) => cbbu.betGroup !== null
            );
            let combinatedBets: ContestBonusBetUser[] = [];

            combinatedCBBUs.sort(
              (a: ContestBonusBetUser, b: ContestBonusBetUser) =>
                (a.betGroup?.id ?? 1) > (b.betGroup?.id ?? 1)
            );

            combinatedCBBUs.forEach((cbbu: ContestBonusBetUser) => {
              combinatedBets.push(
                Object.assign(new ContestBonusBetUser(), {
                  id: cbbu.betGroup?.id,
                  betValue: cbbu.betValue,
                  bonus: cbbu.bonus,
                  oddValue: cbbu.calculatedOdd,
                  calculatedGain: cbbu.calculatedGain,
                  result: cbbu.result,
                  createdAt: cbbu.betGroup?.createdAt,
                  betGroup: cbbu.betGroup,
                })
              );

              cbbu.betGroup?.bets.forEach((bet) =>
                combinatedBets.push(
                  Object.assign(new ContestBonusBetUser(), {
                    bet: bet,
                    betGroup: cbbu.betGroup?.id,
                    result: bet.odd.result,
                    id: bet.id,
                    oddValue: bet.oddValue,
                  })
                )
              );
            });
            this.combinatedBets = combinatedBets;
          },
          (err: any) => {
            this.error = err.error.message;
            this.loading = false;
          },
          () => (this.loading = false)
        );
    }
  }

  ngOnDestroy(): void {
    if (this.usersSearchSubscription)
      this.usersSearchSubscription.unsubscribe();

    if (this.contestsSearchSubscription)
      this.contestsSearchSubscription.unsubscribe();
  }

  getChipColorFromResult(oddResult: OddResult): ChipColor {
    return getChipColorFromResult(oddResult);
  }

  getChipTooltipFromResult(oddResult: OddResult): string {
    return getNameFromOddResult(oddResult);
  }
}
