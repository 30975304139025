import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Trophy, TrophyCategory } from 'src/entities/Trophy';
import { APIService } from './api.service';

@Injectable()
export class TrophyService {
  constructor(private apiService: APIService) {}

  public getTrophies(params: any = null): Observable<Trophy[]> {
    return this.apiService.get(`/trophies`, params, true);
  }
  public getTrophy(guid: string): Observable<Trophy> {
    return this.apiService.get(`/trophies/${guid}`);
  }
  public putTrophy(guid: string, body: Partial<Trophy>): Observable<Trophy> {
    return this.apiService.put(`/trophies/${guid}`, body);
  }
  public createTrophy(body: Partial<Trophy>): Observable<Trophy> {
    return this.apiService.post(`/trophies`, body);
  }

  public getCategories(params: any = null): Observable<TrophyCategory[]> {
    return this.apiService.get(`/trophy_categories`);
  }
}
