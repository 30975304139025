<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="slideshowImage; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/ads/slideshow_images/' + slideshowImage.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ slideshowImage?.slug ?? "" }}
    </h1>
    <div *ngIf="!slideshowImage">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="slideshowImage">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <h2>Général</h2>

          <app-image-manager
            *ngIf="slideshowImage.image"
            [image]="slideshowImage.image"
            [width]="500"
            [height]="225"
            [recommendedHeight]="1000"
            [recommendedWidth]="450"
          ></app-image-manager>

          <p><strong>Slug : </strong>{{ slideshowImage.slug }}</p>
          <p><strong>Action : </strong>{{ slideshowImage.actionType }}</p>

          <p *ngIf="slideshowImage.league">
            <strong>Ligue : </strong
            ><a [routerLink]="'/bets/leagues/' + slideshowImage.league.guid">{{
              slideshowImage.league.name
            }}</a>
          </p>
          <p *ngIf="slideshowImage.contest">
            <strong>Arène : </strong
            ><a [routerLink]="'/contests/' + slideshowImage.contest.guid">{{
              slideshowImage.contest.name
            }}</a>
          </p>
          <p *ngIf="slideshowImage.event">
            <strong>Event : </strong
            ><a [routerLink]="'/bets/events/' + slideshowImage.event.guid">{{
              slideshowImage.event.name
            }}</a>
          </p>
          <p *ngIf="slideshowImage.URL">
            <strong>URL : </strong
            ><a [href]="slideshowImage.URL">{{
              slideshowImage.URL | nullDash
            }}</a>
          </p>
          <p>
            <strong>Sport : </strong>{{ slideshowImage.sport?.name | nullDash }}
          </p>
          <p>
            <strong>Date de début : </strong
            >{{ slideshowImage.startAt | date | nullDash }}
          </p>
          <p>
            <strong>Date de fin : </strong
            >{{ slideshowImage.endAt | date | nullDash }}
          </p>
          <p>
            <strong>Activé : </strong
            >{{ slideshowImage.isEnabled | yesNo | nullDash }}
          </p>
          <p>
            <strong>Activé en mode premium : </strong
            >{{ slideshowImage.isAlsoForPremium | yesNo | nullDash }}
          </p>
          <p *ngIf="slideshowImage.visibleForContests.length > 0">
            <strong>Activé pour les arènes : </strong
            ><span
              *ngFor="
                let contest of slideshowImage.visibleForContests;
                let isLast = last
              "
              >{{ contest.name }}{{ isLast ? "" : ", " }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
