<!-- Loader -->
<mat-spinner *ngIf="!data"></mat-spinner>

<section class="main-section">
  <ng-container *ngIf="data">
    <div *ngIf="!loading; else loader" style="width: 100%">
      <div style="margin: 10px; float: left">
        <button
          matSuffix
          mat-raised-button
          color="primary"
          (click)="showGeneralNotificationModal()"
        >
          Envoyer une notification générale
        </button>
      </div>
      <div style="margin: 10px; float: left">
        <button
          matSuffix
          mat-raised-button
          color="accent"
          (click)="showGeneralNotificationModal(true)"
        >
          Envoyer une notification pour un match
        </button>
      </div>
      <div>
        <div>
          <mat-form-field style="float: right" appearance="fill">
            <mat-label>Filtrer par mode</mat-label>
            <mat-chip-list #modeChipList [multiple]="false">
              <mat-chip
                *ngIf="selectedMode"
                (removed)="
                  selectedMode = null; setData(); modeSearchInput.value = ''
                "
              >
                {{ selectedMode.title }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                placeholder="Rechercher une ligue..."
                #modeSearchInput
                [(ngModel)]="modesSearchedValue"
                [ngModelOptions]="{ standalone: true }"
                (input)="searchModes()"
                [matAutocomplete]="modeAutocomplete"
                [disabled]="selectedMode !== null"
                [hidden]="selectedMode !== null"
                [matChipInputFor]="modeChipList"
              />
            </mat-chip-list>
            <mat-autocomplete #modeAutocomplete="matAutocomplete">
              <mat-option disabled *ngIf="modesSearchLoading"
                ><mat-spinner [diameter]="25" style="margin: auto"></mat-spinner
              ></mat-option>
              <mat-option
                *ngFor="let mode of searchedModes"
                (click)="selectedMode = mode; setData()"
              >
                {{ mode.id + " - " + mode.title }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div>
        <table
          mat-table
          [dataSource]="data"
          class="mat-elevation-z8"
          *ngIf="data"
        >
          <!-- Slug -->
          <ng-container matColumnDef="slug">
            <th mat-header-cell *matHeaderCellDef>Slug</th>
            <td mat-cell *matCellDef="let element">{{ element.slug }}</td>
          </ng-container>

          <!-- Title -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Titre</th>
            <td mat-cell *matCellDef="let element">{{ element.title }}</td>
          </ng-container>

          <!-- Description -->
          <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
              {{ element.content | nullDash }}
            </td>
          </ng-container>

          <!-- isPushNotif -->
          <ng-container matColumnDef="isPushNotif">
            <th mat-header-cell *matHeaderCellDef>Notification push</th>
            <td mat-cell *matCellDef="let element">
              <app-status-chip [bool]="element.isPushNotif"> </app-status-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex; justify-content: space-evenly">
                <div
                  (click)="showNotificationModal(element.id)"
                  class="cursor_pointer"
                  matTooltip="Afficher"
                >
                  <mat-icon>visibility</mat-icon>
                </div>
                <div
                  (click)="editNotificationModal(element.id)"
                  class="cursor_pointer"
                  matTooltip="Editer"
                >
                  <mat-icon>edit</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- Pagination -->
        <mat-paginator
          showFirstLastButtons
          #paginator
          [pageSize]="30"
          [length]="totalItems"
          (page)="page = $event.pageIndex + 1; setData(false)"
        >
        </mat-paginator>
      </div>
    </div>
  </ng-container>
</section>
<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

<ng-template #smallLoader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner" diameter="50"></mat-spinner>
  </div>
</ng-template>
