<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="discount; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.code ?? "" }}
      </h1>
      <div *ngIf="!discount">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="discount">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <mat-form-field appearance="fill">
              <mat-label>Code</mat-label>
              <input
                matInput
                required
                placeholder="Code"
                formControlName="code"
              />
              <mat-error *ngIf="formGroup.controls['code']?.errors">{{
                formGroup.controls["code"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Produit</mat-label>
              <mat-select formControlName="productGuid" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option
                  *ngFor="let product of products"
                  [value]="product.value"
                >
                  {{ product.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <small
              >La réduction est indicative. Pour réellement changer le prix de
              l'achat, il faut modifier le produit.</small
            >
            <mat-form-field appearance="fill">
              <mat-label>Réduction (%)</mat-label>
              <input
                matInput
                required
                placeholder="Réduction (%)"
                formControlName="value"
                type="number"
                min="0"
                max="100"
              />
              <mat-error *ngIf="formGroup.controls['value']?.errors">{{
                formGroup.controls["value"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Nb d'utilisations max</mat-label>
              <input
                matInput
                type="number"
                placeholder="Nb d'utilisations max"
                formControlName="maxUses"
              />
              <mat-error *ngIf="formGroup.controls['maxUses']?.errors">{{
                formGroup.controls["maxUses"]?.errors?.error
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Date d'expiration</mat-label>
              <input
                matInput
                [matDatepicker]="expiresAtPicker"
                formControlName="expiresAt"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="expiresAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #expiresAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['expiresAt']?.errors">{{
                formGroup.controls["expiresAt"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="submitLoading || !discount"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
