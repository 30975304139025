<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="infoMessage; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/info_messages/' + infoMessage.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      Message d'information
    </h1>
    <div *ngIf="!infoMessage">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="infoMessage">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Contenu: </strong>{{ infoMessage.content | nullDash }}</p>
          <p>
            <strong>Date de début : </strong
            >{{ infoMessage.startAt | date: "medium" }}
          </p>
          <p>
            <strong>Date de fin : </strong
            >{{ infoMessage.endAt | date: "medium" }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
