<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="user; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/users/' + user.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ user?.username ?? "" }}
    </h1>
    <div *ngIf="!user">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="user">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Nom d'utilisateur : </strong>{{ user.username }}</p>
          <p><strong>E-mail : </strong>{{ user.email }}</p>
          <p>
            <strong>Date de naisssance : </strong>{{ user.birthdate | date }}
          </p>
          <p>
            <strong>Utilisateur demo : </strong>{{ user.isDemoUser | yesNo }}
          </p>
          <p>
            <strong>Utilisateur secret : </strong>{{ user.isSecret | yesNo }}
          </p>
        </div>
        <div style="flex: 1">
          <p>
            <strong>Nb d'arènes freemium (créateur) : </strong
            >{{ user.createdContestsFreemiumCount | nullDash }}
          </p>
          <p>
            <strong>Nb d'arènes premium (créateur) : </strong
            >{{ user.createdContestsPremiumCount | nullDash }}
          </p>
          <p>
            <strong>Nb d'arènes freemium (participant) : </strong
            >{{ user.participantContestsFreemiumCount | nullDash }}
          </p>
          <p>
            <strong>Nb d'arènes premium (participant) : </strong
            >{{ user.participantContestsPremiumCount | nullDash }}
          </p>
          <p>
            <strong>Nb de paris créés : </strong
            >{{ user.createdBetsCount | nullDash }}
          </p>
          <p>
            <strong>Nb de bonus récupérés : </strong
            >{{ user.retrievedBonusesCount | nullDash }}
          </p>
          <p *ngIf="user.lastBetAt">
            <strong>Dernier pari : </strong
            >{{ user.lastBetAt | date: "medium" }}
          </p>
          <p *ngIf="user.lastUnlockedBonusAt">
            <strong>Dernier bonus récupéré : </strong
            >{{ user.lastUnlockedBonusAt | date: "medium" }}
          </p>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div *ngIf="contestUsers.length > 0; else noContests">
        <h1 class="mat-h1" style="text-align: center">Arènes</h1>
        <div>
          <table
            mat-table
            [dataSource]="contestUsers"
            matSort
            class="mat-elevation-z8"
            *ngIf="contestUsers"
          >
            <!-- Username -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>Nom</th>
              <td mat-cell *matCellDef="let element">
                <a
                  mat-dialog-close
                  [routerLink]="['/contests', element.contest.guid]"
                  >{{ element.contest.name }}</a
                >
              </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Statut</th>
              <td mat-cell *matCellDef="let element">
                <app-status-chip
                  [color]="getChipColorFromStatus(element)"
                  [tooltip]="getChipTooltipFromStatus(element)"
                >
                </app-status-chip>
              </td>
            </ng-container>

            <!-- Balance -->
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef>Cagnotte</th>
              <td mat-cell *matCellDef="let element">
                {{ element.wallet?.balance ?? "-" }}
              </td>
            </ng-container>

            <!-- Pending -->
            <ng-container matColumnDef="pending">
              <th mat-header-cell *matHeaderCellDef>Misé</th>
              <td mat-cell *matCellDef="let element">
                {{ element.wallet?.pending ?? "-" }}
              </td>
            </ng-container>

            <!-- Total -->
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let element">
                {{ element.wallet?.balance + element.wallet?.pending ?? "-" }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

<ng-template #noContests>
  <p style="text-align: center">Aucune arène</p>
</ng-template>
