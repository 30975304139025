<section class="main-section">
  <div *ngIf="!loading; else loader" style="width: 100%">
    <div class="text-center">
      <mat-form-field appearance="fill">
        <mat-label>Rechercher un match</mat-label>
        <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
        <input
          matInput
          type="search"
          [(ngModel)]="searchedValue"
          (input)="setSearchedValue($event)"
        />
        <button
          *ngIf="searchedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchedValue = ''; this.setData(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Custom name -->
        <ng-container matColumnDef="customName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Nom personnalisé
          </th>
          <td mat-cell *matCellDef="let element">{{ element.customName }}</td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip
              [color]="getChipColorFromStatus(element)"
              [tooltip]="getChipTooltipFromStatus(element)"
            >
            </app-status-chip>
          </td>
        </ng-container>

        <ng-container matColumnDef="playedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Joué le</th>
          <td mat-cell *matCellDef="let element">
            {{ element.playedAt | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="league">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ligue</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              [routerLink]="['/bets/leagues', element.league?.guid ?? '']"
              >{{ element.league.name }}</a
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="sport">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sport</th>
          <td mat-cell *matCellDef="let element">{{ element.sport.name }}</td>
        </ng-container>

        <!-- isFeatured -->
        <ng-container matColumnDef="isFeatured">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>En avant</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip [bool]="element.isFeatured"></app-status-chip>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showEventModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="setAsFeatured(element)"
                class="cursor_pointer"
                matTooltip="Mettre en avant"
              >
                <mat-icon>{{
                  element.isFeatured ? "star" : "star_outline"
                }}</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        showFirstLastButtons
        #paginator
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
