import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Competitor } from 'src/entities/Competitor';
import { CompetitorService } from 'src/services/competitor.service';
import { NavigationService } from 'src/services/navigation.service';
import { ShowCompetitorModalComponent } from './show-competitor-modal/show-competitor-modal.component';

@Component({
  selector: 'app-competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.css'],
})
export class CompetitorsComponent implements OnInit, OnDestroy {
  data: Competitor[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['id', 'name', 'country', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'desc',
  };
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;

  constructor(
    private competitorService: CompetitorService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Compétiteurs');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowCompetitorModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.competitorService
      .getCompetitors(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a competitor in the data by id
   */
  getCompetitorById(id: number): Competitor | null {
    const index: number = this.data.findIndex(
      (competitor: Competitor) => competitor.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(competitor: any): void {
    // Clear the order if no header is sorted
    if (competitor.active === '' || competitor.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: competitor.active,
        direction: competitor.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(competitor: any): void {
    this.searchedValue = (competitor.target as HTMLTextAreaElement).value;
    this.setData(false);
  }

  /**
   * Show the show competitor modal
   */
  showCompetitorModal(id: number): void {
    const competitor: Competitor | null = this.getCompetitorById(id);
    if (competitor) {
      this.dialog.open(ShowCompetitorModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: competitor.guid,
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }
}
