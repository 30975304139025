import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'src/services/payment.service';
import { Discount } from 'src/entities/Discount';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { Event, EventStatus, getNameFromEventStatus } from 'src/entities/Event';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-discount-modal',
  templateUrl: './show-discount-modal.component.html',
  styleUrls: ['./show-discount-modal.component.css'],
})
export class ShowDiscountModalComponent implements OnInit {
  discount: Discount | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.paymentService
        .getDiscount(this.data.guid)
        .subscribe((discount: Discount) => {
          this.discount = discount;
        });
      // Change url
      this.location.replaceState(`/payments/discounts/${this.data.guid}`);
    }
  }
}
