<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="contest; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/contests/' + contest.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ contest?.name ?? "" }}
    </h1>
    <div *ngIf="!contest">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="contest">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Nom : </strong>{{ contest.name }}</p>
          <p><strong>Statut : </strong>{{ getNameFromContestStatus() }}</p>
          <p>
            <strong>Public : </strong
            >{{ contest.isSponsored | nullDash | yesNo }}
          </p>
          <p>
            <strong>Premium : </strong
            >{{ contest.isPremium | nullDash | yesNo }}
          </p>
          <p *ngIf="!contest.isSponsored">
            <strong>Acceptation automatique : </strong
            >{{ contest.hasAutoAcceptation | yesNo }}
          </p>
          <p><strong>Code : </strong>{{ contest.code | nullDash }}</p>
          <p>
            <strong>Mode : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/modes', contest.mode?.guid]"
              >{{ contest.mode?.title }}</a
            >
          </p>
          <p>
            <strong>Règle : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/variants', contest.variant?.guid]"
              >{{ contest.variant?.title }}</a
            >
          </p>
          <p>
            <strong>Description : </strong>{{ contest.description | nullDash }}
          </p>

          <app-image-manager
            *ngIf="contest.isSponsored && contest.image"
            [image]="contest.image"
            [width]="300"
            [height]="166"
            [recommendedHeight]="500"
            [recommendedWidth]="890"
          ></app-image-manager>
        </div>
        <div style="flex: 1">
          <p>
            <strong>Créateur : </strong
            ><a
              mat-dialog-close
              [routerLink]="['/users', contest.creator.guid]"
              >{{ contest.creator?.username | nullDash }}</a
            >
          </p>
          <p>
            <strong>Date de création : </strong>{{ contest.createdAt | date }}
          </p>
          <p><strong>Date de fin : </strong>{{ contest.endAt | date }}</p>
          <p>
            <strong>Nb de participants : </strong
            >{{ contest.userCount | number }} ({{ contest.pendingUserCount }} en
            attente)
          </p>
          <p>
            <strong>Nb de paris effectués : </strong
            >{{ contest.createdBetsCount | number }}
          </p>
          <p>
            <strong>Nb de bonus débloqués : </strong
            >{{ contest.retrievedBonusesCount | number }}
          </p>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Joueurs : </strong></p>

          <table
            mat-table
            [dataSource]="contestUsers"
            matSort
            class="mat-elevation-z8"
            style="width: 90%"
            *ngIf="contestUsers"
          >
            <!-- Username -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>Pseudo</th>
              <td mat-cell *matCellDef="let element">
                <a
                  mat-dialog-close
                  [routerLink]="['/users', element.user.guid]"
                  >{{ element.user.username }}</a
                >
              </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Statut</th>
              <td mat-cell *matCellDef="let element">
                <app-status-chip
                  [color]="getChipColorFromStatus(element)"
                  [tooltip]="getChipTooltipFromStatus(element)"
                >
                </app-status-chip>
              </td>
            </ng-container>

            <!-- Balance -->
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef>Cagnotte</th>
              <td mat-cell *matCellDef="let element">
                {{ element.wallet?.balance | nullDash }}
              </td>
            </ng-container>

            <!-- Pending -->
            <ng-container matColumnDef="pending">
              <th mat-header-cell *matHeaderCellDef>Misé</th>
              <td mat-cell *matCellDef="let element">
                {{ element.wallet?.pending | nullDash }}
              </td>
            </ng-container>

            <!-- Total -->
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let element">
                {{
                  element.wallet?.balance + element.wallet?.pending | nullDash
                }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
