import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { Contest, getNameFromContestStatus } from 'src/entities/Contest';
import { ContestUser, ContestUserStatus } from 'src/entities/ContestUser';
import { ContestService } from 'src/services/contest.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-contest-modal',
  templateUrl: './show-contest-modal.component.html',
  styleUrls: ['./show-contest-modal.component.css'],
})
export class ShowContestModalComponent implements OnInit {
  contest: Contest | null = null;
  contestUsers: ContestUser[] = [];

  displayedColumns: string[] = [
    'username',
    'status',
    'balance',
    'pending',
    'total',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contestService: ContestService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.contestService
        .getContest(this.data.guid)
        .subscribe((contest: Contest) => {
          this.contest = contest;
          this.contestUsers = contest.contestUsers['hydra:member'];
        });

      // Change url
      this.location.replaceState(`/contests/${this.data?.guid}`);
    }
  }

  getChipColorFromStatus(contestUser: ContestUser): ChipColor {
    if (contestUser.isCreator) return ChipColor.BLUE;

    switch (contestUser.status) {
      case ContestUserStatus.STATUS_PENDING:
        return ChipColor.ORANGE;
      case ContestUserStatus.STATUS_JOINED:
        return ChipColor.GREEN;
    }
  }

  getNameFromContestStatus(): string {
    if (typeof this.contest?.status !== 'undefined')
      return getNameFromContestStatus(this.contest.status);

    return '-';
  }

  getChipTooltipFromStatus(contestUser: ContestUser): string {
    if (contestUser.isCreator) return 'Créateur';

    switch (contestUser.status) {
      case ContestUserStatus.STATUS_PENDING:
        return 'En attente';
      case ContestUserStatus.STATUS_JOINED:
        return 'Participant';
    }
  }
}
