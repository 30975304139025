import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { Bonus } from './Bonus';
import { TicketType } from './ContestBonusBetUser';

export interface ModeBonus {
  mode: Mode;
  bonus: Bonus;
  defaultCount: number;
  isEditable: boolean;
  probability: number;
}

export class Mode {
  id: number | null = null;
  guid: string | null = null;
  title: string | null = null;
  slug: string | null = null;
  description: string | null = null;
  startAt: Date | null = null;
  endAt: Date | null = null;
  isDisabled: boolean | null = true;
  isForFreemium: boolean | null = false;
  isForPremium: boolean | null = false;
  isAssiduous: boolean | null = true;
  doesDefaultBonusesNeedRetrieval: boolean | null = false;
  autoRetrieveExcessBonuses: boolean | null = false;
  modeBonuses: any = [];
  leagues: any = [];
  sports: any = [];

  primaryColor: string | null = null;
  secondaryColor: string | null = null;
  tertiaryColor: string | null = null;
  textColor: string | null = null;

  isConfigurable: boolean = false;
  defaultWallet: number = 10000;
  isDefaultWalletEditable: boolean = false;
  dailyIncome: number = 0;
  isDailyIncomeEditable: boolean = false;
  dailyBonuses: number = 5;
  areDailyBonusesEditable: boolean = false;

  oddzeusImage: any | null = null;
  dashboardImage: any | null = null;
  dashboardImageDisabled: any | null = null;
  battlefieldImage: any | null = null;
  battlefieldImageDisabled: any | null = null;
  bonusesImage: any | null = null;
  bonusesImageDisabled: any | null = null;
  rankingsImage: any | null = null;
  rankingsImageDisabled: any | null = null;
  prizesImage: any | null = null;
  prizesImageDisabled: any | null = null;

  moneyShort: string | null = null;
  moneyLong: string | null = null;
  moneyShortSuffix: string | null = null;
  oddzeus: string | null = null;

  bonusesParagraph: string | null = null;
  dashboardTitle: string | null = null;
  battlefieldTitle: string | null = null;
  eventsTitle: string | null = null;
  myBetsTitle: string | null = null;
  ticketTitle: string | null = null;
  rankingsTitle: string | null = null;
  trophiesTitle: string | null = null;
  halfGodsTitle: string | null = null;
  heroesTitle: string | null = null;
  legendsTitle: string | null = null;
  stadiumsTitle: string | null = null;

  bonusesWidgetTitle: string | null = null;
  rankingsWidgetTitle: string | null = null;
  betsWidgetTitle: string | null = null;
  contractWidgetTitle: string | null = null;
  trophiesWidgetTitle: string | null = null;

  boosterTitle: string | null = null;
  boosterPresentation: string | null = null;
  boosterDescription: string | null = null;
  insuranceTitle: string | null = null;
  insurancePresentation: string | null = null;
  insuranceDescription: string | null = null;
  zeurosTitle: string | null = null;
  zeurosPresentation: string | null = null;
  zeurosDescription: string | null = null;

  maxInitialBet: number = 100;

  defaultTicketType: TicketType = TicketType.TICKET_COMBINATED;
  translations: any;

  customStadiumsTrophies: any = [];
  customHeroesTrophies: any = [];
  customLegendsTrophies: any = [];
  customHalfGodsTrophies: any = [];
}
