import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlideshowImage } from 'src/entities/SlideshowImage';
import { SlideshowImageService } from 'src/services/slideshow-image.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-slideshow-image-modal',
  templateUrl: './show-slideshow-image-modal.component.html',
  styleUrls: ['./show-slideshow-image-modal.component.css'],
})
export class ShowSlideshowImageModalComponent implements OnInit {
  slideshowImage: SlideshowImage | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private slideshowImageService: SlideshowImageService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.slideshowImageService
        .getSlideshowImage(this.data.guid)
        .subscribe((slideshowImage: SlideshowImage) => {
          this.slideshowImage = slideshowImage;
        });
      // Change url
      this.location.replaceState(`/ads/slideshow_images/${this.data.guid}`);
    }
  }
}
