import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Prize } from 'src/entities/Prize';
import { PrizeService } from 'src/services/prize.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-prize-modal',
  templateUrl: './show-prize-modal.component.html',
  styleUrls: ['./show-prize-modal.component.css'],
})
export class ShowPrizeModalComponent implements OnInit {
  prize: Prize | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private prizeService: PrizeService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.prizeService.getPrize(this.data.guid).subscribe((prize: Prize) => {
        this.prize = prize;
      });
    }
  }
}
