import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from 'src/services/event.service';
import { Event, getNameFromEventStatus } from 'src/entities/Event';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import {
  getChipColorFromResult,
  getNameFromOddResult,
  Odd,
  OddResult,
} from 'src/entities/Odd';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-event-modal',
  templateUrl: './show-event-modal.component.html',
  styleUrls: ['./show-event-modal.component.css'],
})
export class ShowEventModalComponent implements OnInit {
  event: Event | null = null;
  odds: Odd[] = [];

  displayedColumns: string[] = [
    'name',
    'header',
    'value',
    'type',
    'result',
    'updatedAt',
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.eventService.getEvent(this.data.guid).subscribe((event: Event) => {
        this.event = event;
        this.odds = event.odds;
      });
      // Change url
      this.location.replaceState(`/bets/events/${this.data.guid}`);
    }
  }

  getNameFromEventStatus(status: { name: string }): string {
    return getNameFromEventStatus(status);
  }

  getChipColorFromResult(odd: Odd): ChipColor {
    return getChipColorFromResult(odd.result);
  }

  getChipTooltipFromResult(odd: Odd): string {
    return getNameFromOddResult(odd.result);
  }
}
