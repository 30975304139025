import { Product } from './Product';

export class Discount {
  readonly id: number | null = null;
  readonly guid: string | null = null;
  code: string | null = null;
  value: number | null = null;
  maxUses: number | null = null;
  readonly currentUses: number | null = null;
  expiresAt: Date | null = null;
  product: Product | null = null;
  readonly hasExpired: boolean | null = null;
}
