import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/entities/User';
import { UserService } from 'src/services/user.service';
import { UnbanUserModalComponent } from './unban-user-modal/unban-user-modal.component';
import { BanUserModalComponent } from './ban-user-modal/ban-user-modal.component';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { ShowUserModalComponent } from './show-user-modal/show-user-modal.component';
import { APIService } from 'src/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit, OnDestroy {
  data: User[] = [];
  loading: boolean = true;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'username',
    'email',
    'createdAt',
    'isLocked',
    'actions',
  ];
  currentUserGuid: string | null = null;
  totalItems: number = 0;
  page: number = 1;

  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private userService: UserService,
    private apiService: APIService,
    private navigationService: NavigationService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Utilisateurs');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowUserModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();

    this.currentUserGuid = this.apiService.getCurrentUserGuid();

    let guid: string = '';
    if (guid) {
      this.dialog.open(ShowUserModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: guid,
        },
      });
    }
  }

  /**
   * Show the show user modal
   */
  showUserModal(id: number): void {
    const user: User | null = this.getUserById(id);
    if (user) {
      this.dialog.open(ShowUserModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: user.guid,
        },
      });
    }
  }

  /**
   * Show the ban user modal
   */
  banUserModal(id: number): void {
    const user: User | null = this.getUserById(id);
    if (user) {
      const dialogRef = this.dialog.open(
        user.isLocked ? UnbanUserModalComponent : BanUserModalComponent
      );
      dialogRef.afterClosed().subscribe((result) => {
        // Ban or unban the user
        if (result) {
          user.isLocked ? this.unbanUser(id) : this.banUser(id);
        }
      });
    }
  }

  /**
   * Show the delete user modal
   */
  deleteUserModal(id: number): void {
    const user: User | null = this.getUserById(id);
    if (user) {
      const dialogRef = this.dialog.open(DeleteUserModalComponent);
      dialogRef.afterClosed().subscribe((result) => {
        // Ban the user
        if (result) this.deleteUser(id);
      });
    }
  }

  /**
   * Delete a user
   */
  deleteUser(id: number): void {
    const index: number = this.data.findIndex((user: User) => user.id === id);
    if (index !== -1) {
      const user: User = this.data[index];
      this.userService.deleteUser(user.guid).subscribe(() => {
        // Clone the array and splice to refresh the display
        this.data.splice(index, 1);
        this.data = [...this.data];
      });
    }
  }

  /**
   * Ban a user
   */
  banUser(id: number): void {
    const index: number = this.data.findIndex((user: User) => user.id === id);
    if (index !== -1) {
      const user: User = this.data[index];

      this.userService.banUser(user.guid).subscribe((_user: User) => {
        // Clone the array and update the user to refresh the display
        this.data[index] = _user;
        this.data = [...this.data];
      });
    }
  }

  /**
   * Unban a user
   */
  unbanUser(id: number): void {
    const index: number = this.data.findIndex((user: User) => user.id === id);
    if (index !== -1) {
      const user: User = this.data[index];

      this.userService.unbanUser(user.guid).subscribe((_user: User) => {
        // Clone the array and update the user to refresh the display
        this.data[index] = _user;
        this.data = [...this.data];
      });
    }
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.userService
      .getUsers(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Find a user in the data by id
   */
  getUserById(id: number): User | null {
    const index: number = this.data.findIndex((user: User) => user.id === id);
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['username'] = this.searchedValue;

    return params;
  }

  setSearchedValue(event: Event): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }
}
