import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InfoMessage } from 'src/entities/InfoMessage';
import { APIService } from './api.service';

@Injectable()
export class InfoMessageService {
  constructor(private apiService: APIService) {}

  public getInfoMessages(params: any = null): Observable<InfoMessage[]> {
    return this.apiService.get(`/info_messages`, params, true);
  }
  public getInfoMessage(guid: string): Observable<InfoMessage> {
    return this.apiService.get(`/info_messages/${guid}`);
  }
  public putInfoMessage(
    guid: string,
    data: Partial<InfoMessage>
  ): Observable<InfoMessage> {
    return this.apiService.put(`/info_messages/${guid}`, data);
  }
  public createInfoMessage(
    data: Partial<InfoMessage>
  ): Observable<InfoMessage> {
    return this.apiService.post(`/info_messages`, data);
  }
  public deleteInfoMessage(guid: string): Observable<void> {
    return this.apiService.delete(`/info_messages/${guid}`);
  }
}
