import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Mode } from 'src/entities/Mode';
import { LanguageService } from 'src/services/language.service';
import { VariantService } from 'src/services/variant.service';
import { NavigationService } from 'src/services/navigation.service';
import { EditVariantModalComponent } from './edit-variant-modal/edit-variant-modal.component';
import { ShowVariantModalComponent } from './show-variant-modal/show-variant-modal.component';
import { ModeVariant } from 'src/entities/ModeVariant';

@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.css'],
})
export class VariantsComponent implements OnInit, OnDestroy {
  data: ModeVariant[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['id', 'title', 'isEnabled', 'mode', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  loadSubscription: any = null;
  languageSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private variantService: VariantService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Règles');
    // Show variant if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowVariantModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();

    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  /**
   * Show the show variant modal
   */
  showVariantModal(id: number): void {
    const variant: ModeVariant | null = this.getVariantById(id);

    if (variant) {
      this.dialog.open(ShowVariantModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: variant.guid,
        },
      });
    }
  }

  /**
   * Show the edit variant modal
   */
  editVariantModal(id: number | null = null): void {
    let variant: ModeVariant | null = null;

    if (id) {
      variant = this.getVariantById(id);
      if (!variant) return;
    } else {
      variant = new ModeVariant();
    }

    if (variant) {
      const dialogRef = this.dialog.open(EditVariantModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: variant.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.variantService
      .getVariants(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['translations.title'] = this.searchedValue;

    return params;
  }

  /**
   * Find a variant in the data by id
   */
  getVariantById(id: number): ModeVariant | null {
    const index: number = this.data.findIndex(
      (variant: ModeVariant) => variant.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  /**
   * Search a variant by title
   */
  setSearchedValue(event: Event): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
}
