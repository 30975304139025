import { Bonus } from './Bonus';
import { Sport } from './Sport';

export enum TrophyCategoryEnum {
  CATEGORY_ARENAS = 'arenas',
  CATEGORY_HALF_GODS = 'half_gods',
  CATEGORY_HEROES = 'heroes',
  CATEGORY_LEGENDS = 'legends',
}
export class TrophyCategory {
  readonly guid: string = '';
  slug: string | null = null;
  static getNameFromSlug(slug: string): string {
    switch (slug) {
      case TrophyCategoryEnum.CATEGORY_ARENAS:
        return 'Arènes';
      case TrophyCategoryEnum.CATEGORY_HALF_GODS:
        return 'Demi-dieux';
      case TrophyCategoryEnum.CATEGORY_HEROES:
        return 'Héros';
      case TrophyCategoryEnum.CATEGORY_LEGENDS:
        return 'Légendes';
      default:
        return '';
    }
  }
}

export class TrophyProgressionBetWon {
  guid: string | null = null;
  value: number = 10;
}

export class TrophyProgressionOdd {
  guid: string | null = null;
  minOdd: number = 1;
  maxOdd: number = 10;
  value: number = 1;
}
export class TrophyReward {
  guid: string | null = null;
  rewards: Bonus[] = [];
}

export class Trophy {
  id: number | null = null;
  guid: number | null = null;
  name: string | null = null;
  image: any | null = null;
  translations: any | null = null;
  isEnabled: boolean = false;
  category: TrophyCategory | null = null;
  sports: Sport[] = [];
  trophyProgressionBetWon: TrophyProgressionBetWon | null = null;
  trophyProgressionOdd: TrophyProgressionOdd | null = null;
  trophyReward: TrophyReward | null = new TrophyReward();
}
