import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Prize } from 'src/entities/Prize';
import { LanguageService } from 'src/services/language.service';
import { PrizeService } from 'src/services/prize.service';

@Component({
  selector: 'app-edit-prize-modal',
  templateUrl: './edit-prize-modal.component.html',
  styleUrls: ['./edit-prize-modal.component.css'],
})
export class EditPrizeModalComponent implements OnInit {
  prize: Prize | null = null;
  submitLoading: boolean = false;
  imageUploadLoading: boolean = false;
  errors: string | null = null;
  loading: boolean = true;

  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    content: new FormControl(null, [Validators.required]),
    position: new FormControl(null, [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private prizeService: PrizeService,
    private dialogRef: MatDialogRef<EditPrizeModalComponent>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve prize and init form
      this.prizeService.getPrize(this.data.guid).subscribe((prize: Prize) => {
        this.prize = prize;
        this.createPrizeFormGroup();
        this.loading = false;
      });
    } else {
      this.prize = new Prize();
      this.createPrizeFormGroup();
      this.loading = false;
    }
  }

  imageChanged(event: any) {
    if (this.prize) {
      if (event === null) {
        this.prize.image = null;
      } else {
        this.prize.image = event['@id'] ?? null;
      }
    }
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);
    data.image = this.prize?.image;
    if (data.image?.guid) data.image = `/s/media_objects/${data.image.guid}`;

    data = this.createTranslations(data);

    data.contest = `/s/contests/${this.data.contestGuid}`;
    this.errors = null;

    // POST / PUT Prize
    let request: Observable<Prize> = this.data.guid
      ? this.prizeService.putPrize(this.data.guid, data)
      : this.prizeService.createPrize(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error?.violations?.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Create the prize form group
   */
  createPrizeFormGroup(): void {
    if (this.prize) {
      const prize = this.prize;

      this.formGroup.setValue({
        title: prize.title,
        content: prize.content,
        position: prize.position,
      });
    }
  }

  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      title: data.title === '' ? null : data.title,
      content: data.content === '' ? null : data.content,
    };

    delete data.title;
    delete data.content;

    // Retrieve all translations and set the new one
    data.translations = this.prize?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }
}
