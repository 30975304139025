<!-- Loader -->
<mat-spinner *ngIf="!data"></mat-spinner>

<section class="main-section">
  <ng-container *ngIf="data">
    <div *ngIf="!loading; else loader" style="width: 100%">
      <div style="margin: 10px; float: right">
        <button
          matSuffix
          mat-raised-button
          color="primary"
          (click)="editCampainModal()"
        >
          Nouvelle campagne
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- startAt -->
        <ng-container matColumnDef="startAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Début</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startAt | date: "medium" }}
          </td>
        </ng-container>

        <!-- endAt -->
        <ng-container matColumnDef="endAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Fin</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endAt | date: "medium" }}
          </td>
        </ng-container>

        <!-- views -->
        <ng-container matColumnDef="views">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Limite de vues
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.views ?? "Illimité" }}
          </td>
        </ng-container>

        <!-- views -->
        <ng-container matColumnDef="currentViews">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vues</th>
          <td mat-cell *matCellDef="let element">
            {{ element.currentViewCount }}
          </td>
        </ng-container>

        <!-- isEnabled -->
        <ng-container matColumnDef="isEnabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activée</th>

          <td mat-cell *matCellDef="let element">
            <app-status-chip [bool]="element.isEnabled"></app-status-chip>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showCampainModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="editCampainModal(element.id)"
                class="cursor_pointer"
                matTooltip="Editer"
              >
                <mat-icon>edit</mat-icon>
              </div>
              <div
                (click)="deleteCampainModal(element.id)"
                class="cursor_pointer"
                matTooltip="Supprimer"
                style="color: red"
              >
                <mat-icon>delete</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        #paginator
        showFirstLastButtons
        [pageSize]="30"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </ng-container>
</section>
<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
