import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bonus, BonusOperatorType, BonusType } from 'src/entities/Bonus';
import { BonusService } from 'src/services/bonus.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-bonus-modal',
  templateUrl: './show-bonus-modal.component.html',
  styleUrls: ['./show-bonus-modal.component.css'],
})
export class ShowBonusModalComponent implements OnInit {
  bonus: Bonus | null = null;

  operators: { name: string; value: BonusOperatorType }[] = [
    { name: 'Pourcentage', value: BonusOperatorType.PERCENTAGE },
    { name: 'Puissance', value: BonusOperatorType.POWER },
  ];
  types: { name: string; value: BonusType }[] = [
    { name: 'Booster', value: BonusType.BOOSTER },
    { name: 'Assurance', value: BonusType.INSURANCE },
    { name: 'Zeuros', value: BonusType.ZEUROS },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bonusService: BonusService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.bonusService.getBonus(this.data.guid).subscribe((bonus: Bonus) => {
        this.bonus = bonus;
      });
      // Change url
      this.location.replaceState(`/bonuses/${this.data?.guid}`);
    }
  }

  getOperatorName(value: BonusOperatorType) {
    const index: number = this.operators.findIndex(
      (operator: { name: string; value: BonusOperatorType }) =>
        value === operator.value
    );
    return index === -1 ? 'Zeuros' : this.operators[index].name;
  }
  getTypeName(value: BonusType) {
    const index: number = this.types.findIndex(
      (type: { name: string; value: BonusType }) => value === type.value
    );
    return index === -1 ? value : this.types[index].name;
  }
}
