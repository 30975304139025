import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Base64Image } from 'src/entities/MediaObject';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiService: APIService, private http: HttpClient) {}

  public getImageAsBase64(imageGuid: string): Observable<Base64Image> {
    return this.apiService.get(`/image_as_base64/${imageGuid}`);
  }

  public createMediaObject(data: any): Observable<any> {
    return this.apiService.post('/media_objects', data, true, true);
  }
}
