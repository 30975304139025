import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getTypeAsString, Product, ProductType } from 'src/entities/Product';
import { PaymentService } from 'src/services/payment.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-product-modal',
  templateUrl: './edit-product-modal.component.html',
  styleUrls: ['./edit-product-modal.component.css'],
})
export class EditProductModalComponent implements OnInit {
  product: Product | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    androidStoreName: new FormControl(null, Validators.required),
    iosStoreName: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    isDefaultProduct: new FormControl(null, Validators.required),
  });
  errors: string | null = null;

  types: { name: string; value: ProductType }[] = [
    {
      name: getTypeAsString(ProductType.TYPE_PREMIUM_CONTEST_PASS),
      value: ProductType.TYPE_PREMIUM_CONTEST_PASS,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private dialogRef: MatDialogRef<EditProductModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.paymentService
        .getProduct(this.data.guid)
        .subscribe((product: Product) => {
          this.product = product;
          this.createFormGroup();
        });
    } else {
      this.product = new Product();
      this.createFormGroup();
    }
  }

  createFormGroup(): void {
    if (this.product) {
      this.formGroup.setValue({
        androidStoreName: this.product.androidStoreName,
        iosStoreName: this.product.iosStoreName,
        isDefaultProduct: this.product.isDefaultProduct,
        type: this.product.type,
      });
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    let data: Partial<Product> = this.formGroup.value;
    this.errors = null;

    let request = this.data.guid
      ? this.paymentService.putProduct(this.data.guid, data)
      : this.paymentService.createProduct(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      .add(() => {
        this.submitLoading = false;
      });
  }
}
