<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="product; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/payments/products/' + product.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ product?.androidStoreName ?? "" }} / {{ product?.iosStoreName ?? "" }}
    </h1>
    <div *ngIf="!product">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="product">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p>
            <strong>Id sur le store Android: </strong
            >{{ product.androidStoreName }}
          </p>
          <p>
            <strong>Id sur le store iOS: </strong>{{ product.iosStoreName }}
          </p>
          <p><strong>Type : </strong>{{ getTypeAsString(product.type) }}</p>
          <p>
            <strong>Produit par défaut pour ce type : </strong
            >{{ product.isDefaultProduct | yesNo }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
