import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Bonus, BonusType, getNameFromBonusType } from 'src/entities/Bonus';
import { League } from 'src/entities/League';
import { Mode, ModeBonus } from 'src/entities/Mode';
import { Sport } from 'src/entities/Sport';
import { AppLanguages, LanguageService } from 'src/services/language.service';
import { BonusService } from 'src/services/bonus.service';
import { LeagueService } from 'src/services/league.service';
import { ModeService } from 'src/services/mode.service';
import { SportService } from 'src/services/sport.service';
import {
  Trophy,
  TrophyCategory,
  TrophyCategoryEnum,
} from 'src/entities/Trophy';
import { TrophyService } from 'src/services/trophy.service';

export type BonusGroup = {
  name: string;
  type: BonusType;
  bonuses: Bonus[];
};

enum ModeImages {
  DASHBOARD = 'dashboard',
  DASHBOARD_DISABLED = 'dashboardDisabled',
  BATTLEFIELD = 'battlefield',
  BATTLEFIELD_DISABLED = 'battlefieldDisabled',
  BONUSES = 'bonuses',
  BONUSES_DISABLED = 'bonusesDisabled',
  RANKINGS = 'rankings',
  RANKINGS_DISABLED = 'rankingsDisabled',
  TROPHIES = 'trophies',
  TROPHIES_DISABLED = 'trophiesDisabled',
  ODDZEUS = 'oddzeus',
}

@Component({
  selector: 'app-edit-mode-modal',
  templateUrl: './edit-mode-modal.component.html',
  styleUrls: ['./edit-mode-modal.component.css'],
})
export class EditModeModalComponent implements OnInit {
  mode: Mode | null = null;
  submitLoading: boolean = false;
  errors: string | null = null;
  bonuses: Bonus[] = [];
  bonusesGroups: BonusGroup[] = [];
  loading: boolean = true;

  leagueSearchTerm: string = '';
  sportSearchTerm: string = '';
  trophyStadiumSearchTerm: string = '';
  trophyHeroesSearchTerm: string = '';
  trophyLegendsSearchTerm: string = '';
  trophyHalfGodsSearchTerm: string = '';

  searchedLeagues: League[] = [];
  searchedSports: Sport[] = [];
  searchedTrophyStadium: Trophy[] = [];
  searchedTrophyHalfGods: Trophy[] = [];
  searchedTrophyHeroes: Trophy[] = [];
  searchedTrophyLegends: Trophy[] = [];

  leagueSearchLoading: boolean = false;
  sportSearchLoading: boolean = false;
  trophyStadiumSearchLoading: boolean = false;
  trophyLegendsSearchLoading: boolean = false;
  trophyHeroesSearchLoading: boolean = false;
  trophyHalfGodsSearchLoading: boolean = false;

  /*  Images */
  oddzeusImageUploadLoading: boolean = false;
  oddzeusDisabledImageUploadLoading: boolean = false;
  dashboardImageUploadLoading: boolean = false;
  dashboardDisabledImageUploadLoading: boolean = false;
  battlefieldImageUploadLoading: boolean = false;
  battlefieldDisabledImageUploadLoading: boolean = false;
  bonusesImageUploadLoading: boolean = false;
  bonusesDisabledImageUploadLoading: boolean = false;
  rankingsImageUploadLoading: boolean = false;
  rankingsDisabledImageUploadLoading: boolean = false;
  trophiesImageUploadLoading: boolean = false;
  trophiesDisabledImageUploadLoading: boolean = false;

  oddzeusUploadLoading: boolean = false;

  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    slug: new FormControl(null, [Validators.required]),
    enabled: new FormControl(false),
    isForFreemium: new FormControl(false),
    isForPremium: new FormControl(false),
    doesDefaultBonusesNeedRetrieval: new FormControl(false),
    autoRetrieveExcessBonuses: new FormControl(false),
    isAssiduous: new FormControl(true),
    primaryColor: new FormControl(),
    secondaryColor: new FormControl(),
    tertiaryColor: new FormControl(),
    textColor: new FormControl(),

    startAt: new FormControl(),
    endAt: new FormControl(),
    oddzeus: new FormControl(),
    moneyShort: new FormControl(),
    moneyLong: new FormControl(),
    moneyShortSuffix: new FormControl(),

    dashboardTitle: new FormControl(),
    battlefieldTitle: new FormControl(),
    eventsTitle: new FormControl(),
    myBetsTitle: new FormControl(),
    ticketTitle: new FormControl(),
    rankingsTitle: new FormControl(),
    trophiesTitle: new FormControl(),
    halfGodsTitle: new FormControl(),
    heroesTitle: new FormControl(),
    legendsTitle: new FormControl(),
    stadiumsTitle: new FormControl(),

    bonusesWidgetTitle: new FormControl(),
    rankingsWidgetTitle: new FormControl(),
    betsWidgetTitle: new FormControl(),
    contractWidgetTitle: new FormControl(),
    trophiesWidgetTitle: new FormControl(),

    isConfigurable: new FormControl(false),
    defaultWallet: new FormControl(0, Validators.required),
    isDefaultWalletEditable: new FormControl(false),
    dailyIncome: new FormControl(0, Validators.required),
    isDailyIncomeEditable: new FormControl(false),
    dailyBonuses: new FormControl(0, Validators.required),
    areDailyBonusesEditable: new FormControl(false),

    bonusesParagraph: new FormControl(),
    boosterTitle: new FormControl(),
    boosterPresentation: new FormControl(),
    boosterDescription: new FormControl(),
    insuranceTitle: new FormControl(),
    insurancePresentation: new FormControl(),
    insuranceDescription: new FormControl(),
    zeurosTitle: new FormControl(),
    zeurosPresentation: new FormControl(),
    zeurosDescription: new FormControl(),
    modeBonuses: new FormArray([]),

    maxInitialBet: new FormControl(),
    defaultTicketType: new FormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modeService: ModeService,
    private bonusService: BonusService,
    private dialogRef: MatDialogRef<EditModeModalComponent>,
    private languageService: LanguageService,
    private leagueService: LeagueService,
    private sportService: SportService,
    private trophyService: TrophyService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve mode and init form
      this.modeService.getMode(this.data.guid).subscribe((mode: Mode) => {
        this.mode = mode;
        this.createModeFormGroup();
        this.createModeBonusesFormGroup();
      });
    } else {
      this.mode = new Mode();
      this.createModeFormGroup();
      this.createModeBonusesFormGroup();
    }

    // Retrieve all bonuses and class them in groups
    let params: any = [];
    params['itemsPerPage'] = 40;
    this.bonusService.getBonuses(params).subscribe((bonuses: any) => {
      this.bonuses = bonuses['hydra:member'];
      this.groupBonuses(bonuses['hydra:member']);
    });
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);
    // Format fields
    data.isDisabled = !data.enabled;
    delete data.enabled;

    if (data.startAt) {
      data.startAt = new Date(data.startAt);
    }

    if (data.endAt) {
      data.endAt = new Date(data.endAt);
      // Set the end date to the end of the day
      data.endAt.setUTCHours(23, 59, 59, 999);
    }

    // Set the correct bonuses IRIs
    data.modeBonuses
      .filter((modeBonus: any) => modeBonus.selectedBonusGuid !== null)
      .forEach((modeBonus: any) => {
        modeBonus.bonus = '/s/bonuses/' + modeBonus.selectedBonusGuid;
        delete modeBonus.selectedBonusGuid;
      });

    // Transform sports, leagues and trophies entities to IRIs
    data.sports = [...this.mode?.sports].map(
      (sport: Sport) => `/s/sports/${sport.guid}`
    );
    data.leagues = [...this.mode?.leagues].map(
      (league: League) => `/s/leagues/${league.guid}`
    );
    data.customStadiumsTrophies = [...this.mode?.customStadiumsTrophies].map(
      (trophy: Trophy) => `/s/trophies/${trophy.guid}`
    );
    data.customHeroesTrophies = [...this.mode?.customHeroesTrophies].map(
      (trophy: Trophy) => `/s/trophies/${trophy.guid}`
    );
    data.customHalfGodsTrophies = [...this.mode?.customHalfGodsTrophies].map(
      (trophy: Trophy) => `/s/trophies/${trophy.guid}`
    );
    data.customLegendsTrophies = [...this.mode?.customLegendsTrophies].map(
      (trophy: Trophy) => `/s/trophies/${trophy.guid}`
    );

    // Transform images to IRIs
    data.dashboardImage = this.mode?.dashboardImage?.guid
      ? '/s/media_objects/' + this.mode?.dashboardImage.guid
      : null;
    data.dashboardImageDisabled = this.mode?.dashboardImageDisabled?.guid
      ? '/s/media_objects/' + this.mode?.dashboardImageDisabled.guid
      : null;
    data.battlefieldImage = this.mode?.battlefieldImage?.guid
      ? '/s/media_objects/' + this.mode?.battlefieldImage.guid
      : null;
    data.battlefieldImageDisabled = this.mode?.battlefieldImageDisabled?.guid
      ? '/s/media_objects/' + this.mode?.battlefieldImageDisabled.guid
      : null;
    data.bonusesImage = this.mode?.bonusesImage?.guid
      ? '/s/media_objects/' + this.mode?.bonusesImage.guid
      : null;
    data.bonusesImageDisabled = this.mode?.bonusesImageDisabled?.guid
      ? '/s/media_objects/' + this.mode?.bonusesImageDisabled.guid
      : null;
    data.rankingsImage = this.mode?.rankingsImage?.guid
      ? '/s/media_objects/' + this.mode?.rankingsImage.guid
      : null;
    data.rankingsImageDisabled = this.mode?.rankingsImageDisabled?.guid
      ? '/s/media_objects/' + this.mode?.rankingsImageDisabled.guid
      : null;
    data.prizesImage = this.mode?.prizesImage?.guid
      ? '/s/media_objects/' + this.mode?.prizesImage.guid
      : null;
    data.prizesImageDisabled = this.mode?.prizesImageDisabled?.guid
      ? '/s/media_objects/' + this.mode?.prizesImageDisabled.guid
      : null;
    data.oddzeusImage = this.mode?.oddzeusImage?.guid
      ? '/s/media_objects/' + this.mode?.oddzeusImage.guid
      : null;

    data = this.createTranslations(data);

    this.errors = null;

    // POST / PUT Mode
    let request: Observable<Mode> = this.data.guid
      ? this.modeService.putMode(this.data.guid, data)
      : this.modeService.createMode(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }

  /**
   * Class bonuses in groups
   * @param bonuses
   */
  groupBonuses(bonuses: Bonus[]): void {
    // Create groups
    let groups: BonusGroup[] = [];

    if (bonuses.length) {
      // For each bonus, add it to the corresponding group or create one
      bonuses.forEach((bonus: Bonus) => {
        let groupIndex: number = groups.findIndex(
          (group: BonusGroup) => group.type === bonus.type
        );

        // Create group
        if (groupIndex === -1) {
          groups.push({
            name: getNameFromBonusType(bonus.type),
            type: bonus.type,
            bonuses: [bonus],
          });
        }
        // Add it to the group
        else {
          groups[groupIndex].bonuses.push(bonus);
        }
      });

      // Set the groups
      this.bonusesGroups = groups;
    }
  }

  /**
   * Add a mode bonus
   */
  addModeBonusFormGroup(modeBonus?: ModeBonus): void {
    this.modeBonuses.push(
      new FormGroup({
        selectedBonusGuid: new FormControl(
          modeBonus?.bonus?.guid ?? null,
          Validators.required
        ),
        probability: new FormControl(
          modeBonus?.probability ?? 0,
          Validators.required
        ),
        defaultCount: new FormControl(
          modeBonus?.defaultCount ?? 0,
          Validators.required
        ),
        isEditable: new FormControl(modeBonus?.isEditable ?? false),
      })
    );
  }

  /**
   * Delete a mode bonus
   */
  removeModeBonusFormGroup(i: number): void {
    this.modeBonuses.removeAt(i);
  }

  /**
   * Create a mode bonus form group for every existing mode bonus
   */
  createModeBonusesFormGroup(): any {
    this.mode?.modeBonuses.forEach((modeBonus: ModeBonus) => {
      this.addModeBonusFormGroup(modeBonus);
    });

    return this.modeBonuses;
  }

  /**
   * Get the mode bonus form array
   */
  get modeBonuses() {
    return this.formGroup.get('modeBonuses') as FormArray;
  }

  /**
   * Create the mode form group
   */
  createModeFormGroup(): void {
    if (this.mode) {
      const mode = this.mode;

      this.formGroup.setValue({
        title: mode.title,
        description: mode.description,
        slug: mode.slug,
        startAt: mode.startAt,
        endAt: mode.endAt,
        enabled: !mode.isDisabled,
        isForFreemium: mode.isForFreemium,
        isForPremium: mode.isForPremium,
        doesDefaultBonusesNeedRetrieval: mode.doesDefaultBonusesNeedRetrieval,
        isAssiduous: mode.isAssiduous,
        autoRetrieveExcessBonuses: mode.autoRetrieveExcessBonuses,
        primaryColor: mode.primaryColor,
        secondaryColor: mode.secondaryColor,
        tertiaryColor: mode.tertiaryColor,
        textColor: mode.textColor,

        oddzeus: mode.oddzeus,
        moneyShort: mode.moneyShort,
        moneyShortSuffix: mode.moneyShortSuffix,
        moneyLong: mode.moneyLong,

        dashboardTitle: mode.dashboardTitle,
        battlefieldTitle: mode.battlefieldTitle,
        eventsTitle: mode.eventsTitle,
        myBetsTitle: mode.myBetsTitle,
        ticketTitle: mode.ticketTitle,
        rankingsTitle: mode.rankingsTitle,
        trophiesTitle: mode.trophiesTitle,
        halfGodsTitle: mode.halfGodsTitle,
        heroesTitle: mode.heroesTitle,
        legendsTitle: mode.legendsTitle,
        stadiumsTitle: mode.stadiumsTitle,

        bonusesWidgetTitle: mode.bonusesWidgetTitle,
        rankingsWidgetTitle: mode.rankingsWidgetTitle,
        betsWidgetTitle: mode.betsWidgetTitle,
        contractWidgetTitle: mode.contractWidgetTitle,
        trophiesWidgetTitle: mode.trophiesWidgetTitle,

        isConfigurable: mode.isConfigurable,
        defaultWallet: mode.defaultWallet,
        isDefaultWalletEditable: mode.isDefaultWalletEditable,
        dailyIncome: mode.dailyIncome,
        isDailyIncomeEditable: mode.isDailyIncomeEditable,
        dailyBonuses: mode.dailyBonuses,
        areDailyBonusesEditable: mode.areDailyBonusesEditable,

        bonusesParagraph: mode.bonusesParagraph,
        boosterTitle: mode.boosterTitle,
        boosterPresentation: mode.boosterPresentation,
        boosterDescription: mode.boosterDescription,
        insuranceTitle: mode.insuranceTitle,
        insurancePresentation: mode.insurancePresentation,
        insuranceDescription: mode.insuranceDescription,
        zeurosTitle: mode.zeurosTitle,
        zeurosPresentation: mode.zeurosPresentation,
        zeurosDescription: mode.zeurosDescription,

        modeBonuses: [],
        maxInitialBet: mode.maxInitialBet,
        defaultTicketType: mode.defaultTicketType,
      });
    }
  }

  /**
   * Get the bonus type as a string for a bonus
   */
  getBonusTypeByBonusGuid(guid: string): string {
    const index: number = this.bonuses.findIndex(
      (bonus: Bonus) => bonus.guid === guid
    );
    return index !== -1 ? getNameFromBonusType(this.bonuses[index].type) : '';
  }

  /**
   * Check if a bonus is not already selected
   */
  canBonusBeSelected(guid: string): boolean {
    const index: number = this.modeBonuses.value.findIndex(
      (control: any) => control.selectedBonusGuid === guid
    );
    return index === -1;
  }

  /**
   * Change a mode's image
   */
  imageChanged(event: any, imageType: string) {
    if (this.mode) {
      // Get the new image id or null
      const result: { guid: string } | null =
        event === null ? null : { guid: event.guid } ?? null;
      // Set the mode image
      switch (imageType) {
        case ModeImages.DASHBOARD:
          this.mode.dashboardImage = result;
          break;
        case ModeImages.DASHBOARD_DISABLED:
          this.mode.dashboardImageDisabled = result;
          break;
        case ModeImages.BATTLEFIELD:
          this.mode.battlefieldImage = result;
          break;
        case ModeImages.BATTLEFIELD_DISABLED:
          this.mode.battlefieldImageDisabled = result;
          break;
        case ModeImages.BONUSES:
          this.mode.bonusesImage = result;
          break;
        case ModeImages.BONUSES_DISABLED:
          this.mode.bonusesImageDisabled = result;
          break;
        case ModeImages.RANKINGS:
          this.mode.rankingsImage = result;
          break;
        case ModeImages.RANKINGS_DISABLED:
          this.mode.rankingsImageDisabled = result;
          break;
        case ModeImages.TROPHIES:
          this.mode.prizesImage = result;
          break;
        case ModeImages.TROPHIES_DISABLED:
          this.mode.prizesImageDisabled = result;
          break;
        case ModeImages.ODDZEUS:
          this.mode.oddzeusImage = result;
          break;
      }
    }
  }

  /**
   * Create a translation object for every language and delete the original fields
   */
  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      title: data.title === '' ? null : data.title,
      description: data.description === '' ? null : data.description,
      moneyShort: data.moneyShort === '' ? null : data.moneyShort,
      moneyLong: data.moneyLong === '' ? null : data.moneyLong,
      moneyShortSuffix:
        data.moneyShortSuffix === '' ? null : data.moneyShortSuffix,
      oddzeus: data.oddzeus === '' ? null : data.oddzeus,
      dashboardTitle: data.dashboardTitle === '' ? null : data.dashboardTitle,
      primaryColor: data.primaryColor === '' ? null : data.primaryColor,
      secondaryColor: data.secondaryColor === '' ? null : data.secondaryColor,
      tertiaryColor: data.tertiaryColor === '' ? null : data.tertiaryColor,
      textColor: data.textColor === '' ? null : data.textColor,
      battlefieldTitle:
        data.battlefieldTitle === '' ? null : data.battlefieldTitle,
      eventsTitle: data.eventsTitle === '' ? null : data.eventsTitle,
      myBetsTitle: data.myBetsTitle === '' ? null : data.myBetsTitle,
      ticketTitle: data.ticketTitle === '' ? null : data.ticketTitle,
      rankingsTitle: data.rankingsTitle === '' ? null : data.rankingsTitle,
      trophiesTitle: data.trophiesTitle === '' ? null : data.trophiesTitle,
      halfGodsTitle: data.halfGodsTitle === '' ? null : data.halfGodsTitle,
      heroesTitle: data.heroesTitle === '' ? null : data.heroesTitle,
      legendsTitle: data.legendsTitle === '' ? null : data.legendsTitle,
      stadiumsTitle: data.stadiumsTitle === '' ? null : data.stadiumsTitle,
      bonusesWidgetTitle:
        data.bonusesWidgetTitle === '' ? null : data.bonusesWidgetTitle,
      rankingsWidgetTitle:
        data.rankingsWidgetTitle === '' ? null : data.rankingsWidgetTitle,
      betsWidgetTitle:
        data.betsWidgetTitle === '' ? null : data.betsWidgetTitle,
      contractWidgetTitle:
        data.contractWidgetTitle === '' ? null : data.contractWidgetTitle,
      trophiesWidgetTitle:
        data.trophiesWidgetTitle === '' ? null : data.trophiesWidgetTitle,
      boosterTitle: data.boosterTitle === '' ? null : data.boosterTitle,
      boosterPresentation:
        data.boosterPresentation === '' ? null : data.boosterPresentation,
      boosterDescription:
        data.boosterDescription === '' ? null : data.boosterDescription,
      insuranceTitle: data.insuranceTitle === '' ? null : data.insuranceTitle,
      insurancePresentation:
        data.insurancePresentation === '' ? null : data.insurancePresentation,
      insuranceDescription:
        data.insuranceDescription === '' ? null : data.insuranceDescription,
      zeurosTitle: data.zeurosTitle === '' ? null : data.zeurosTitle,
      zeurosPresentation:
        data.zeurosPresentation === '' ? null : data.zeurosPresentation,
      zeurosDescription:
        data.zeurosDescription === '' ? null : data.zeurosDescription,
      bonusesParagraph:
        data.bonusesParagraph === '' ? null : data.bonusesParagraph,
    };

    // delete the original fields
    delete data.locale;
    delete data.title;
    delete data.description;
    delete data.bonusesParagraph;
    delete data.moneyShort;
    delete data.moneyLong;
    delete data.moneyShortSuffix;
    delete data.oddzeus;
    delete data.dashboardTitle;
    delete data.battlefieldTitle;
    delete data.eventsTitle;
    delete data.myBetsTitle;
    delete data.ticketTitle;
    delete data.rankingsTitle;
    delete data.trophiesTitle;
    delete data.halfGodsTitle;
    delete data.heroesTitle;
    delete data.legendsTitle;
    delete data.stadiumsTitle;
    delete data.bonusesWidgetTitle;
    delete data.rankingsWidgetTitle;
    delete data.betsWidgetTitle;
    delete data.contractWidgetTitle;
    delete data.trophiesWidgetTitle;
    delete data.boosterTitle;
    delete data.boosterPresentation;
    delete data.boosterDescription;
    delete data.insuranceTitle;
    delete data.insurancePresentation;
    delete data.insuranceDescription;
    delete data.zeurosTitle;
    delete data.zeurosPresentation;
    delete data.zeurosDescription;

    // Retrieve all translations and set the new one
    data.translations = this.mode?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }

  /**
   * Search sports
   */
  searchSports(): void {
    this.sportSearchLoading = true;
    let params: any = [];
    params['translations.name'] = this.sportSearchTerm;
    params['itemsPerPage'] = 3;

    // Search leagues and remove the already selected ones
    this.sportService.getSports(params).subscribe((data: any) => {
      this.sportSearchLoading = false;
      this.searchedSports = data['hydra:member'].filter((sport: Sport) => {
        return !this.mode?.sports.some(
          (_sport: Sport) => _sport.id === sport.id
        );
      });
    });
  }

  /**
   * Search leagues
   */
  searchLeagues(): void {
    this.leagueSearchLoading = true;
    let params: any = [];
    params['translations.name'] = this.leagueSearchTerm;
    params['itemsPerPage'] = 3;

    // Search leagues and remove the already selected ones
    this.leagueService.getLeagues(params).subscribe((data: any) => {
      this.leagueSearchLoading = false;
      this.searchedLeagues = data['hydra:member'].filter((league: League) => {
        return !this.mode?.leagues.some(
          (_league: League) => _league.id === league.id
        );
      });
    });
  }

  /**
   * Select a league
   */
  addLeague(league: League): void {
    this.leagueSearchTerm = '';
    if (this?.mode?.leagues) {
      this.leagueSearchTerm = '';
      this.searchedLeagues = [];
      // Push it to the array with a new object to refresh the table
      this.mode.leagues = [...this.mode.leagues, league];
    }
  }

  /**
   * Remove a league
   */
  removeLeague(league: League): void {
    if (this?.mode?.leagues) {
      this.mode.leagues = this.mode.leagues.filter(
        (_league: League) => league.id !== _league.id
      );
    }
  }

  /**
   * Select a sport
   */
  addSport(sport: Sport): void {
    this.sportSearchTerm = '';
    if (this?.mode?.sports) {
      this.sportSearchTerm = '';
      this.searchedSports = [];
      // Push it to the array with a new object to refresh the table
      this.mode.sports = [...this.mode.sports, sport];
    }
  }

  /**
   * Remove a sport
   */
  removeSport(sport: Sport): void {
    if (this?.mode?.sports) {
      this.mode.sports = this.mode.sports.filter(
        (_sport: Sport) => sport.id !== _sport.id
      );
    }
  }

  searchTrophy(type: any): void {
    let searchTerm: string = '';

    // Get the search term and show a loading spinner
    switch (type) {
      case TrophyCategoryEnum.CATEGORY_ARENAS:
        searchTerm = this.trophyStadiumSearchTerm;
        this.trophyStadiumSearchLoading = true;
        break;
      case TrophyCategoryEnum.CATEGORY_HEROES:
        searchTerm = this.trophyHeroesSearchTerm;
        this.trophyHeroesSearchLoading = true;
        break;
      case TrophyCategoryEnum.CATEGORY_LEGENDS:
        searchTerm = this.trophyLegendsSearchTerm;
        this.trophyLegendsSearchLoading = true;
        break;
      case TrophyCategoryEnum.CATEGORY_HALF_GODS:
        searchTerm = this.trophyHalfGodsSearchTerm;
        this.trophyHalfGodsSearchLoading = true;
        break;
    }

    // Get params
    let params: any = [];
    params['translations.name'] = searchTerm;
    params['category.slug'] = type;
    params['itemsPerPage'] = 3;

    // Search trophies and remove the already selected ones
    this.trophyService.getTrophies(params).subscribe((data: any) => {
      switch (type) {
        case TrophyCategoryEnum.CATEGORY_ARENAS:
          this.trophyStadiumSearchLoading = false;
          this.searchedTrophyStadium = data['hydra:member'].filter(
            (trophy: Trophy) => {
              return !this.mode?.customStadiumsTrophies.some(
                (_trophy: Trophy) => _trophy.id === trophy.id
              );
            }
          );
          break;
        case TrophyCategoryEnum.CATEGORY_HALF_GODS:
          this.trophyHalfGodsSearchLoading = false;
          this.searchedTrophyHalfGods = data['hydra:member'].filter(
            (trophy: Trophy) => {
              return !this.mode?.customHalfGodsTrophies.some(
                (_trophy: Trophy) => _trophy.id === trophy.id
              );
            }
          );
          break;
        case TrophyCategoryEnum.CATEGORY_HEROES:
          this.trophyHeroesSearchLoading = false;
          this.searchedTrophyHeroes = data['hydra:member'].filter(
            (trophy: Trophy) => {
              return !this.mode?.customHeroesTrophies.some(
                (_trophy: Trophy) => _trophy.id === trophy.id
              );
            }
          );
          break;
        case TrophyCategoryEnum.CATEGORY_LEGENDS:
          this.trophyLegendsSearchLoading = false;
          this.searchedTrophyLegends = data['hydra:member'].filter(
            (trophy: Trophy) => {
              return !this.mode?.customLegendsTrophies.some(
                (_trophy: Trophy) => _trophy.id === trophy.id
              );
            }
          );
          break;
      }
    });
  }

  /**
   * Select a trophy
   */
  addTrophy(trophy: Trophy, type: any): void {
    switch (type) {
      case TrophyCategoryEnum.CATEGORY_ARENAS:
        this.trophyStadiumSearchTerm = '';
        if (this?.mode?.customStadiumsTrophies) {
          this.trophyStadiumSearchTerm = '';
          this.searchedTrophyStadium = [];
          this.mode.customStadiumsTrophies = [
            ...this.mode.customStadiumsTrophies,
            trophy,
          ];
        }
        break;
      case TrophyCategoryEnum.CATEGORY_HEROES:
        this.trophyHeroesSearchTerm = '';
        if (this?.mode?.customHeroesTrophies) {
          this.trophyHeroesSearchTerm = '';
          this.searchedTrophyHeroes = [];
          this.mode.customHeroesTrophies = [
            ...this.mode.customHeroesTrophies,
            trophy,
          ];
        }
        break;
      case TrophyCategoryEnum.CATEGORY_LEGENDS:
        this.trophyLegendsSearchTerm = '';
        if (this?.mode?.customLegendsTrophies) {
          this.trophyLegendsSearchTerm = '';
          this.searchedTrophyLegends = [];
          this.mode.customLegendsTrophies = [
            ...this.mode.customLegendsTrophies,
            trophy,
          ];
        }
        break;
      case TrophyCategoryEnum.CATEGORY_HALF_GODS:
        this.trophyHalfGodsSearchTerm = '';
        if (this?.mode?.customHalfGodsTrophies) {
          this.trophyHalfGodsSearchTerm = '';
          this.searchedTrophyHalfGods = [];
          this.mode.customHalfGodsTrophies = [
            ...this.mode.customHalfGodsTrophies,
            trophy,
          ];
        }
        break;
    }
  }

  /**
   * Remove a trophy
   */
  removeTrophy(trophy: Trophy, type: any): void {
    switch (type) {
      case TrophyCategoryEnum.CATEGORY_ARENAS:
        if (this?.mode?.customStadiumsTrophies) {
          this.mode.customStadiumsTrophies =
            this.mode.customStadiumsTrophies.filter(
              (_trophy: Trophy) => trophy.id !== _trophy.id
            );
        }
        break;
      case TrophyCategoryEnum.CATEGORY_HEROES:
        if (this?.mode?.customHeroesTrophies) {
          this.mode.customHeroesTrophies =
            this.mode.customHeroesTrophies.filter(
              (_trophy: Trophy) => trophy.id !== _trophy.id
            );
        }
        break;
      case TrophyCategoryEnum.CATEGORY_LEGENDS:
        if (this?.mode?.customLegendsTrophies) {
          this.mode.customLegendsTrophies =
            this.mode.customLegendsTrophies.filter(
              (_trophy: Trophy) => trophy.id !== _trophy.id
            );
        }
        break;
      case TrophyCategoryEnum.CATEGORY_HALF_GODS:
        if (this?.mode?.customHalfGodsTrophies) {
          this.mode.customHalfGodsTrophies =
            this.mode.customHalfGodsTrophies.filter(
              (_trophy: Trophy) => trophy.id !== _trophy.id
            );
        }
        break;
    }
  }
}
