import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompetitorService } from 'src/services/competitor.service';
import { Competitor } from 'src/entities/Competitor';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { Event, EventStatus, getNameFromEventStatus } from 'src/entities/Event';
import { Location } from '@angular/common';

@Component({
  selector: 'app-show-competitor-modal',
  templateUrl: './show-competitor-modal.component.html',
  styleUrls: ['./show-competitor-modal.component.css'],
})
export class ShowCompetitorModalComponent implements OnInit {
  competitor: Competitor | null = null;
  events: Event[] = [];
  displayedColumns: string[] = ['id', 'name', 'playedAt', 'status'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private competitorService: CompetitorService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.competitorService
        .getCompetitor(this.data.guid)
        .subscribe((competitor: Competitor) => {
          this.competitor = competitor;
          this.events = competitor.events;
        });
      // Change url
      this.location.replaceState(`/bets/competitors/${this.data.guid}`);
    }
  }

  getChipColorFromStatus(event: Event): ChipColor {
    switch (event.status.name) {
      case EventStatus.STATUS_NOT_STARTED:
        return ChipColor.ORANGE;
      case EventStatus.STATUS_INPLAY:
        return ChipColor.BLUE;
      case EventStatus.STATUS_TOBEFIXED:
      case EventStatus.STATUS_POSTPONED:
      case EventStatus.STATUS_CANCELLED:
      case EventStatus.STATUS_WALKOVER:
      case EventStatus.STATUS_INTERRUPTED:
      case EventStatus.STATUS_ABANDONNED:
      case EventStatus.STATUS_RETIRED:
      case EventStatus.STATUS_REMOVED:
        return ChipColor.RED;
      case EventStatus.STATUS_ENDED:
        return ChipColor.BLACK;
      default:
        return ChipColor.GREY;
    }
  }

  getChipTooltipFromStatus(event: Event): string {
    return getNameFromEventStatus(event.status);
  }
}
