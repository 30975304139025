<mat-toolbar color="primary" style="height: 50px" class="mat-elevation-z6">
  <div style="flex: 1; display: flex; justify-content: space-between">
    <span>OddZone - Gestion</span>
    <div>
      <app-language-selector></app-language-selector>
      <button
        mat-button
        *ngIf="isUserConnected()"
        [matMenuTriggerFor]="userMenu"
      >
        <mat-icon> account_circle </mat-icon>
        {{ currentUsername }}
        <mat-icon> arrow_drop_down </mat-icon>
      </button>

      <mat-menu #userMenu="matMenu" xPosition="after" yPosition="below">
        <button mat-menu-item (click)="logout()">
          <mat-icon style="color: #e74c3c">logout</mat-icon>
          <span style="color: #e74c3c">Déconnexion</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
<div style="margin-top: 50px">
  <router-outlet></router-outlet>
</div>
