import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Payment } from 'src/entities/Payment';
import { PaymentService } from 'src/services/payment.service';
import { NavigationService } from 'src/services/navigation.service';
import { ShowTransactionModalComponent } from './show-transaction-modal/show-transaction-modal.component';
import { getTypeAsString, ProductType } from 'src/entities/Product';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  data: Payment[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'user',
    'contest',
    'type',
    'discount',
    'amount',
    'createdAt',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'desc',
  };
  MODAL_WIDTH: string = '100%';
  loadSubscription: any = null;

  constructor(
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.navigationService.setCurrentPageName('Compétiteurs');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowTransactionModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.paymentService
      .getPayments(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['search_payment'] = this.searchedValue;

    return params;
  }

  /**
   * Find a transaction in the data by id
   */
  getTransactionById(id: number): Payment | null {
    const index: number = this.data.findIndex(
      (transaction: Payment) => transaction.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  getNameByType(type: ProductType): string {
    return getTypeAsString(type);
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(transaction: any): void {
    // Clear the order if no header is sorted
    if (transaction.active === '' || transaction.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: transaction.active,
        direction: transaction.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(transaction: any): void {
    this.searchedValue = (transaction.target as HTMLTextAreaElement).value;
    this.setData(false);
  }

  /**
   * Show the show transaction modal
   */
  showTransactionModal(id: number): void {
    const transaction: Payment | null = this.getTransactionById(id);
    if (transaction) {
      this.dialog.open(ShowTransactionModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: transaction.guid,
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
  }
}
