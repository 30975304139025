<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="variant; else loader">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.title ?? "-" }}
      </h1>
      <div *ngIf="!variant">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="variant">
        <div style="flex: 1; display: flex; justify-content: center">
          <div style="flex: 0.75; display: flex; flex-direction: column">
            <h2>Général</h2>

            <mat-form-field appearance="fill">
              <mat-label>Mode</mat-label>
              <mat-select
                formControlName="mode"
                (selectionChange)="changeMode()"
              >
                <mat-option [value]="-1">Règle générale</mat-option>
                <mat-option *ngFor="let mode of modes" [value]="mode.guid">{{
                  mode.title
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Titre</mat-label>
              <input
                matInput
                placeholder="Titre de la règle"
                formControlName="title"
                required
              />
              <mat-error *ngIf="formGroup.controls['title']?.errors">{{
                formGroup.controls["title"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                placeholder="Description de la règle"
                formControlName="description"
                required
              ></textarea>
              <mat-error *ngIf="formGroup.controls['description']?.errors">{{
                formGroup.controls["description"]?.errors?.error
              }}</mat-error>
            </mat-form-field>
            <mat-slide-toggle formControlName="isEnabled"
              >Activée</mat-slide-toggle
            >
            <br />
            <mat-divider></mat-divider>
            <br />
            <div style="display: flex; flex-direction: row">
              <div style="flex: 1; display: flex; flex-direction: column">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <div style="flex: 0.5; align-content: flex-start">
                    <h2>Configuration</h2>
                  </div>
                  <div
                    style="
                      flex: 0.5;
                      align-content: flex-end;
                      text-align: right;
                    "
                  >
                    <h4>Dépend du mode séléctionné</h4>
                  </div>
                </div>

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-form-field appearance="fill" style="width: 100%">
                      <mat-label>Bourse par défaut</mat-label>
                      <input
                        matInput
                        placeholder="Bourse par défaut"
                        formControlName="defaultWallet"
                        type="number"
                        [disabled]="nullFields['defaultWallet']"
                      />
                      <mat-error
                        *ngIf="formGroup.controls['defaultWallet']?.errors"
                        >{{
                          formGroup.controls["defaultWallet"]?.errors?.error
                        }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['defaultWallet']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-slide-toggle formControlName="isDefaultWalletEditable"
                      >Bourse par défaut configurable</mat-slide-toggle
                    >
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['isDefaultWalletEditable']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <br />

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-form-field appearance="fill">
                      <mat-label>Revenus quotidiens</mat-label>
                      <input
                        matInput
                        placeholder="Revenus quotidiens"
                        formControlName="dailyIncome"
                        type="number"
                      />
                      <mat-error
                        *ngIf="formGroup.controls['dailyIncome']?.errors"
                        >{{
                          formGroup.controls["dailyIncome"]?.errors?.error
                        }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['dailyIncome']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-slide-toggle formControlName="isDailyIncomeEditable"
                      >Revenus quotidiens configurables</mat-slide-toggle
                    >
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['isDailyIncomeEditable']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <br />

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-form-field appearance="fill">
                      <mat-label>Bonus quotidiens</mat-label>
                      <input
                        matInput
                        placeholder="Revenus quotidiens"
                        formControlName="dailyBonuses"
                        type="number"
                      />
                      <mat-error
                        *ngIf="formGroup.controls['dailyBonuses']?.errors"
                        >{{
                          formGroup.controls["dailyBonuses"]?.errors?.error
                        }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['dailyBonuses']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-slide-toggle formControlName="areDailyBonusesEditable"
                      >Bonus quotidiens configurables</mat-slide-toggle
                    >
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['areDailyBonusesEditable']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-slide-toggle formControlName="isAssiduous"
                      >Assidu</mat-slide-toggle
                    >
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['isAssiduous']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>

                <div class="full-input-container">
                  <div class="input-container">
                    <mat-slide-toggle
                      formControlName="doesDefaultBonusesNeedRetrieval"
                      >Les bonus par défaut ont besoin d'être récupérés
                      manuellement</mat-slide-toggle
                    >
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="
                        nullFields['doesDefaultBonusesNeedRetrieval']
                      "
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>
                <div class="full-input-container">
                  <div class="input-container">
                    <mat-slide-toggle
                      formControlName="autoRetrieveExcessBonuses"
                      >Les bonus non récupérés le sont à la prochaine
                      distribution</mat-slide-toggle
                    >
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      [(ngModel)]="nullFields['autoRetrieveExcessBonuses']"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="toggleFields()"
                    >
                    </mat-checkbox>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <mat-divider style="margin: 10px 0"></mat-divider>
      </div>

      <div
        style="flex: 1; display: flex; justify-content: center"
        *ngIf="variantBonuses.controls.length > 0"
      >
        <div style="flex: 0.75; display: flex; flex-direction: column">
          <h1>Bonus</h1>
          <!-- Variant bonuses -->
          <div *ngIf="!bonusesLoading; else loader">
            <div
              formArrayName="variantBonuses"
              *ngFor="
                let variantBonusFormGroup of variantBonuses.controls;
                let i = index
              "
            >
              <div
                [formGroupName]="i"
                style="
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-evenly;
                "
              >
                <mat-form-field appearance="fill">
                  <mat-label>Bonus</mat-label>
                  <mat-select formControlName="selectedBonusGuid" required>
                    <mat-option
                      [value]="variantBonusFormGroup.value.selectedBonusGuid"
                      >{{
                        getBonusByGuid(
                          variantBonusFormGroup.value.selectedBonusGuid
                        )?.name
                      }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Nb par défaut</mat-label>
                  <input
                    matInput
                    placeholder="Nb par défaut"
                    type="number"
                    formControlName="defaultCount"
                    required
                  />
                </mat-form-field>
                <mat-slide-toggle formControlName="isEditable"
                  >Configurable</mat-slide-toggle
                >
              </div>
              <br />
            </div>
          </div>
          <mat-divider style="margin: 10px 0"></mat-divider>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Retour</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="submitLoading || !variant || !formGroup.valid"
    >
      Enregistrer
    </button>
  </mat-dialog-actions>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
