import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Ad } from 'src/entities/Ad';
import { AdService } from 'src/services/ad.service';

@Component({
  selector: 'app-edit-fullscreen-ad-modal',
  templateUrl: './edit-fullscreen-ad-modal.component.html',
  styleUrls: ['./edit-fullscreen-ad-modal.component.css'],
})
export class EditFullscreenAdModalComponent implements OnInit {
  ad: Ad | null = null;
  submitLoading: boolean = false;
  errors: string | null = null;

  loading: boolean = true;
  imageUploadLoading: boolean = false;

  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    link: new FormControl(null),
    minimalAge: new FormControl(null, Validators.min(0)),
    skipTime: new FormControl(10, [Validators.required, Validators.min(0)]),
    shownForTicket: new FormControl(true, [Validators.required]),
    shownForBonuses: new FormControl(true, [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adService: AdService,
    private dialogRef: MatDialogRef<EditFullscreenAdModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve slideshow image and init form
      this.adService.getAd(this.data.guid).subscribe((ad: Ad) => {
        this.ad = ad;
        this.createFormGroup();
      });
    } else {
      this.ad = new Ad();
      this.createFormGroup();
    }
  }

  createFormGroup(): void {
    if (this.ad)
      this.formGroup.setValue({
        name: this.ad.name,
        link: this.ad.link,
        minimalAge: this.ad.minimalAge,
        skipTime: this.ad.skipTime,
        shownForTicket: this.ad.shownForTicket,
        shownForBonuses: this.ad.shownForBonuses,
      });
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  phoneImageChanged(event: any) {
    if (this.ad) {
      if (event === null) {
        this.ad.phoneImage = null;
      } else {
        this.ad.phoneImage = event['@id'] ?? null;
      }
    }
  }

  tabletImageChanged(event: any) {
    if (this.ad) {
      if (event === null) {
        this.ad.tabletImage = null;
      } else {
        this.ad.tabletImage = event['@id'] ?? null;
      }
    }
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);

    data.phoneImage = this.ad?.phoneImage;
    data.tabletImage = this.ad?.tabletImage;

    // Transform entities to IRIs
    if (data.phoneImage.guid)
      data.phoneImage = `/s/media_objects/${data.phoneImage.guid}`;
    if (data.tabletImage.guid)
      data.tabletImage = `/s/media_objects/${data.tabletImage.guid}`;

    this.errors = null;

    // POST / PUT Mode
    let request: Observable<Ad> = this.data.guid
      ? this.adService.putAd(this.data.guid, data)
      : this.adService.createAd(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }
}
