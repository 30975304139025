export class Ad {
  guid: string | null = null;
  id: number | null = null;
  name: string | null = null;
  phoneImage: any | null = null;
  tabletImage: any | null = null;
  link: string | null = null;
  skipTime: number = 10;
  minimalAge: number | null = null;
  shownForBonuses: boolean = true;
  shownForTicket: boolean = true;
}
