<section class="main-section">
  <div
    style="
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    "
  >
    <mat-card style="flex: 0.33; margin-top: 150px" *ngIf="!data">
      <mat-card-title style="text-align: center; margin-bottom: 20px"
        ><h2>Rechercher des lots</h2></mat-card-title
      >
      <mat-card-content>
        <div style="display: flex">
          <mat-form-field style="flex: 1" appearance="fill">
            <mat-label>Rechercher une arène</mat-label>
            <mat-icon matSuffix style="opacity: 0.75">search</mat-icon>
            <mat-chip-list #contestChipList [multiple]="false">
              <mat-chip
                *ngIf="selectedContest"
                (removed)="
                  selectedContest = null; contestSearchInput.value = ''
                "
              >
                {{ selectedContest.id + " - " + selectedContest.name }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                placeholder="Rechercher une arène..."
                #contestSearchInput
                [(ngModel)]="contestsSearchedValue"
                [ngModelOptions]="{ standalone: true }"
                (input)="searchContests()"
                [matAutocomplete]="contestAutocomplete"
                [disabled]="selectedContest !== null"
                [hidden]="selectedContest !== null"
                [matChipInputFor]="contestChipList"
              />
            </mat-chip-list>
            <mat-autocomplete #contestAutocomplete="matAutocomplete">
              <mat-option disabled *ngIf="contestsSearchLoading"
                ><mat-spinner [diameter]="25" style="margin: auto"></mat-spinner
              ></mat-option>
              <mat-option
                *ngFor="let contest of searchedContests"
                (click)="selectedContest = contest"
              >
                {{ contest.id + " - " + contest.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-divider inset></mat-divider>
      <mat-card-actions
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <button
          mat-raised-button
          [disabled]="selectedContest === null"
          color="primary"
          (click)="setData()"
        >
          Rechercher
        </button>
      </mat-card-actions>
      <mat-card-footer>
        <mat-progress-bar
          *ngIf="loading"
          mode="indeterminate"
        ></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>

  <div
    *ngIf="!loading"
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    "
  >
    <div *ngIf="data" style="width: 100%; flex: 1">
      <button mat-raised-button color="primary" (click)="resetData()">
        <mat-icon> chevron_left </mat-icon>Nouvelle recherche
      </button>

      <h1 style="text-align: center">
        Lots de l'arène <strong>{{ selectedContest?.name }}</strong>
      </h1>
      <div *ngIf="data.length === 0" style="text-align: center">
        <p>Aucun lot</p>
        <button
          matSuffix
          mat-raised-button
          color="primary"
          (click)="editPrizeModal()"
        >
          Ajouter un lot
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div *ngIf="data.length > 0">
        <div style="margin: 20px 10px; float: right" *ngIf="selectedContest">
          <button
            matSuffix
            mat-raised-button
            color="primary"
            (click)="editPrizeModal()"
          >
            Nouveau lot
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div>
          <table
            mat-table
            [dataSource]="data"
            matSort
            class="mat-elevation-z8"
            *ngIf="data.length > 0"
          >
            <!-- ID -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Titre</th>
              <td mat-cell *matCellDef="let element">
                {{ element.title }}
              </td>
            </ng-container>

            <ng-container matColumnDef="content">
              <th mat-header-cell *matHeaderCellDef>Contenu</th>
              <td mat-cell *matCellDef="let element">
                {{ element.content }}
              </td>
            </ng-container>

            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef>Position</th>
              <td mat-cell *matCellDef="let element">
                {{ element.position | number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <div style="display: flex; justify-content: space-evenly">
                  <div
                    (click)="showPrizeModal(element.id)"
                    class="cursor_pointer"
                    matTooltip="Afficher"
                  >
                    <mat-icon>visibility</mat-icon>
                  </div>
                  <div
                    (click)="editPrizeModal(element.id)"
                    class="cursor_pointer"
                    matTooltip="Editer"
                  >
                    <mat-icon>edit</mat-icon>
                  </div>
                  <div
                    (click)="deletePrizeModal(element.id)"
                    class="cursor_pointer"
                    matTooltip="Supprimer"
                    style="color: red"
                  >
                    <mat-icon>delete</mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
<ng-template #smallLoader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner" diameter="50"></mat-spinner>
  </div>
</ng-template>
