import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ad } from 'src/entities/Ad';
import { APIService } from './api.service';

@Injectable()
export class AdService {
  constructor(private apiService: APIService) {}

  public getAds(params: any = null): Observable<Ad[]> {
    return this.apiService.get(`/ads`, params, true);
  }
  public getAd(guid: string): Observable<Ad> {
    return this.apiService.get(`/ads/${guid}`);
  }

  public putAd(guid: string, body: Partial<Ad>): Observable<Ad> {
    return this.apiService.put(`/ads/${guid}`, body);
  }
  public createAd(body: Partial<Ad>): Observable<Ad> {
    return this.apiService.post(`/ads`, body);
  }
}
