import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/services/language.service';
import { ModeService } from 'src/services/mode.service';
import { ModeVariant, ModeVariantBonus } from 'src/entities/ModeVariant';
import { VariantService } from 'src/services/variant.service';
import { Bonus } from 'src/entities/Bonus';
import { BonusService } from 'src/services/bonus.service';
import { Mode } from 'src/entities/Mode';

@Component({
  selector: 'app-edit-variant-modal',
  templateUrl: './edit-variant-modal.component.html',
  styleUrls: ['./edit-variant-modal.component.css'],
})
export class EditVariantModalComponent implements OnInit {
  variant: ModeVariant | null = null;
  submitLoading: boolean = false;
  errors: string | null = null;
  loading: boolean = true;
  bonuses: Bonus[] = [];
  modes: Mode[] = [];
  nullFields: any = {};
  bonusesLoading: boolean = true;
  selectedMode: Mode | null = null;

  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    mode: new FormControl(null),
    isEnabled: new FormControl(false),
    doesDefaultBonusesNeedRetrieval: new FormControl(null),
    autoRetrieveExcessBonuses: new FormControl(null),
    isAssiduous: new FormControl(null),
    defaultWallet: new FormControl(null),
    isDefaultWalletEditable: new FormControl(null),
    dailyIncome: new FormControl(null),
    isDailyIncomeEditable: new FormControl(null),
    dailyBonuses: new FormControl(null),
    areDailyBonusesEditable: new FormControl(null),
    variantBonuses: new FormArray([]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modeService: ModeService,
    private bonusService: BonusService,
    private variantService: VariantService,
    private dialogRef: MatDialogRef<EditVariantModalComponent>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      // Retrieve mode and init form
      this.variantService
        .getVariant(this.data.guid)
        .subscribe((variant: ModeVariant) => {
          this.variant = variant;
          // Init the variant form group
          this.createVariantFormGroup();
          this.bonusesLoading = false;

          // If a mode exists, retrieve it and set the bonuses
          if (variant.mode?.guid) {
            this.modeService
              .getMode(variant.mode.guid)
              .subscribe((mode: Mode) => {
                this.selectedMode = mode;
                // Retrieve the mode bonuses
                this.setBonusesForTheCurrentMode();

                // Init the bonuses form groups
                this.createVariantBonusesFormGroup();
              });
          }
        });
    } else {
      this.variant = new ModeVariant();
      this.createVariantFormGroup();
    }

    // Retrieve all modes
    this.modeService.getModes().subscribe((modes: any) => {
      this.modes = modes['hydra:member'];
    });
  }

  getBonusByGuid(guid: string): any {
    return this.bonuses.find((bonus: any) => bonus.guid === guid);
  }

  /**
   * Close the modal and notify of a change
   */
  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  /**
   * Save the bonuses for the current selected mode
   */
  setBonusesForTheCurrentMode(): void {
    if (this.selectedMode) {
      this.bonuses = this.selectedMode.modeBonuses.map(
        (modeBonus: any) => modeBonus.bonus
      );
    }
  }

  /**
   * Reset the bonuses when changing the mode
   */
  changeMode(): void {
    // Remove all bonuses
    this.variantBonuses.clear();

    if (this.formGroup.value.mode && this.formGroup.value.mode !== -1) {
      this.bonusesLoading = false;

      // Retrieve the mode
      this.modeService
        .getMode(this.formGroup.value.mode)
        .subscribe((mode: any) => {
          this.selectedMode = mode;
          // Retrieve the bonuses
          this.setBonusesForTheCurrentMode();
          // Create the bonuses form groups
          this.createVariantBonusesFormGroup();
        })
        .add(() => {
          this.bonusesLoading = false;
        });
    } else {
      this.selectedMode = null;
    }
  }

  /**
   * Submit form and POST/PUT to API
   */
  onSubmit(): void {
    this.submitLoading = true;
    // Clone data
    let data: any = Object.assign({}, this.formGroup.value);
    data.variantBonuses = [...data.variantBonuses];
    data = this.createTranslations(data);

    this.errors = null;

    // Set null values for disabled fields
    Object.keys(this.nullFields).forEach((key) => {
      if (this.nullFields[key]) data[key] = null;
    });

    // Set the correct IRIs
    data.variantBonuses
      .filter((variantBonus: any) => variantBonus.selectedBonusGuid !== null)
      .forEach((variantBonus: any) => {
        variantBonus.bonus = '/s/bonuses/' + variantBonus.selectedBonusGuid;
        delete variantBonus.selectedBonusGuid;
      });

    if (data.mode === -1) {
      data.mode = null;
    } else {
      data.mode = '/s/modes/' + data.mode;
    }

    // POST / PUT Variant
    let request: Observable<ModeVariant> = this.data.guid
      ? this.variantService.putVariant(this.data.guid, data)
      : this.variantService.createVariant(data);
    request
      .subscribe(
        () => {
          this.closeModal(true);
        },
        (response: any) => {
          // Show errors
          response.error.violations.forEach((violation: any) => {
            this.formGroup.controls[violation.propertyPath]?.setErrors({
              error: violation.message,
            });
          });
        }
      )
      // Remove loader when all requests are completed
      .add(() => {
        this.submitLoading = false;
      });
  }
  /**
   * Create the variant form group
   */
  createVariantFormGroup(): void {
    if (this.variant) {
      const variant = this.variant;

      this.formGroup.setValue({
        title: variant.title,
        description: variant.description,
        isEnabled: variant.isEnabled,
        mode: variant.mode?.guid ?? -1,
        doesDefaultBonusesNeedRetrieval:
          variant.doesDefaultBonusesNeedRetrieval,
        isAssiduous: variant.isAssiduous,
        defaultWallet: variant.defaultWallet,
        isDefaultWalletEditable: variant.isDefaultWalletEditable,
        dailyIncome: variant.dailyIncome,
        isDailyIncomeEditable: variant.isDailyIncomeEditable,
        dailyBonuses: variant.dailyBonuses,
        variantBonuses: [],
        areDailyBonusesEditable: variant.areDailyBonusesEditable,
        autoRetrieveExcessBonuses: variant.autoRetrieveExcessBonuses,
      });

      this.nullFields['doesDefaultBonusesNeedRetrieval'] =
        variant.doesDefaultBonusesNeedRetrieval === null;
      this.nullFields['autoRetrieveExcessBonuses'] =
        variant.autoRetrieveExcessBonuses === null;
      this.nullFields['isAssiduous'] = variant.isAssiduous === null;
      this.nullFields['defaultWallet'] = variant.defaultWallet === null;
      this.nullFields['isDefaultWalletEditable'] =
        variant.isDefaultWalletEditable === null;
      this.nullFields['dailyIncome'] = variant.dailyIncome === null;
      this.nullFields['isDailyIncomeEditable'] =
        variant.isDailyIncomeEditable === null;
      this.nullFields['dailyBonuses'] = variant.dailyBonuses === null;
      this.nullFields['areDailyBonusesEditable'] =
        variant.areDailyBonusesEditable === null;

      this.toggleFields();
    }
  }

  /**
   * Create a mode bonus form group for every existing mode bonus
   */
  createVariantBonusesFormGroup(): any {
    // If the original variant's mode is the selected one, juste retrieve the variantBonuses
    if (this.variant?.mode?.guid === this.selectedMode?.guid) {
      this.variant?.variantBonuses.forEach((variantBonus: ModeVariantBonus) => {
        this.addVariantBonusFormGroup({
          bonusGuid: variantBonus.bonus?.guid ?? null,
          defaultCount: variantBonus.defaultCount,
          isEditable: variantBonus.isEditable,
        });
      });
    } else if (this.selectedMode) {
      // Otherwise, retrieve the values for the selected mode
      this.selectedMode.modeBonuses.forEach((modeBonus: any) => {
        this.addVariantBonusFormGroup({
          bonusGuid: modeBonus.bonus.guid,
          defaultCount: modeBonus.defaultCount,
          isEditable: modeBonus.isEditable,
        });
      });
    }

    return this.variantBonuses;
  }

  /**
   * Get the mode bonus form array
   */
  get variantBonuses() {
    return this.formGroup.get('variantBonuses') as FormArray;
  }

  /**
   * Add a variant bonus form group
   */
  addVariantBonusFormGroup(values: {
    bonusGuid: string | null;
    defaultCount: number;
    isEditable: boolean;
  }): void {
    this.variantBonuses.push(
      new FormGroup({
        selectedBonusGuid: new FormControl(
          values.bonusGuid,
          Validators.required
        ),
        defaultCount: new FormControl(values.defaultCount, Validators.required),
        isEditable: new FormControl(values.isEditable),
      })
    );
  }

  /**
   * Create a translation object for every language and delete the original fields
   */
  createTranslations(data: any): any {
    // Retrieve app language
    let language = this.languageService.getAppLanguage();

    // Define the new translation
    let translation = {
      locale: language,
      title: data.title === '' ? null : data.title,
      description: data.description === '' ? null : data.description,
    };

    // delete the original fields
    delete data.locale;
    delete data.title;
    delete data.description;

    // Retrieve all translations and set the new one
    data.translations = this.variant?.translations ?? {};
    data.translations[language] = translation;
    return data;
  }

  /**
   * Go through the null fields object and enable or disable fields accordingly
   */
  toggleFields(): void {
    Object.keys(this.nullFields).forEach((key) => {
      if (this.nullFields[key]) {
        this.formGroup.get(key)?.disable();
      } else {
        this.formGroup.get(key)?.enable();
      }
    });
  }
}
