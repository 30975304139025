import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { Event } from './Event';

export interface Odd {
  id: number;
  oddType: OddType;
  name: string;
  value: number;
  header: string;
  event: Event;
  result: OddResult;
}

export interface OddType {
  name: string;
}

export const enum OddResult {
  RESULT_WAITING = 0,
  RESULT_WON = 1,
  RESULT_LOST = 2,
  RESULT_CANCELLED = 3,
}

export function getNameFromOddResult(oddResult: OddResult): string {
  switch (oddResult) {
    case OddResult.RESULT_CANCELLED:
      return 'Annulé';
    case OddResult.RESULT_WAITING:
      return 'En attente';
    case OddResult.RESULT_WON:
      return 'Gagné';
    case OddResult.RESULT_LOST:
      return 'Perdu';
    default:
      return '-';
  }
}

export function getChipColorFromResult(oddResult: OddResult): ChipColor {
  switch (oddResult) {
    case OddResult.RESULT_CANCELLED:
      return ChipColor.BLACK;
    case OddResult.RESULT_WAITING:
      return ChipColor.ORANGE;
    case OddResult.RESULT_WON:
      return ChipColor.GREEN;
    case OddResult.RESULT_LOST:
      return ChipColor.RED;
    default:
      return ChipColor.GREY;
  }
}
