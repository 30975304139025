import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullDash',
})
export class NullDashPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === null || typeof value === 'undefined') return '-';
    return value;
  }
}
