import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APIService } from 'src/services/api.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'OddZoneAdmin';
  currentUsername: string | null;
  constructor(private apiService: APIService, private titleService: Title) {
    // Set the env in title, exept in prod
    this.titleService.setTitle(
      environment.name === 'prod'
        ? 'OddZone - Gestion'
        : `OddZone - Gestion (${environment.name})`
    );
    this.currentUsername = apiService.getCurrentUsername();
  }

  logout(): void {
    this.apiService.logout();
  }

  isUserConnected(): boolean {
    return this.apiService.isUserConnected();
  }
}
