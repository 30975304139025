import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { League } from 'src/entities/League';
import { LeagueService } from 'src/services/league.service';
import { EditLeagueModalComponent } from './edit-league-modal/edit-league-modal.component';
import { ShowLeagueModalComponent } from './show-league-modal/show-league-modal.component';
import { LanguageService } from 'src/services/language.service';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.css'],
})
export class LeaguesComponent implements OnInit, OnDestroy {
  data: League[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = [
    'id',
    'name',
    'country',
    'sport',
    'isFeatured',
    'actions',
  ];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = {
    field: 'id',
    direction: 'DESC',
  };
  MODAL_WIDTH: string = '100%';

  loadSubscription: any = null;
  languageSubscription: any = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private leagueService: LeagueService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationService.setCurrentPageName('Ligues');
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowLeagueModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
    this.languageSubscription = this.languageService.languageSubject.subscribe(
      () => this.setData()
    );
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Cancel the previous search
    if (this.loadSubscription) this.loadSubscription.unsubscribe();

    // Retrieve data from API
    this.loadSubscription = this.leagueService
      .getLeagues(this.getParams())
      .subscribe(
        (response: any) => {
          // Set all data
          this.data = response['hydra:member'];
          // Set the total count for the paginator
          this.totalItems = response['hydra:totalItems'];
        },
        () => null,
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['translations.name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a league in the data by id
   */
  getLeagueById(id: number): League | null {
    const index: number = this.data.findIndex(
      (league: League) => league.id === id
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = { field: event.active, direction: event.direction };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(event: any): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1;
    this.paginator?.firstPage();
    this.setData(false);
  }

  /**
   * Show the show league modal
   */
  showLeagueModal(id: number): void {
    const league: League | null = this.getLeagueById(id);
    if (league) {
      this.dialog.open(ShowLeagueModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: league.guid,
        },
      });
    }
  }

  /**
   * Show the show league modal
   */
  editLeagueModal(id: number): void {
    const league: League | null = this.getLeagueById(id);
    if (league) {
      let dialogRef = this.dialog.open(EditLeagueModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: league.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  /**
   * Set/Unset a league as featured
   */
  setAsFeatured(league: League): void {
    this.leagueService
      .putLeague(league.guid, { isFeatured: !league.isFeatured })
      .subscribe(() => this.setData(false));
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) this.loadSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
}
